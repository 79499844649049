import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import useStateRef from "react-usestateref";
import { notification } from "antd";
import moment from "moment";

// Mui meterial component import
import { Grid, DialogContent, Dialog } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./UploadReport.css";
import FooterMaster from "../Dashboard/FooterMaster";
import HeaderMasterComponent from "../Header/HeaderMaster";

import Member from "../../assets/Doctor_web_Icon/Visit.svg";
import Fasttrack from "../../assets/Doctor_web_Icon/Fasttrack.svg";
import Online from "../../assets/Doctor_web_Icon/Online.svg";
import Walkin from "../../assets/Doctor_web_Icon/Walkin.svg";
import successModelTickImg from "../../assets/Doctor_web_Icon/success.svg";
import uploadimagerep from "../../assets/Doctor_Images/upload.png";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import LinearProgress from "@mui/material/LinearProgress";
import MaleIcon from "../../assets/Doctor_Images/male_user.png";
import FemaleIcon from "../../assets/Doctor_Images/female_user.png";
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";

const UploadReportComponent = () => {
  let Var_History = useHistory();
  let { patientId } = useParams();
  let { bookingId } = useParams();
  let { date } = useParams();
  let { frompage } = useParams();

  const [Var_FileName, setVar_FileName] = useStateRef("Upload to file");
  const [, setVar_File, Var_File_Ref] = useStateRef("");
  const [, setVar_ReportName, Var_ReportName_Ref] = useStateRef("");
  var [, setVar_DateApi, dateRefapi] = useStateRef("");
  var [Var_AppointmentDetail, setVar_AppointmentDetail] = useState([]);
  var [Var_TotalCuont, setVar_TotalCuont] = useState("");
  const [Var_SuccessModelText, setVar_SuccessModelText] = useState("");
  const [Var_ReportNamePlaceHolder, setVar_ReportNamePlaceHolder] =
    useState("Enter Report Name");
  const [loading, setLoading] = useState(true);
  const [Var_fileprocess, setVar_fileprocess] = React.useState(false);
  const [Var_progress, setVar_Progress] = useState(0);
  const [, setVar_buttoncolor] = React.useState("#510F30");

  const [Var_IsshowSuccess, setVar_IsshowSuccess] = useState(false);
  const [isDisable, setisisDisable] = useState(false);

  // initial function call start
  useEffect(() => {
    setVar_DateApi(new Date().toLocaleDateString("fr-CA"));
    getAllAppointment();
    setisisDisable(false);
  }, []);
  // initial function call End

  // navigattion to back start
  const navigateTo = (path) => {
    Var_History.push("/dashboard" + path);
  };
  const navigateToReportPage = () => {
    if (frompage == "touploadreport") {
      navigateTo(
        "/appointments/report/" + patientId + "/" + bookingId + "/" + date
      );
    } else {
      navigateTo("/appointments");
    }
  };
  const okClose = () => {
    setVar_IsshowSuccess(!Var_IsshowSuccess);
    navigateTo("/appointments");
  };
  const uploadDocumentDoctor = async (e) => {
    setisisDisable(true);
    let filetype = e.target.files[0].name.split(".");

    if (filetype[1] == "jpg" || filetype[1] == "jpeg" || filetype[1] == "png") {
      setVar_fileprocess(true);
      setVar_Progress(0);
      setVar_buttoncolor("#D4C3CC");
      setVar_FileName(e.target.files[0].name);
      const formData = new FormData();
      formData.append("module_id", "3");
      formData.append("file", e.target.files[0]);
      formData.append("pathLocation", "CLINICREPORT/");

      Axios({
        method: "POST",
        url: "admin/awsS3FileUpload",
        data: formData,
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("progress", percentage);
          setVar_Progress(percentage);
        },
      })
        .then((response) => {
          setVar_File(response.data.filepath.Location);
          setisisDisable(false);
          setVar_buttoncolor("#510F30");
        })
        .catch((error) => {
          console.log(error);
          setVar_fileprocess(false);
          setVar_buttoncolor("#510F30");
          setisisDisable(false);
        });
    } else {
      setVar_fileprocess(true);
      setVar_Progress(0);
      setVar_buttoncolor("#D4C3CC");
      setVar_FileName(e.target.files[0].name);
      const formData = new FormData();
      formData.append("module_id", "3");
      formData.append("file", e.target.files[0]);
      formData.append("pathLocation", "CLINICREPORT/");

      Axios({
        method: "POST",
        url: "admin/awsS3FileUpload",
        data: formData,
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("progress", percentage);
          setVar_Progress(percentage);
        },
      })
        .then((response) => {
          setVar_File(response.data.filepath.Location);
          setVar_buttoncolor("#510F30");
          setisisDisable(false);
        })
        .catch((error) => {
          console.log(error);
          setVar_fileprocess(false);
          setVar_buttoncolor("#510F30");
          setisisDisable(false);
        });
    }
  };
  // File Upload Report End

  const sendReport = () => {
    setisisDisable(true);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate);
    setLoading(true);
    let data = {
      patientId: patientId,
      doctorId: localStorage.getItem("Doctor_id"),
      bookingId: bookingId,
      clinicId: localStorage.getItem("Clinic_id"),
      reportName: Var_ReportName_Ref.current,
      reportFile: Var_File_Ref.current.split("?")[0],
      dateValue: formattedDate,
      is_walkin: 0,
    };
    if (
      Var_ReportName_Ref.current != "" &&
      Var_File_Ref.current != "" &&
      formattedDate != ""
    ) {
      Axios.post("clinic/reportByClinic", data)
        .then((response) => {
          if (response.data.status == 1) {
            setVar_FileName("");
            setVar_ReportNamePlaceHolder("Enter Report Name");
            setVar_SuccessModelText("Report uploaded successfully.");
            setVar_IsshowSuccess(true);
            setTimeout(() => {
              setVar_IsshowSuccess(false);
              navigateToReportPage();
            }, 2000);
            setLoading(false);
            setisisDisable(false);
          } else if (response.data.status == 0) {
            notification.error({ message: response.data.msg });
            setLoading(false);
            setisisDisable(false);
          }
          getAllAppointment();
          setLoading(false);
          setisisDisable(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setisisDisable(false);
        });
    } else {
      notification.error({ message: "Please fill all the data" });
      setLoading(false);
      setisisDisable(false);
    }
  };
  // =============== Get All Appointment Start ==========================================
  const getAllAppointment = () => {
    setLoading(true);
    let data = {
      clinic_id: localStorage.getItem("Clinic_id"),
      doctor_id: localStorage.getItem("Doctor_id"),
      patient_type: "",
      period: "day",
      search_date: date, //"2023-08-14",
      search_date_to: date, //"2023-08-14",
    };
    Axios.post("doctor/getAppointmentList", data)
      .then((response) => {
        console.log("Appoinment", response.data.data[0].result);
        let filtereddata = response.data.data[0].result.filter(
          (x) => x.patient_id == patientId && x.booking_id == bookingId
        );
        setVar_AppointmentDetail(filtereddata);
        setVar_TotalCuont(response.data.data[0].result.length);
        console.log("appointment Result", filtereddata);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // Time conversion start

  const convertTo12HrFormat = (time24) => {
    let timeArr = time24.split(":");
    let hours = parseInt(timeArr[0]);
    let minutes = parseInt(timeArr[1]);
    let suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    let time12 =
      hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + suffix;

    return time12;
  };

  // Time conversion End
  // =============== Get All Appointment End ==========================================

  return (
    <div>
      {loading && (
        <div className="loader_body">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </div>
      )}
      <div>
        <div>
          {/* <div className="sub_header">
                        <div className="sub_header_body">
                            <div className="back_navigation" onClick={() => navigateTo("/appointments")}>
                                <i className="fa fa-angle-left" aria-hidden="true"></i>
                                <span>REPORT</span>
                            </div>
                            <div className="flex_grow"></div>
                        </div>
                    </div> */}

          <div>
            <HeaderMasterComponent />
          </div>

          <div className="Navication-header">
            <div>
              <img src={BackIcon} onClick={() => navigateToReportPage()} />
              <label onClick={() => navigateToReportPage()}>
                Upload Report
              </label>
            </div>
          </div>
          <div className="workinghours_content">
            <div>
              {Var_AppointmentDetail.map((item) => (
                <div className="profile_details_prescription_new">
                  <div className="profile_detail_container">
                    <img
                      src={
                        item.file_name != "" &&
                        item.file_name != " " &&
                        item.file_name != null
                          ? item.file_name
                          : item.gender.toLowerCase() == "male"
                          ? MaleIcon
                          : FemaleIcon
                      }
                      className="profile_Details"
                      style={{ border: "1px solid var(--app-theme)" }}
                    />
                    <div style={{paddingRight:"2rem"}}>
                      <div className="detailsprofile">
                        <p className="name_details_new">Name</p>
                        <p className="profile_name">{item.name}</p>
                        {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                      </div>
                      <div className="detailsprofile">
                        <p className="name_details_new">Date</p>
                        <p className="profile_name">
                          {moment(item.appointment_date).format("DD-MMM-YY")}
                        </p>
                        {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                      </div>
                      <div className="detailsprofile">
                      <p className="name_details_new">Height (cm)</p>
                      <p className="profile_name">{item.height}</p>
                      </div>
                      <div className="detailsprofile">
                      <p className="name_details_new">Weight (kg)</p>
                      <p className="profile_name">{item.weight}</p>
                      </div>
                      <div className="detailsprofile">
                      <p className="name_details_new">Body Temperature (°C or °F)</p>
                      <p className="profile_name">{item.body_temperature}</p>
                      </div>
                    </div>
                    <div style={{paddingRight:"1rem"}}>
                      <div className="detailsprofile">
                        <p className="name_details_new">Patient ID</p>
                        <p className="profile_name">{item.patient_id}</p>
                        {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                      </div>
                      <div className="detailsprofile">
                        <p className="name_details_new">Time</p>
                        <p className="profile_name">
                          {item.appointment_time == null
                            ? 0
                            : convertTo12HrFormat(item.appointment_time)}
                        </p>
                        {/* <p className='profile_age'>{item.age > 0 ? item.age + " " + "yrs," : ""} <span> {item.gender}</span></p> */}
                      </div>
                      <div className="detailsprofile">
                      <p className="name_details_new">Blood Pressure (mmHg)</p>
                      <p className="profile_name">{item.blood_pressure}</p>
                      </div>
                      <div className="detailsprofile">
                      <p className="name_details_new">Heart Rate (bpm)</p>
                      <p className="profile_name">{item.heart_rate}</p>
                      </div>
                      <div>
                        <div className="detailsprofile appointment_page_detailsprofile">
                         <p className="name_details_new"> Pre-existing disease</p>
                              {item?.pre_exits_disease != null ? (
                            <div>
                              {(() => {
                                const PreExisting =
                                 item?.pre_exits_disease &&
                                JSON.parse(item.pre_exits_disease);
                             return PreExisting?.map((item, index) => (
                            <label className="symptoms_chip_main">{item.trim()}</label>
                            ));
                             })()}
                            </div>
                            ) : null}
                            </div>
                          </div>
                    </div>
                  </div>

                  <div className="EnterDetalis">
                    <Grid container>
                      <Grid item align="start">
                        <label className="report_name_text"> Report Name</label>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item align="center">
                        <input
                          className="text_filed"
                          type="text"
                          placeholder={Var_ReportNamePlaceHolder}
                          onChange={(e) => setVar_ReportName(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container className="report_name_details">
                      <Grid item align="start">
                        <label className="report_name_text">
                          {" "}
                          Select the report
                        </label>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item align="center">
                        <div
                          className="fileUpload_new fileUpload_report_new"
                          onChange={(e) => uploadDocumentDoctor(e)}
                          onClick={(e) => (e.target.value = null)}
                        >
                          <p>
                            <img src={uploadimagerep} alt="Upload Icon" />
                          </p>
                          <input
                            type="file"
                            className="upload"
                            // onChange={(e) => uploadDocumentDoctor(e)}
                            // onClick={(e) => (e.target.value = null)}
                            placeholder={Var_FileName}
                          />
                          <p className="fileName">{Var_FileName}</p>
                        </div>
                        {Var_fileprocess && (
                          <div>
                            <div
                              className="upload-container"
                              style={{ paddingTop: "15px" }}
                            >
                              {/* Your other UI elements */}
                              <LinearProgress
                                variant="determinate"
                                value={Var_progress}
                                color="secondary"
                                className="progressBar"
                                sx={{
                                  zIndex: "1",
                                  width: "100%",
                                  margin: "0px 3px",
                                }}
                              />
                              {/* Display progress percentage */}
                              <div className="progress-text">{`${Var_progress}%`}</div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </div>

                  <div className="Reportcontainer_new">
                  {Var_File_Ref.current != "" && (
                      <div className="segment_center">
                        <Grid container>
                          {Var_File_Ref.current.includes(".pdf") ?
                          <object  data={Var_File_Ref.current} className="upload_image_details" ></object> :
                          <img
                            className="upload_image_details"
                            src={Var_File_Ref.current}
                            alt="error"
                          />
                          }
                        </Grid>
                      </div>
                    )}
                  </div>
                  {/* <div className="flex_grow"></div>
                                        <div className='profile_detail_type'>
                                            <img src={item.appointment_type_id == 1
                                                ? Member
                                                : item.appointment_type_id == 3
                                                    ? Fasttrack
                                                    : item.appointment_type_id == 2
                                                        ? Online
                                                        : item.appointment_type_id == 0
                                                            ? Walkin
                                                            : vector} alt='pro' className='Profoile_Type'  />
                                            <p className='profile_time'>{item.appointment_time == null ? 0 : convertTo12HrFormat(item.appointment_time)}</p>
                                            <p className='report_date'>{moment(item.appointment_date).format("DD-MMM-YY")}</p>
                                        </div> */}
                </div>
              ))}
              {Var_File_Ref.current != "" && (
                <div className="segment_center">
                  <button
                    className="botton_Cart_Create"
                    disabled={isDisable}
                    onClick={() => sendReport()}
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* ====================== Upload Success=============================== */}

          {/* 

                    <Dialog
                        fullWidth={true}
                        open={Var_IsshowSuccess}
                        onClose={setVar_IsshowSuccess}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "300px",
                                    borderRadius: "20px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <div className="success_model_body">
                                <div className="icon">
                                    <img src={successModelTickImg} />
                                </div>
                                <div className="title">success!</div>
                                <div className="content">Report uploaded successfully!</div>
                                <div className="segment_center">
                                    <button className="botton_Cart_Create" onClick={() => okClose()}>OK</button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog> */}
          {/* ======================================================= */}
          {Var_IsshowSuccess && (
            <div className="popup_main">
              <div className="success_Popup_body">
                <div className="success_model_content">
                  <p className="success_content_text_head">
                    <b>{Var_SuccessModelText}</b>
                  </p>
                </div>
                <div className="progress_prt">
                  <div className="progress_bar"></div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="layout_footer footer">
                    <FooterMaster />
                </div> */}
      </div>
    </div>
  );
};
export default UploadReportComponent;
