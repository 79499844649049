// New flow


import { useHistory } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import useStateRef from "react-usestateref";
import { notification, Spin } from "antd";
// import LinearProgress from '@mui/material/LinearProgress';


// Mui meterial component import
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import './DashboardMaster.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCalendar } from "react-icons/ai";
import Appoinmentsimg from '../../assets/icon/appointment.svg'
import Queueimg from '../../assets/icon/queue.svg'
import Availabilityimg from '../../assets/icon/manageSchedule.svg'
import Workinghoursimg from '../../assets/icon/workhours.svg'
import Walkinimg from '../../assets/icon/referral.svg'
import Cancelledimg from '../../assets/icon/cancelled.svg'
import Postimg from '../../assets/icon/post.svg'
import Manageserviceimg from '../../assets/icon/manageService.svg'
import alterImage from '../../images/Vector.png'

import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import manAppointmentt from '../../images/man.svg'
import serviceSvg from '../../images/serviceSvg.svg'
import appointSvg from '../../images/appointSvg.svg'
import hrSvg from '../../images/hrSvg.svg'
import revenueSvg from '../../images/revenueSvg.svg'
import dashboardFile from '../../images/dashboardFile.png'
import dashboard_back from '../../images/dashboard_back.png'
import MonthlyChart from "../Dashboard/DashboardChart";
import { Container, FormControl, Select, MenuItem, Card } from '@mui/material';

import moment from "moment";
import MaleIcon from "../../assets/Doctor_web_Icon/male_user.png";
import FemaleIcon from "../../assets/Doctor_web_Icon/female_user.png";
import VectorIcon from "../../assets/Doctor_web_Icon/userPNG.png";
import closeicon from "../../assets/Doctor_web_Icon/close.svg";
import AddIcon from "../../assets/Doctor_Images/Plus.png";
import closeIcon from "../../assets/Doctor_Images/closeIcon.png";   
function DashboardMasterComponent({Clinic_id}) {
    console.log(Clinic_id,"Dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd");
    let Var_History = useHistory();
    const [startDate, setStartDate,startDate_Ref] = useStateRef(new Date());
    const [Var_ClinicID, setVar_ClinicID, Var_ClinicID_Ref] = useStateRef(null)
    const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = useState([]);
    var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] = useState("");
    const [Var_TabPermissionById, setVar_TabPermissionById, Var_TabPermissionById_Ref] = useStateRef(null)
    const [Var_Availability_access, setVar_Availability_access, Var_Availability_access_Ref] = useStateRef(null)
    const [Var_Workinghrs_access, setVar_Workinghrs_access, Var_Workinghrs_access_Ref] = useStateRef(null)
    const [Var_Post_acccess, setVar_Post_acccess, Var_Post_acccess_Ref] = useStateRef(null)
    const [Var_Service_access, setVar_Service_access, Var_Service_access_Ref] = useStateRef(null)
    const [loading, setLoading] = useState(false);

    //new one
    const [isOpenAddDealModel, setIsOpenAddDealModel] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);

    const [walname, setwalname] = useState("");
    const [walgender, setwalgender] = useState("");
    const [walmobile, setwalmobile] = useState("");
    const [walage, setwalage] = useState("");
    const [Var_ProfileName, setVar_ProfileName, Var_ProfileName_Ref] =
        useStateRef("");
    const [Var_ProfilePhone, setVar_ProfilePhone, Var_ProfilePhone_Ref] =
        useStateRef("");
    const [Var_ProfileAge, setVar_ProfileAge, Var_ProfileAge_Ref] =
        useStateRef("");
    const [Var_gender, setVar_gender, Var_gender_Ref] = useStateRef("Select");
    const [Var_gendervalue, setVar_gendervalue, Var_gendervalue_Ref] =
        useStateRef("Select");

    const [walBookingBtnState, setWalBookingBtnState, walBookingBtnStateRef] =
        useStateRef(true);
    const [Var_GetServiceListSelect, setVar_GetServiceListSelect, Var_GetServiceListSelect_Ref,
    ] = useStateRef("");
    var [Var_Date, setVar_Date, Var_DateRef] = useStateRef("");
    const [Var_SuccessModelText, setVar_SuccessModelText] = useState("");
    const [isOpenWalkInQrModel, setisOpenWalkInQrModel] = useState(false);
    var [isOpenQrCodeModel, setisOpenQrCodeModel] = useState(false);
    const [Var_SelectTimeSlots, setVar_SelectTimeSlots, Var_SelectTimeSlots_Ref] =
        useStateRef("");
    var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("");
    const [Var_WalingNoSlots, setVar_WalingNoSlots, Var_WalingNoSlots_Ref] = useStateRef("0");
    const [Var_WalkInGetSlots, setVar_WalkInGetSlots, Var_WalkInGetSlots_Ref] = useStateRef([]);
    const [walservice, setwalservice] = useState("");
    const [isshowsuccess, setishiddensuccess] = useState(false);
    const [Var_AddAppointmentMsg, setVar_AddAppointmentMsg, Var_AddAppointmentMsg_Ref] = useStateRef("");
    const [Var_UpcomingAppointment, setVar_UpcomingAppointment] = useStateRef([]);
    const [Var_NextPatientDetailsData, setVar_NextPatientDetailsData, Var_NextPatientDetailsData_Ref] = useStateRef([]);
    const [Var_CheckinStatus, setVar_CheckinStatus, Var_CheckinStatus_Ref] = useStateRef("0");
    const [Var_CheckOutStatus, setVar_CheckOutStatus, Var_CheckOutStatus_Ref] = useStateRef("0");
    const [currentPage, setCurrentPage] = useState(1);
    const [revenueIncome, setRevenueIncome] = useState([
    ]);
    const [getTotalRevenue, setTotalRevenue]= useState(null);
    const [Var_Date_Today, setVar_Date_Today] = useState(new Date());
    var [Var_TotalCount, setVar_TotalCount, Var_TotalCount_Ref] = useStateRef(0);
    const [Var_GetSymptoms, setVar_GetSymptoms, Var_GetSymptoms_Ref] = useStateRef("");
    const [Var_SlotsLength, setVar_SlotsLength, Var_SlotsLength_Ref] = useStateRef("");
    const [Var_GetServiceList, setVar_GetServiceList, Var_GetServiceList_Ref] = useStateRef([]);
    const [walsymptoms, setwalsymptoms] = useState("");
  const [walspecial, setwalspecial] = useState("");
    const [Var_SelectToTimeSlots, setVar_SelectToTimeSlots, Var_SelectToTimeSlots_Ref] = useStateRef("");
    const [Var_year, setVar_year, Var_year_Ref] = useStateRef(moment(new Date()).format('YYYY'));
    var [isOpenSuccessModel, setIsOpenSuccessModel] = useState(false);

    const [Var_ManageServicesTotalCount, setVar_ManageServicesTotalCount, Var_ManageServicesTotalCount_Ref] = useStateRef([]);
    const [Var_WorkingHoursTotalCount, setVar_WorkingHoursTotalCount, Var_WorkingHoursTotalCount_Ref,] = useStateRef([]);
    const [Var_SpecialityDoctorList, setVar_SpecialityDoctorList, Var_SpecialityDoctorList_Ref] = useStateRef([]);
    const [Var_GetSpecilaitySelect, setVar_GetSpecilaitySelect, Var_GetSpecilaitySelect_Ref] = useStateRef("");
    const [Var_symptomsValue, setVar_symptomsValue, Var_symptomsValue_Ref] = useStateRef([]);
    let interval;
    const itemsPerPage = 10;

    useEffect(() => {
        // getClinicWiseDoctor()
        const currentDate = new Date();
        const currentHour = String(currentDate.getHours()).padStart(2, '0');
        const currentMinute = String(currentDate.getMinutes()).padStart(2, '0');
        const currentSecond = String(currentDate.getSeconds()).padStart(2, '0');
        const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
        const Var_CurrentDate = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');

        const currentDateInUTC = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()));

        console.log(currentDateInUTC.toUTCString());
        console.log(formattedTime, Var_CurrentDate, currentDateInUTC, "Date");
        setVar_Date(moment(new Date().toLocaleDateString("fr-CA")).format("DD-MMM-YY"));
        setVar_DateApi(new Date().toLocaleDateString("fr-CA"));

        sendToken();
        changeLanguage();
        getAllAppointment();
        getClinicWiseDoctor();
        getspeciality()
    }, [Clinic_id]);

// Custom input component with an icon
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
    style={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #2f887c ",
        borderRadius: "5px",
        padding: "6px 12px",
        cursor: "pointer",
        gap: "20px",
        background: "#fff",
         boxShadow: "0 4px 6px rgba(99, 99, 99, 0.2)",
         marginRight:"4rem"
      }}
      onClick={onClick}
      ref={ref}
    >
      <span>{value || "Select Date"}</span> {/* Display the selected date or placeholder */}
      <AiOutlineCalendar size={20} color="#555" /> {/* Calendar icon */}
    </div>
  ));

  const getAppointmentDate = (date) =>{
    setStartDate(date);
    getSlotsService();
  }


    // navigation to back start
    const handleOnClick = (path) => {
        Var_History.push("/dashboard/" + path)
    };
    // navigation to back end

    const getClinicWiseDoctor = () => {

        // setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id")
        };
        axios
            .post("clinic/getClinicWiseDoctor", data)
            .then((response) => {
                // setVar_getClinicWiseDoctor(response.data.data);
                // setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
                // localStorage.setItem("Doctor_id", response.data.data[0].doctor_id)
                // localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
                setLoading(false);
                getManageServicesList();
                getClinicProfile();
                getworkingDetails();
                getRevenueIncome();
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    const noPermission = (type) => {
        if (type == "appointments") {
            notification.error({ message: "No permission" })
        } else if (type == "queue") {
            notification.error({ message: "No permission" })

        } else if (type == "availability") {
            notification.error({ message: "No permission" })

        } else if (type == "workinghours") {
            notification.error({ message: "No permission" })
        }
        else if (type == "walkinbooking") {
            notification.error({ message: "No permission" })

        } else if (type == "cancelledappointments") {
            notification.error({ message: "No permission" })

        } else if (type == "post") {
            notification.error({ message: "No permission" })

        } else if (type == "manageservice") {
            notification.error({ message: "No permission" })
        }
    }
    const sendToken = () => {

        var data = {
            "vendor_id": localStorage.getItem("Doctor_id"),
            "token": localStorage.getItem("TokenFCM")
        };
        axios
            .post("admin/insertvendorwebtoken", data)
            .then((response) => {
                console.log("update token response", response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const changeLanguage = () => {

        var data = {
            "vendor_id": localStorage.getItem("Doctor_id"),
            "language": "en"
        };
        axios
            .post("admin/insertvendorweblanguage", data)
            .then((response) => {
                console.log("update token response", response);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const openAppoint = () => {
        setIsOpenAddDealModel(true)
    }
    const closeAppoint = () => {
        setIsOpenAddDealModel(false)
    }

    const [datasss, setDatasss] = useState([
        { month: new Date(2024, 0, 1), value: 10 }, // January
        { month: new Date(2024, 1, 1), value: 20 }, // February
        { month: new Date(2024, 2, 1), value: 40 },  // March
        { month: new Date(2024, 3, 1), value: 55 }, // April
        { month: new Date(2024, 4, 1), value: 70 }, // May
        { month: new Date(2024, 5, 1), value: 67 }, // June
        { month: new Date(2024, 6, 1), value: 70 }, // July
        { month: new Date(2024, 7, 1), value: 53 }, // August
        { month: new Date(2024, 8, 1), value: 10 }, // September
        { month: new Date(2024, 9, 1), value: 20 }, // October
        { month: new Date(2024, 10, 1), value: 0 }, // November
        { month: new Date(2024, 11, 1), value: 0 }, // December
      ]);

    

    const getRevenueIncome = () => {
        var data = {
            doctorId:localStorage.getItem("Doctor_id"),
            clinicId:localStorage.getItem("Clinic_id"),
            year: Var_year_Ref.current
        }; 
        axios.post("doctor/getRevenueForDoctor", data).then((response) => {
            let totalData = [];
            let income = 0;
            response.data?.data.forEach((item, index)=>{
                totalData.push( { month: new Date(2024, index, 1), value: item.booking_count });
                income = item.booking_count + income;
            });
            setRevenueIncome(totalData);
            setTotalRevenue(income);
            
        })

    }

    const getAllAppointment = () => {
        debugger;
        const patient_details = [];
        setVar_NextPatientDetailsData([]);
        setVar_UpcomingAppointment([]);
        setVar_TotalCount(0);
        setVar_CheckOutStatus(0);
        var date = dateRefapi.current;
        setLoading(true);
        var data = {
            clinic_id: localStorage.getItem("Clinic_id"),
            doctor_id: localStorage.getItem("Doctor_id"),
            patient_type: "",
            period: "day",
            search_date: date.toString(),
            search_date_to: date.toString(),
        };
        console.log("scheme", data);

        axios
            .post("doctor/getAppointmentList", data)
            .then((response) => {
                if (response.data.status == 1) {
                    if (response.data.data[0].result.length != 0) {

                        console.log("Appoinment", response.data.data[0].result);
                        var TotalAppointmentCount = response.data.data[0].result.filter(
                            (x) => x.appointment_type_id != 2
                        );
                        setVar_TotalCount(TotalAppointmentCount.length);
                        var checkOutStatusAppointment = response.data.data[0].result.filter(
                            (x) => x.check_out_status == 0
                        );
                        setVar_UpcomingAppointment(checkOutStatusAppointment);
                        patient_details.push(checkOutStatusAppointment);
                        setVar_NextPatientDetailsData(patient_details[0][0]);
                        console.log(Var_NextPatientDetailsData_Ref.current, "datedfdsd");
                        var checkInStatus = response.data.data[0].result.filter(
                            (x) => x.check_in_status == 1
                        );
                        var checkOutStatus = response.data.data[0].result.filter(
                            (x) => x.check_out_status == 1
                        );
                        setVar_CheckinStatus(checkInStatus.length);
                        console.log("checkin", checkInStatus.length);
                        setVar_CheckOutStatus(checkOutStatus.length);
                        console.log("checkout", checkOutStatus.length);
                        setLoading(false);
                        // changeCheckinStatus(response.data.data[0].result)
                        clearInterval(interval);
                        interval = setInterval(() => {
                            getAllAppointment();
                        }, 60000); // 1000ms interval
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
    })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const totalPages = Math.ceil(Var_UpcomingAppointment.length / itemsPerPage);

    const currentData = Var_UpcomingAppointment.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const getGender = (e) => {
        setVar_gendervalue(e.target.value);
        setwalgender("");
        if (e.target.value == "M") {
            setVar_gender("Male");
        } else {
            setVar_gender("Female");
        }
    };

    const getYear = (e) => {
        setVar_year(e.target.value);
        getRevenueIncome();
    };


 
    const validation = () => {
        setwalname("");
        setwalmobile("");
        setwalage("");
        setwalgender("");
        setwalsymptoms("");
        setwalservice("");
        setwalspecial("")
        if (
            Var_SelectTimeSlots_Ref.current == "" &&
            Var_ProfileName_Ref.current == "" &&
            Var_ProfilePhone_Ref.current == "" &&
            Var_ProfileAge_Ref.current == "" &&
            (Var_gender_Ref.current == "" || Var_gender_Ref.current == "Select") &&
            (Var_GetServiceListSelect_Ref.current.id == 0 ||
                Var_GetServiceListSelect_Ref.current.id == "") &&
            Var_SlotsLength_Ref.current == "" &&
            Var_symptomsValue_Ref.current.length == 0
        ) {
            setwalname(true);
            setwalmobile(true);
            setwalage(true);
            setwalgender(true);
            setwalsymptoms(true);
            setwalservice(true);
            setwalspecial(true)
            return false;
        }
        if (Var_ProfileName_Ref.current == "") {
            setwalname(true);
            return false;
        }
        if (Var_gender_Ref.current == "" || Var_gender_Ref.current == "Select") {
            setwalgender(true);
            return false;
        }
        if (Var_ProfileAge_Ref.current == "") {
            setwalage(true);
            return false;
        }
        if (Var_ProfilePhone_Ref.current == "") {
            setwalmobile(true);
            return false;
        }
        if (Var_symptomsValue_Ref.current.length == 0) {
            setwalsymptoms(true);
            return false;
        }
        if (Var_GetSpecilaitySelect_Ref.current.id == '' || Var_GetSpecilaitySelect_Ref.current.id == 0) {
            setwalspecial(true)
          }
        if (
            Var_GetServiceListSelect_Ref.current.id == "" ||
            Var_GetServiceListSelect_Ref.current.id == 0
        ) {
            setwalservice(true);
            return false;
        }
        if (Var_SlotsLength_Ref.current == "") {
            return false;
        }
        if (Var_SelectTimeSlots_Ref.current == "") {
            return false;
        }
        if (walservice || walmobile || walgender || walname || walsymptoms) {
            return false;
        } else {
            return true;
        }
    };
    const getClinicProfile = () => {
        setLoading(true);
        var data = {
          clinic_id: localStorage.getItem("Clinic_id"),
        };
        axios
          .post("clinic/getClinicProfile", data)
          .then((response) => {
            const pharmaListData = response.data.data[0].pharma_list
            ? JSON.parse(response.data.data[0].pharma_list)
            : [];
          if (pharmaListData.length > 0) {
            localStorage.setItem("pharmacy_id", pharmaListData[0].pharmacyId);
          } else {
            localStorage.setItem("pharmacy_id", "");
          }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      };

    const walkinMobileNumbetValidation = (event, type) => {
        console.log(event.target.value, "number");
        const inputText = event.target.value;
        const numericOnly = inputText.replace(/\D/g, "");
        if (type == "PhoneNumber") {
            if (
                numericOnly.toString().length >= 0 &&
                numericOnly.toString().length <= 10
            ) {
                setVar_ProfilePhone(numericOnly);
                setwalmobile("");
            } else {
                setwalmobile(true);
            }
            console.log(walmobile, "walmobile", Var_ProfilePhone_Ref.current);
        } else if (type == "Age") {
            if (
                numericOnly.toString().length >= 0 &&
                numericOnly.toString().length <= 3
            ) {
                setVar_ProfileAge(numericOnly);
                setwalage("");
            } else {
                setwalage(true);
            }
            console.log(walage, "walage", Var_ProfileAge_Ref.current);
        }
    };

    const bookedSuccessfully = () => {
        debugger
        let Var_Validate = validation();
        if (Var_Validate) {
            const currentTimeUTC = new Date().toUTCString();
            console.log("Current UTC time:", currentTimeUTC);

            var now = new Date();
            var hours = now.getHours().toString().padStart(2, "0");
            var minutes = now.getMinutes().toString().padStart(2, "0");
            var seconds = now.getSeconds().toString().padStart(2, "0");

            var formattedTimeStrings = hours + ":" + minutes + ":" + seconds;
            setWalBookingBtnState(false);
            var date = dateRefapi.current;
            setLoading(true);
            var data = {
                mobile_no: Var_ProfilePhone_Ref.current,
                name: Var_ProfileName_Ref.current,
                age: Var_ProfileAge_Ref.current,
                gender: Var_gendervalue_Ref.current,
                book_date: startDate_Ref.current.toLocaleDateString("fr-CA").toString(),
                from_time: Var_SelectTimeSlots_Ref.current,
                to_time: Var_SelectToTimeSlots_Ref.current,
                service_type_id: Var_GetServiceListSelect_Ref.current.id,
                book_amount: 0,
                total_slots: 0,
                payment_status: 0,
                clinic_id: localStorage.getItem("Clinic_id"),
                doctor_id: localStorage.getItem("Doctor_id"),
                created_by: 1091,
                arrived_status: moment(startDate).format("DD-MMM-YY") == moment(new Date()).format("DD-MMM-YY") ? "1" : "0",
                arrived_order_list: moment(startDate).format("DD-MMM-YY") == moment(new Date()).format("DD-MMM-YY")
                    ? new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds() : "NULL",
                speciality_id: localStorage.getItem("speciality_Id"),
                symptoms: JSON.stringify(Var_symptomsValue_Ref.current),
            };
            console.log("bookdata", data);

            axios
                .post("clinic/insert_patient_walkin", data)
                .then((response) => {
                    {
                        if (response.data.status == 1) {
                            setVar_AddAppointmentMsg("Congratulations! Appointment Added Successfully.");
                            setVar_SuccessModelText("Walk-in Booking added successfully!");
                            setIsOpenAddDealModel(!isOpenAddDealModel);
                            setIsOpenSuccessModel(!isOpenSuccessModel);
                            setWalBookingBtnState(true);
                            setLoading(false);
                            setVar_ProfileAge("");
                            setVar_ProfilePhone("");
                            setVar_ProfileName("");
                            setVar_GetServiceListSelect({
                                cost: "",
                                description: "",
                                doctor_id: "",
                                file_name: "",
                                file_path: "",
                                id: 0,
                                is_active: "",
                                service: "Select Service",
                                slot_duration: "",
                                spec_id: "",
                            });
                            setVar_GetSpecilaitySelect({
                                id: "",
                                speciality: "Select Speciality"
                              });
                            setVar_GetSymptoms("");
                            setishiddensuccess(true);
                            setTimeout(() => {
                                setishiddensuccess(false);
                                getAllAppointment();
                            }, 3000);
                        } else {
                            setVar_AddAppointmentMsg(response.data.msg + "!");
                            setishiddensuccess(true);
                            setTimeout(() => {
                                setishiddensuccess(false);
                                getAllAppointment();
                            }, 3000);
                        }
                        // setVar_WalkInbooked(response.data.data)
                    }
                })
                .catch((error) => {
                    setWalBookingBtnState(true);
                    setLoading(false);
                    console.log(error);
                });
        }
    };


    const isCloseModel = (modelname) => {
        if (modelname == "WalkInModel") {
            setVar_ProfileAge("");
            setVar_ProfilePhone("");
            setVar_ProfileName("");
            setVar_gender("Select");
            setVar_WalingNoSlots("0");
            setVar_WalkInGetSlots([]);
            setVar_GetServiceListSelect({
                cost: "",
                description: "",
                doctor_id: "",
                file_name: "",
                file_path: "",
                id: 0,
                is_active: "",
                service: "Select Service",
                slot_duration: "",
                spec_id: "",
            });
            setVar_GetSymptoms("");
            setStartDate(new Date());
            setVar_symptomsValue([]);
        } else if (modelname == "WalkInQrModel") {
            setisOpenWalkInQrModel(false);
            setVar_SuccessModelText("Walk-in Booking added successfully!");
            setIsOpenSuccessModel(!isOpenSuccessModel);
        } else if (modelname == "QrCodeModel") {
            setisOpenQrCodeModel(false);
        }
    }
    const patientDetails = (data) => {
        debugger;
        setVar_NextPatientDetailsData([]);
        setVar_NextPatientDetailsData(data);
        console.log(Var_NextPatientDetailsData_Ref.current, "87654323456");

    }



    const addPatientModel = () => {
        setVar_WalingNoSlots("0");
        setVar_WalkInGetSlots([]);
        setVar_GetServiceListSelect({
            cost: "",
            description: "",
            doctor_id: "",
            file_name: "",
            file_path: "",
            id: 0,
            is_active: "",
            service: "Select Service",
            slot_duration: "",
            spec_id: "",
        });
        setVar_GetSpecilaitySelect({
            id: "",
            speciality: "Select Speciality"
          });
        setVar_GetSymptoms("");
        // getservice();
        setVar_gender("Select");
        setVar_SelectTimeSlots("");
        setVar_SlotsLength("");
        setwalname("");
        setwalmobile("");
        setwalage("");
        setwalgender("");
        setwalservice("");
        setwalsymptoms("");
        setwalspecial("")
        setIsOpenAddDealModel(!isOpenAddDealModel);
        setStartDate(new Date());
        setVar_symptomsValue([]);
    };
    const getspeciality = () => {
        setLoading(true);
        var data = {
          doctor_id: localStorage.getItem("Doctor_id")
        };
        axios
    
          ({
            method: "POST",
            url: "doctor/getSpecialitybyDoctor",
            data: data,
          })
    
          .then((response) => {
            setVar_SpecialityDoctorList(response.data.data);
            setVar_GetSpecilaitySelect({
              id: "",
              speciality: "Select Speciality"
            });
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }

    const getSlotsService = () => {
        if (Var_GetServiceListSelect_Ref.current.id != "" || Var_GetServiceListSelect_Ref.current.id != 0) {
            setwalservice(false);
        }
        setLoading(true);
        var date = dateRefapi.current;
        var data = {
            search_date: startDate_Ref.current.toLocaleDateString("fr-CA").toString(),
            clinic_id: localStorage.getItem("Clinic_id"),
            doctor_id: localStorage.getItem("Doctor_id"),
            service_id: Var_GetServiceListSelect_Ref.current.id,
            type: "",
            nxtdate: "true",
        };
        axios
            .post("doctor/getslotsbyservice", data)
            .then((response) => {
                var check = new Date().toLocaleTimeString("en-US", {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false
                });
                {
                    console.log("Sloats", response.data.data[0].date_slots);
                    if(Var_DateRef.current === moment(new Date()).format("DD-MMM-YY")){
                        var filteredData = response.data.data[0].date_slots.filter(
                          (x) => x.appointtype_id == "1"  && check < x.from_time
                        );
                        setVar_WalkInGetSlots(filteredData);
                     } else{
                    var filteredData = response.data.data[0].date_slots.filter(
                        (x) => x.appointtype_id == "1"
                    );
                    setVar_WalkInGetSlots(filteredData);
                }
                    // setVar_WalkInGetSlots(response.data.data[0].date_slots);
                    setVar_SlotsLength(response.data.data[0].date_slots.length);
                    setVar_SlotsLength(response.data.data[0].date_slots.length);
                    setLoading(false);
                    if (response.data.data[0].date_slots.length == 0) {
                        setVar_WalingNoSlots("1");
                        setLoading(false);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const getservice = (special_id) => {
        setLoading(true);
        var data = {
            // doctor_id: localStorage.getItem("doctor_id"),
            // speciality_id: localStorage.getItem("speciality_Id"),
            doctor_id: localStorage.getItem("Doctor_id"),
            speciality_id: special_id
        };
        axios
            .post("doctor/getDoctorServiceBySpecilaity", data)
            .then((response) => {
                {
                    console.log("service", response.data.data);
                    setVar_GetServiceList(response.data.data);
                    setVar_GetServiceListSelect({
                        cost: "",
                        description: "",
                        doctor_id: "",
                        file_name: "",
                        file_path: "",
                        id: 0,
                        is_active: "",
                        service: "Select Service",
                        slot_duration: "",
                        spec_id: "",
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const selectSlots = (value) => {
        Var_WalkInGetSlots_Ref.current.forEach((element) => {
            element.is_clicked = 0;
        });
        setVar_SelectTimeSlots(value.from_time);
        value.is_clicked = 1;
        setVar_SelectToTimeSlots(value.to_time);
    };

    const timeFormat = (time) => {
        let datetime = new Date("1970-01-01T" + time + "Z");
        return datetime.toLocaleTimeString("en-US", {
            timeZone: "UTC",
            hour12: true,
            hour: "numeric",
            minute: "numeric",
        });
    };


    const getworkingDetails = () => {
        setLoading(true);
        var DoctorServiceBySpecilaity = {
            doctorId: localStorage.getItem("Doctor_id"),
            clinicId: localStorage.getItem("Clinic_id"),
            limit: 100,
            pageno: 1,
        };
        axios({
            method: "POST",
            url: "doctor/getdocAppointmentSettings",
            data: DoctorServiceBySpecilaity,
        })
            .then((response) => {
                if (response.data.status == 1) {
                    if (response.data.data[0].details.length > 0) {
                        setVar_WorkingHoursTotalCount(response.data.data[0].totalCount);
                    } else {
                        setVar_WorkingHoursTotalCount(0);
                    }
                } else {
                    setVar_WorkingHoursTotalCount(0);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const getManageServicesList = () => {
        setLoading(true);
        var Var_RequestData = {
            doctor_id: localStorage.getItem("Doctor_id"),
        };

        axios({
            method: "POST",
            url: "doctor/getDoctorService",
            data: Var_RequestData,
        })
            .then((response) => {
                if (response.data.status == 1) {
                    setVar_ManageServicesTotalCount(response.data.data.length);
                } else {
                    setVar_ManageServicesTotalCount(0);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    const getSymptoms = () =>{
        if(Var_GetSymptoms_Ref.current !==""){
        let symptoms = [];
        symptoms = [...Var_symptomsValue_Ref.current];
        symptoms.push(Var_GetSymptoms_Ref.current)
        setVar_symptomsValue(symptoms);
        setVar_GetSymptoms("");
        }
    }
    const removeSymptoms = (index) =>{
    let symptoms = [];
    symptoms = [...Var_symptomsValue_Ref.current];
    symptoms.splice(index,1);
    setVar_symptomsValue(symptoms);
    }

    return (
        <div>
            {loading &&
                <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
            }
            {/* ====================Dashboard Module Image Start=================== */}

            <div className='main_card_image' >

                <Grid container className='first_row_card_image'                 >
                    <Grid item xs={12} md={12} align='center'>
                        <div className='card_image_content mr_t_2rem'>
                            <p className='dateOf'>DATE: {moment(Var_Date_Today).format("DD-MMM-YY")}</p>

                            <div style={{ borderRadius: 20, marginLeft: 20 }}>
                                <div style={{ position: "relative" }}>
                                    <img
                                        src={dashboard_back}
                                        alt="Background"
                                        className="dashImageBack"
                                        style={{ width: '100%', borderRadius: '20px' }} // Ensure the image takes the full width and fits in the container
                                    />
                                    <Grid container className="positionOf">
                                        <Grid item xs={8} md={8} align="left" style={{ marginTop: 20 }}>
                                            <p className="headerTextOf">
                                                Good Morning, Josh Little
                                            </p>
                                            <p className="headerTextOf2">
                                                Have a nice day at work it seems like you're <br />
                                                referring to Josh Little
                                            </p>
                                        </Grid>

                                        <Grid item xs={4} md={4} className="dashImagePosition" >
                                            <img
                                                src={dashboardFile}
                                                alt="Dashboard File"
                                                className="dashImage"
                                                style={{ width: '100%', height: 'auto' }} // Make sure the image scales properly
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>


                <Grid container className='first_row_card_image'>
                    <Grid item xs={12} md={12} align='center'>
                        <div className='card_image_content mr_t_2rem' style={{ display: "flex", justifyContent: "left" }}>
                            <div className="appointGrid">
                                <Grid container >
                                    <Grid item xs={3} md={3}
                                        style={{ marginTop: 25 }} >
                                        <div className="addPlus" >
                                            <p className="addPlus2"  onClick={() => addPatientModel()}>+</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9} md={9} align='left'
                                        style={{ marginTop: 25 }}>
                                        <p className="addAppoint"
                                            // onClick={() => openAppoint()}
                                            onClick={() => addPatientModel()}
                                        >Add Appointment</p>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className="appointGap2">
                    <Grid container className='first_row_card_image'>
                        <Grid item xs={12} md={12} align='center'>
                            <div className='card_image_content mr_t_2rem'>
                                <div style={{ marginLeft: 20 }}>
                                    <Grid container >
                                        <Grid item xs={6} md={3} align='left'>
                                            <div className="services1" onClick={() => handleOnClick("appointments")}>
                                                <div className="appointBox">APPOINTMENTS</div>  
                                                <div>
                                                    <Grid container>
                                                        <Grid item xs={9} md={9}>
                                                            <p style={{ fontSize: 25, marginBottom: 7, color: "#2F887C", fontWeight: 600, marginLeft: 10 }}>{Var_CheckOutStatus_Ref.current}/<span style={{ fontSize: 25 }}>{Var_TotalCount_Ref.current}</span></p>
                                                        </Grid>
                                                        <Grid item xs={3} md={3} align='right'>
                                                            <img src={appointSvg} style={{ height: "30px", width: "30px", marginBottom: 10, marginRight: 15 }}></img>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} md={3} align='left' className="serviceImage">
                                            <div className="services3" onClick={() => handleOnClick("manageservice")}>
                                                <div className="appointBox">SERVICES</div>
                                                <div>  <Grid container>
                                                    <Grid item xs={9} md={9}>
                                                    <p style={{ fontSize: 25, marginBottom: 7, color: "#e4b230", fontWeight: 600, marginLeft: 10 }}>{Var_ManageServicesTotalCount_Ref.current}</p>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} align='right'>
                                                        <img src={serviceSvg} style={{ height: "30px", width: "30px", marginBottom: 10, marginRight: 15 }}></img>
                                                    </Grid>
                                                </Grid></div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} md={3} align='left'>
                                            <div className="services2"
                                                onClick={() => handleOnClick("workinghours/viewworkinghours")}>
                                                <div className="appointBox">WORKING HOURS</div>
                                                <div>  <Grid container>
                                                    <Grid item xs={9} md={9}>
                                                    <p style={{ fontSize: 25, marginBottom: 7, color: "#e1446a", fontWeight: 600, marginLeft: 10 }}>{Var_WorkingHoursTotalCount_Ref.current}</p>
                                                         </Grid>
                                                    <Grid item xs={3} md={3} align='right'>
                                                        <img src={hrSvg} style={{ height: "30px", width: "30px", marginBottom: 10, marginRight: 15 }}></img>
                                                    </Grid>
                                                </Grid></div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} md={3} align='left' className="serviceImage">
                                            <div className="services4">
                                                <div className="appointBox">REVENUE</div>
                                                <div>  <Grid container>
                                                    <Grid item xs={9} md={9}>
                                                    <p className="dashboard_content_card_text_revenue">{getTotalRevenue}</p>
                                                    </Grid>
                                                    <Grid item xs={3} md={3} align='right'>
                                                        <img src={revenueSvg} style={{ height: "30px", width: "30px", marginBottom: 10, marginRight: 15 }}></img>
                                                    </Grid>
                                                </Grid></div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <div className="appointGap">
                    <Grid container className='first_row_card_image'>
                        <Grid item xs={12} md={12} align='center'>
                            <div className='card_image_content mr_t_2rem'>
                                <div style={{ marginLeft: 20 }}>
                                    <Grid container style={{ marginBottom: 70 }}>
                                        <Grid item xs={12} md={5.8} align='left' style={{ borderRadius: 10, border: "2px solid #f3f0f0", boxShadow: "0px 3px 6px #00000029" }}>
                                            <div className="nextPatient">
                                                <p style={{marginBottom:0}}>UPCOMING APPOINTMENTS</p>
                                                <hr className="dashboard_table_card_line" />
                                            </div>

                                            <div className="row dashboard_table_card_row">
                                <div className="col-2 dashboard_table_card_header">
                                    <h6 className="dashboard_table_card_header_text" style={{ paddingLeft: '10px' }}><span>S.No</span></h6>
                                </div>
                                <div className="col-4 dashboard_table_card_header">
                                    <h6 className="dashboard_table_card_header_text"><span>Name</span></h6>
                                </div>
                                <div className="col-3 dashboard_table_card_header">
                                    <h6 className="dashboard_table_card_header_text"><span>Gender</span></h6>
                                </div>
                                <div className="col-3 dashboard_table_card_header">
                                    <h6 className="dashboard_table_card_header_text"><span>Phone No</span></h6>
                                </div>
                            </div>
                            {currentData.length != 0 ?
                                <div className="dashboard_upcoming_appointment_data">
                                    {currentData.map((responseData, i) => (
                                        <div className="dashboard_card_content_new" key={i} onClick={() => patientDetails(responseData)}>
                                            <div className={`dashboard_card_content_new ${i % 2 === 0 ? "row" : "row dashboard_content_row_data"}`}>
                                                <div className="col-2">
                                                    <h6 className="dashboard_table_card_header_text" style={{ paddingLeft: '13px' }}>{i + 1}</h6>
                                                </div>
                                                <div className="col-4">
                                                    <h6 className="dashboard_table_card_header_text">{responseData.name}</h6>
                                                </div>
                                                <div className="col-3">
                                                    <h6 className="dashboard_table_card_header_text">{responseData.gender}</h6>
                                                </div>
                                                <div className="col-3">
                                                    <h6 className="dashboard_table_card_header_text">{responseData.mobile_no}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div> :
                                <div className="row">
                                    <p className="text-center no_data_found">No Data Found</p>
                                </div>
                            }

                                            {/* 
                                            <div>
                                                <table className="table" style={{ width: '100%' }}>
                                                    <thead style={{ backgroundColor: "#2F887C", textAlign: "center", verticalAlign: "middle" }}>
                                                        <tr>
                                                            <th className="dash_table dash_head">S.No</th>
                                                            <th className="dash_head">Name</th>
                                                            <th className="dash_head">Gender</th>
                                                            <th className="dash_head">Phone No</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                              {currentData.length != 0 ? 
                                                <div style={{ maxHeight: '160px', overflowY: 'auto' }}>
                                                    <table className="table" style={{ width: '100%' }}>
                                                        <tbody>
                                                            {currentData.map((user, i) => (
                                                                <tr style={{ textAlign: "center", verticalAlign: "middle" }} key={user.patient_id}
                                                                    onClick={() => patientDetails(user)}
                                                                >
                                                                    <td>{i + 1}</td>
                                                                    <td style={{ textAlign: "left" }}>{user.name}</td>
                                                                    <td style={{ textAlign: "left" }}>{user.gender}</td>
                                                                    <td style={{ textAlign: "left" }}>{user.mobile_no}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div> :
                                                 <div className="row" style={{marginTop:"13%"}}>
                                                 <p className="text-center no_data_found">No Data Found.</p>
                                             </div>
}
                                            </div> */}
                                        </Grid>
                                        <Grid item xs={12} md={0.4} align='left'>
                                        </Grid>
                                        <Grid item xs={12} md={5.8} align='left' style={{ borderRadius: 10, border: "2px solid #f3f0f0", boxShadow: "0px 3px 6px #00000029" }}>
                                            <div className="nextPatient">
                                                <p style={{marginBottom:0}}>NEXT PATIENT DETAILS</p>
                                                <hr className="dashboard_table_card_line" />
                                            </div>
                                            <Grid container style={{ marginBottom: 20 }}>
                                                <Grid item xs={3} md={3} align='center'>
                                                    {(Var_NextPatientDetailsData_Ref.current?.gender == 'Male' || Var_NextPatientDetailsData_Ref.current?.gender == 'male') ?
                                                        <img className='dashboard_next_patient_image' src={MaleIcon} alt="dashboard_next_patient_image"
                                                            style={{ height: "55px", width: "60px" }}
                                                        /> :
                                                        (Var_NextPatientDetailsData_Ref.current?.gender == 'Female' || Var_NextPatientDetailsData_Ref.current?.gender == 'female') ?
                                                            <img className='dashboard_next_patient_image' src={FemaleIcon} alt="dashboard_next_patient_image"
                                                                style={{ height: "55px", width: "60px" }} />
                                                            : <img className='dashboard_next_patient_image' src={VectorIcon} alt="dashboard_next_patient_image"
                                                                style={{ height: "55px", width: "60px" }} />}
                                                </Grid>
                                                <Grid item xs={3} md={3} align='left'>
                                                    <div className="nextName">Name</div>
                                                    <div>
                                                        <p>{Var_NextPatientDetailsData_Ref.current?.name}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} md={6} align='left'>
                                                    <div className="nextName">Gender</div>
                                                    <div>
                                                        <p>{Var_NextPatientDetailsData_Ref.current?.gender}</p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ marginBottom: 30 }}>
                                                <Grid item xs={3} md={3} align='left'>
                                                </Grid>
                                                <Grid item xs={3} md={3} align='left'>
                                                    <div className="nextName">Patient ID</div>
                                                    <div>
                                                        {Var_NextPatientDetailsData_Ref.current?.length != 0 ?
                                                            <p className="dashboard_next_patient_content">ID000{Var_NextPatientDetailsData_Ref.current?.patient_id}</p>
                                                            : ''}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3} md={3} align='left'>
                                                    <div className="nextName">Phone No</div>
                                                    <div>
                                                        <p>{Var_NextPatientDetailsData_Ref.current?.mobile_no}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3} md={3} align='left'>
                                                    <div className="nextName">Age</div>
                                                    <div>{Var_NextPatientDetailsData_Ref.current?.age}</div>
                                                </Grid>
                                            </Grid>
                                            {Var_NextPatientDetailsData_Ref.current?.symptoms != null ? (
                            <div className="dashboard_next_patient_details_row3">
                              {(() => {
                              // Parse the symptoms JSON string into an array
                                const symptoms =
                                 Var_NextPatientDetailsData_Ref.current?.symptoms &&
                                JSON.parse(Var_NextPatientDetailsData_Ref.current.symptoms);

                                // Map through the symptoms array and render each symptom
                             return symptoms?.map((item, index) => (
                            <label className="dashboard_next_patient_content_symptoms">{item.trim()}</label>
                            ));
                             })()}
                            </div>
                            ) : null}
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="barChartsHead">
                    <Grid container className="barCharts">
                        <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={10} md={10}>
                            <div className="barMargin">
                                <p className="barIncome">INCOME</p>
                                <hr className="dashboard_table_card_line2" />
                                <p style={{ fontSize: 31, fontWeight: "600" }}>₹{getTotalRevenue}</p>
                            </div>
                            </Grid>
                       
                            <Grid item xs={2} md={2}>
                                <div className="enter_input_name" style={{marginTop:'7%'}}>
                                    <FormControl sx={{ m: "8px 0px"}} size="small">
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={Var_year}
                                            renderValue={(p) => p} onChange={(e) => getYear(e)}>
                                                    <MenuItem value={2021}>2021</MenuItem>
                                                    <MenuItem value={2022}>2022</MenuItem>
                                                    <MenuItem value={2023}>2023</MenuItem>
                                                    <MenuItem value={2024}>2024</MenuItem>
                                                    <MenuItem value={2025}>2025</MenuItem>
                                                    <MenuItem value={2026}>2026</MenuItem>
                                                    <MenuItem value={2027}>2027</MenuItem>
                                                    <MenuItem value={2028}>2028</MenuItem>
                                                    <MenuItem value={2029}>2029</MenuItem>
                                                    <MenuItem value={2030}>2030</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            </Grid>
                            <MonthlyChart data={revenueIncome} />
                        </Grid>
                    </Grid>
                </div>

            </div >
                <Dialog
                fullWidth={fullWidth}
                alignment="center"
                size="md"
                open={isOpenAddDealModel}
                onClose={setIsOpenAddDealModel}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "850px",
                            borderRadius: "8px !important",
                            padding: "10px 0px 30px"
                        },
                    },
                }}
            >
                <div className="dashboard_dialog_header">
                    <div className="flex_grow"></div>
                    {/* <div className="walk_appointment_booking" style={{marginBottom:"5px"}}>
                                    <p className="walk_model_header_text">
                                        Select Appointment Date<span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div> */}
                                <DatePicker
                                selected={startDate}
                                onChange={(date) =>getAppointmentDate(date)} // Set the selected date
                                customInput={<CustomInput />} // Use custom input with the icon
                                minDate={new Date()} // Prevent dates before today
                                />
                    <div onClick={() => closeAppoint()} style={{cursor:"pointer"}}>
                        <img src={closeicon} />
                    </div>
                </div>
                <DialogContent>
                    <Container>
                        <div className="row">
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_left_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Patient Name
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_ProfileName}
                                        type="text"
                                        placeholder="Enter full name"
                                        onChange={(event) => setVar_ProfileName(event.target.value)}
                                    />
                                </div>
                                {Var_ProfileName_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walname == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                                Field Required
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Age<span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_ProfileAge}
                                        type="number"
                                        placeholder="Enter age"
                                        maxLength="3"
                                        onChange={(e) => walkinMobileNumbetValidation(e, "Age")}
                                    />
                                </div>
                                {Var_ProfileAge_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walage == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                            Field Required
                                        </div>
                                        )}
                                    </>
                             )} 
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_right_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Gender
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <FormControl
                                        sx={{ m: "8px 0px", minWidth: 353 }}
                                        size="small"
                                    >
                                        <Select style={{border: "0px solid #2F887C"}}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={Var_gender}
                                            renderValue={(p) => p}
                                            onChange={(e) => getGender(e)}
                                        >
                                            <MenuItem value={"M"}>Male</MenuItem>
                                            <MenuItem value={"F"}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {walgender == true && (
                                    <div className="field_required">Field Required</div>
                                )}
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Phone Number
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_ProfilePhone}
                                        type="number"
                                        placeholder="Enter number"
                                        maxLength="10"
                                        onChange={(e) =>
                                            walkinMobileNumbetValidation(e, "PhoneNumber")
                                        }
                                    />
                                </div>
                                {Var_ProfilePhone_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walmobile == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                            Field Required
                                        </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_service_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Symptoms
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="symptom_parent">
                                    <input
                                        className="symptom_input"
                                        value={Var_GetSymptoms}
                                        type="text"
                                        placeholder="Enter symptoms"
                                        onChange={(event) => setVar_GetSymptoms(event.target.value)}
                                    />
                                     <img  className="symptom_add_icon" onClick={()=>getSymptoms()} src={AddIcon}></img>
                                </div>
                                {Var_symptomsValue_Ref.current?.map((item,i) =>(
                                <span className="symptoms_chip_main">{item}{" "}<img onClick={()=>removeSymptoms(i)}  alt="Remove" className="symptoms_remove_icon" src={closeIcon}></img></span>
                                ))}
                                {Var_symptomsValue_Ref.current.length !== 0  ? (
                                    <></>
                                ) : (
                                    <>
                                        {walsymptoms == true && (
                                             <div item xs={12} className="field_required pb-1 pt-0">
                                             Field Required
                                         </div>
                                        )}
                                    </>
                             )}
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_service_part">
                <div className="walk_appointment_booking">
                  <p className="walk_model_header_text">
                    Select Specialty
                    <span className="walk_model_header_text_valid"> *</span>
                  </p>
                </div>

              <div className="dropdown_control">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      sx={{
                        padding: "8.5px 14px",
                        height: "40px",
                        minHeight: 15,
                        minWidth: "-webkit-fill-available",
                      }}
                      value={Var_GetSpecilaitySelect}
                      onChange={(e) => {
                        setVar_GetSpecilaitySelect(e.target.value);
                        getservice(Var_GetSpecilaitySelect_Ref.current.id);
                        setVar_SelectTimeSlots("");
                        setVar_WalingNoSlots("0");
                        setVar_WalkInGetSlots([]);
                        setwalspecial(false);
                      }}
                      renderValue={(Var_GetSpecilaitySelect) => {
                        return Var_GetSpecilaitySelect.speciality;
                      }}
                    >
                      <MenuItem
                        key={0}
                        value={{
                          id: "",
                          speciality: "Select Speciality"
                        }}
                      ></MenuItem>

                      {Var_SpecialityDoctorList_Ref.current.map((item) => (
                        <MenuItem key={item.speciality} value={item}>
                          <Container>
                            <Grid container>
                              <Grid
                                item
                                xs={9}
                                className="ref_lab"
                                align="start"
                              >
                                <p className="dropdowntext">{item.speciality}</p>
                              </Grid>
                            </Grid>
                          </Container>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                {walspecial == true &&
                  <Grid item xs={12} className="field_required py-1">
                    Field Required
                  </Grid>
                }
              </div>
              </div>
                            <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 walk_model_service_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Select Service
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>

                                <div className="dropdown_control">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select style={{border: "0px solid #2F887C"}}
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                sx={{
                                                    padding: "8.5px 14px",
                                                    height: "40px",
                                                    minHeight: 15,
                                                    minWidth: "-webkit-fill-available",
                                                }}
                                                value={Var_GetServiceListSelect}
                                                onChange={(e) => {
                                                    setVar_GetServiceListSelect(e.target.value);
                                                    getSlotsService();
                                                    setVar_SelectTimeSlots("");
                                                }}
                                                renderValue={(Var_GetServiceListSelect) => {
                                                    return Var_GetServiceListSelect.service;
                                                }}
                                            >
                                                <MenuItem
                                                    key={0}
                                                    value={{
                                                        cost: "",
                                                        description: "",
                                                        doctor_id: "",
                                                        file_name: "",
                                                        file_path: "",
                                                        id: 0,
                                                        is_active: "",
                                                        service: "Select Service",
                                                        slot_duration: "",
                                                        spec_id: "",
                                                    }}
                                                ></MenuItem>

                                                {Var_GetServiceList.map((item) => (
                                                    <MenuItem key={item.service} value={item}>
                                                        {item.is_active == 1 && (
                                                            <Container>
                                                                <Card className="dropdown_card">
                                                                    <Grid container>
                                                                        <Grid item xs={1}></Grid>
                                                                        <Grid item xs={1} align="start">
                                                                            <img
                                                                                src={
                                                                                    item.file_path == " "
                                                                                        ? VectorIcon
                                                                                        : item.file_path ==
                                                                                            "[object Object]"
                                                                                            ? VectorIcon
                                                                                            : item.file_path == ""
                                                                                                ? VectorIcon
                                                                                                : item.file_path
                                                                                }
                                                                                alt="a"
                                                                                className="dropdownimg"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={1}></Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={9}
                                                                            className="ref_lab"
                                                                            align="start"
                                                                        >
                                                                            <p className="dropdowntext">
                                                                                {item.service}
                                                                            </p>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Card>
                                                            </Container>
                                                        )}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    {walservice == true && (
                                        <Grid item xs={12} className="field_required">
                                            Field Required
                                        </Grid>
                                    )}
                                </div>
                                {Var_WalingNoSlots_Ref.current == "1" &&
                                    Var_WalkInGetSlots.length == 0 && (
                                        <Grid container className="modal_slots_time">
                                            <Grid item>
                                                <p className="dashboard_warning_helper_txt">
                                                    Slots Not Available
                                                </p>
                                            </Grid>
                                        </Grid>
                                    )}
                                {Var_WalkInGetSlots.length != 0 && (
                                    <>
                                        <Grid container className="modal_slots_time">
                                            <Grid item>
                                                <p className="dashboard_helper_txt">Slots</p>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ justifyContent: "space-evenly" }}
                                            >
                                                <Grid container className="dashboard_total_slots_top">
                                                    {Var_WalkInGetSlots?.map((value) => (
                                                        <Grid item xs={2}>
                                                            {(value.date >=
                                                                moment(new Date()).format("YYYY-MM-DD") ==
                                                                true ||
                                                                value.from_time >=
                                                                new Date().getHours() +
                                                                ":" +
                                                                new Date().getMinutes() +
                                                                ":" +
                                                                new Date().getSeconds()) && (
                                                                    <>
                                                                        <div className="dashboard_time_sloat">
                                                                            <p
                                                                                className={`dashboard_time_show ${value.is_booked != 0
                                                                                    ? "dashboard_time_disabled"
                                                                                    : ""
                                                                                    }`}
                                                                                onClick={() => {
                                                                                    if (value.is_booked == 0) {
                                                                                        selectSlots(value);
                                                                                    }
                                                                                }}
                                                                                disabled={
                                                                                    value.is_booked == 1 ? true : false
                                                                                }
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        value.is_booked == 0
                                                                                            ? value.is_clicked == 1
                                                                                                ? "var(--app-theme)"
                                                                                                : "#2A7A6F40"
                                                                                            : "#FBEAE5",
                                                                                    color:
                                                                                        value.is_booked == 0
                                                                                            ? value.is_clicked == 1
                                                                                                ? "white"
                                                                                                : "var(--app-theme) "
                                                                                            : "#E12F2F",
                                                                                    border:
                                                                                        value.is_booked == 0
                                                                                            ? "1px solid var(--app-theme) "
                                                                                            : "1px solid #E12F2F",
                                                                                }}
                                                                            >
                                                                                {timeFormat(value.from_time)}
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                <div>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="dashboard_select_finder">
                                                <div className="dashboard_select_icon">
                                                    <div className="dashboard_check_box_booked" />
                                                    <p className="booked">Booked</p>
                                                </div>
                                                <div className="dashboard_select_icon">
                                                    <div className="dashboard_check_box_available" />
                                                    <p className="available">Available</p>
                                                </div>
                                                <div className="dashboard_select_icon">
                                                    <div className="dashboard_check_box_selected" />
                                                    <p className="selected">Selected</p>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div className="walk_appointment_booking_button">
                            <button
                                className="walk_appointment_cancel_button"
                                onClick={() => isCloseModel("WalkInModel")}
                            >
                                Clear
                            </button>
                            <button
                                className="walk_appointment_book_button"
                                onClick={
                                    walBookingBtnStateRef.current
                                        ? () => bookedSuccessfully()
                                        : undefined
                                }
                            >
                                Add Appointment
                            </button>
                        </div>
                    </Container>
                </DialogContent>
            </Dialog>


            {isshowsuccess &&
                <div className="popup-main">
                    <div className="pushNotification_body">
                        <div className="pushNotification_content">
                            <p className="notification_text_head"><b>{Var_AddAppointmentMsg_Ref.current}</b></p>
                        </div>
                        <div className="progress-prt">
                            <div className="progress-bar"></div>
                        </div>
                    </div>
                </div>}
        </div >
    )
}

export default DashboardMasterComponent;


























// import { useHistory } from "react-router-dom";
// import React, { Component, useEffect, useState } from "react";
// import axios from "axios";
// import useStateRef from "react-usestateref";
// import { notification, Spin } from "antd";
// // import LinearProgress from '@mui/material/LinearProgress';


// // Mui meterial component import
// import Grid from '@mui/material/Grid';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// import LinearProgress from '@mui/material/LinearProgress';

// import './DashboardMaster.css'

// import Appoinmentsimg from '../../assets/icon/appointment.svg'
// import Queueimg from '../../assets/icon/queue.svg'
// import Availabilityimg from '../../assets/icon/manageSchedule.svg'
// import Workinghoursimg from '../../assets/icon/workhours.svg'
// import Walkinimg from '../../assets/icon/referral.svg'
// import Cancelledimg from '../../assets/icon/cancelled.svg'
// import Postimg from '../../assets/icon/post.svg'
// import Manageserviceimg from '../../assets/icon/manageService.svg'



// function DashboardMasterComponent(props) {
//     let Var_History = useHistory();
//     console.log(props.menupermission);
//     const [Var_ClinicID, setVar_ClinicID, Var_ClinicID_Ref] = useStateRef(null)
//     const [Var_getClinicWiseDoctor, setVar_getClinicWiseDoctor] = useState([]);
//     var [Var_doctorSpecialitySelect, setVar_doctorSpecialitySelect] = useState("");
//     const [Var_TabPermissionById, setVar_TabPermissionById, Var_TabPermissionById_Ref] = useStateRef(null)
//     const [Var_Availability_access, setVar_Availability_access, Var_Availability_access_Ref] = useStateRef(null)
//     const [Var_Workinghrs_access, setVar_Workinghrs_access, Var_Workinghrs_access_Ref] = useStateRef(null)
//     const [Var_Post_acccess, setVar_Post_acccess, Var_Post_acccess_Ref] = useStateRef(null)
//     const [Var_Service_access, setVar_Service_access, Var_Service_access_Ref] = useStateRef(null)
//     const [loading, setLoading] = useState(false);


//     useEffect(() => {
//         // getClinicWiseDoctor()
//         sendToken();
//         changeLanguage();
//     }, []);
//     // navigation to back start
//     const handleOnClick = (path) => {
//         Var_History.push("/dashboard/" + path)
//     };
//     // navigation to back end

//     const getClinicWiseDoctor = () => {

//         // setLoading(true);
//         var data = {
//             clinic_id: localStorage.getItem("Clinic_id")
//         };
//         axios
//             .post("clinic/getClinicWiseDoctor", data)
//             .then((response) => {
//                 // setVar_getClinicWiseDoctor(response.data.data);
//                 // setVar_doctorSpecialitySelect(response.data.data[0].specialityid);
//                 // localStorage.setItem("Doctor_id", response.data.data[0].doctor_id)
//                 // localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
//                 setLoading(false);

//             })
//             .catch((error) => {
//                 setLoading(false);
//                 console.log(error);
//             });
//     }

//     const noPermission = (type) => {
//         if (type == "appointments") {
//             notification.error({ message: "No permission" })
//         } else if (type == "queue") {
//             notification.error({ message: "No permission" })

//         } else if (type == "availability") {
//             notification.error({ message: "No permission" })

//         } else if (type == "workinghours") {
//             notification.error({ message: "No permission" })
//         }
//         else if (type == "walkinbooking") {
//             notification.error({ message: "No permission" })

//         } else if (type == "cancelledappointments") {
//             notification.error({ message: "No permission" })

//         } else if (type == "post") {
//             notification.error({ message: "No permission" })

//         } else if (type == "manageservice") {
//             notification.error({ message: "No permission" })
//         }
//     }
//     const sendToken = () => {

//         var data = {
//             "vendor_id": localStorage.getItem("Doctor_id"),
//             "token": localStorage.getItem("TokenFCM")
//         };
//         axios
//             .post("admin/insertvendorwebtoken", data)
//             .then((response) => {
//                 console.log("update token response", response);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }

//     const changeLanguage = () => {

//         var data = {
//             "vendor_id": localStorage.getItem("Doctor_id"),
//             "language": "en"
//         };
//         axios
//             .post("admin/insertvendorweblanguage", data)
//             .then((response) => {
//                 console.log("update token response", response);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }

//     return (
//         <div>
//             {/* {loading &&
//                 <div className="loader_body">
//                     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//                         <CircularProgress />
                       
//                     </Box>
//                 </div>
//             } */}
//             {loading &&
//                 <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
//             }
//             {/* ====================Dashboard Module Image Start=================== */}
//             <div className='main_card_image'>
//                 <Grid container className='first_row_card_image'>
//                     <Grid item xs={6} md={3} align='center'>
//                         <div className='card_image_content mr_t_2rem'
//                         >
//                             <div className="image_div">
//                                 <img className='card_image card_image_appointment '   onClick={() => handleOnClick("appointments")} src={Appoinmentsimg} alt="Appoinmentsimg" />
//                             </div>
//                             <p className='home_card_title'>Appointments</p>
//                         </div>
//                     </Grid>
//                     <Grid item xs={6} md={3} align='center'>
//                         <div className='card_image_content mr_t_2rem'
                            
//                         >
//                             <div className="image_div">
//                                 <img className='card_image' onClick={() => handleOnClick("queue")} src={Queueimg} alt="Queueimg" />
//                             </div>
//                             <p className='home_card_title'>Queue</p>
//                         </div>
//                     </Grid>
//                     <Grid item xs={6} md={3} align='center'>
//                         <div className='card_image_content mr_t_2rem'
                            
//                         >
//                             <div className="image_div">
//                                 <img className='card_image' onClick={() => handleOnClick("availability")} src={Availabilityimg} alt="Availabilityimg" />
//                             </div>
//                             <p className='home_card_title'>Manage Schedule</p>
//                         </div>
//                     </Grid>
//                     <Grid item xs={6} md={3} align='center'>
//                         <div className='card_image_content mr_t_2rem'
                          
//                         >
//                             <div className="image_div">
//                                 <img className='card_image'   onClick={() => handleOnClick("workinghours")} src={Workinghoursimg} alt="Workinghoursimg" />
//                             </div>
//                             <p className='home_card_title'>Working Hours</p>
//                         </div>
//                     </Grid>
//                 </Grid>
//                 <Grid container className='first_row_div_image'>
//                     <Grid item xs={6} md={3} align='center'>
//                         <div className='card_image_content'>
//                             <div className="image_div" >
//                                 <img className='card_image card_image_Walkin'  onClick={() => handleOnClick("referral")} src={Walkinimg} alt="Walkinimg" />
//                             </div>
//                             <p className='home_card_title'>Referral</p>
//                         </div>
//                     </Grid>
//                     <Grid item xs={6} md={3} align='center'>
//                         <div className='card_image_content'
                           
//                         >
//                             <div className="image_div">
//                                 <img className='card_image'  onClick={() => handleOnClick("cancelledappointments")}  src={Cancelledimg} alt="Cancelledimg" />
//                             </div>
//                             <p className='home_card_title'>Canceled</p>
//                         </div>
//                     </Grid>
//                     <Grid item xs={6} md={3} align='center'>
//                         <div className='card_image_content'
                          
//                         >
//                             <div className="image_div">
//                                 <img className='card_image'   onClick={() => handleOnClick("post")} src={Postimg} alt="Postimg" />
//                             </div>
//                             <p className='home_card_title'>Post</p>
//                         </div>
//                     </Grid>
//                     <Grid item xs={6} md={3} align='center'>
//                         <div className='card_image_content'
                           
//                         >
//                             <div className="image_div">
//                                 <img className='card_image'   onClick={() => handleOnClick("manageservice")} src={Manageserviceimg} alt="Manageserviceimg" />
//                             </div>
//                             <p className='home_card_title'>Manage Services</p>
//                         </div>
//                     </Grid>
//                 </Grid>
//             </div>
//             {/* ====================Dashboard Module Image End=================== */}
//         </div >
//     )
// }

// export default DashboardMasterComponent;