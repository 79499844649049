// AUTHOR : Dinesh
// CR-DATE: 26-June-2023
// MOD-DATE: 19-July-2023
// DESCRIPTION: Login Update work

import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { notification, Spin } from "antd";
import SignaturePad from 'react-signature-pad'
import moment from "moment";

// Mui meterial component import
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import LinearProgress from '@mui/material/LinearProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import "./Login.css";

import HomeImage from "../../assets/Images/log_im.svg";
import tombutton from "../../assets/Images/tom_journey.svg";
import Inbox from "../../images/inbox.svg";
import EMail_Box from "../../assets/Doctor_Images/Email_Icon.jpeg";
import Logo from "../../assets/Web_Doctor_Icon_new_theme/logo_crop.svg";
import successModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import Alert from "../../assets/Web_Doctor_Icon_new_theme/alert.svg";
import closeicon from '../../assets/Web_Doctor_Icon_new_theme/close.svg';
import useStateRef from "react-usestateref";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import axios from "axios";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CryptoJS from "crypto-js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function Login() {
  let Var_History = useHistory();
  const [Var_Email, setVar_Email] = useState("");
  const [Var_Password, setVar_Password] = useState("");
  const [Var_LoginError, setVar_LoginError] = useState("");
  const [Var_InvalidEmail, setVar_InvalidEmail] = useState("");
  const [Var_ProfilePath, setVar_ProfilePath] = useState("");
  const [Var_ProfileName, setVar_ProfileName] = useState("");
  const [Var_SignatureImage, setVar_SignatureImage] = useState("");
  const [Var_OpenSignatureImage, setVar_OpenSignatureImage] = useState("");
  const [Var_RejectModel, setVar_RejectModel] = useState("");
  const [Var_EsignatureImage, setVar_EsignatureImage] = useState("");
  const [Var_ContractDetailsID, setVar_ContractDetailsID] = useState("");
  const [Var_ContractDetails, setVar_ContractDetails] = useState([]);
  const [Var_ContractResult, setVar_ContractResult] = useState([]);
  const [Var_Signature, setVar_Signature, Var_Signature_Ref] = useStateRef({});
  const [Var_ContractVertification, setVar_ContractVertification] = useState("");
  const [Var_ErrorMsg, setVar_ErrorMsg] = useState("");
  const [Var_ContractApproval, setVar_ContractApproval] = useState("");
  const [Var_TermsCondition_File, setVar_TermsCondition_File] = useState("");
  const [Var_SignedContract_File, setVar_SignedContract_File] = useState("");
  const [Var_ContractMsg, setVar_ContractMsg, Var_ContractMsg_Ref] = useStateRef("");
  const [Var_EmailError, setVar_EmailError] = useState(false);
  const [Var_PasswordError, setVar_PasswordError] = useState(false);
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);
  const [Var_AdminContact, setVar_AdminContact] = React.useState(false);
  const [Var_Hidden, setVar_Hidden] = useState(true);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [vendor_name, setvendor_name] = useState(null);
  const [Var_File, setVar_File, Var_File_Ref] = useStateRef("");
  const [Var_progress, setVar_Progress] = useState(0);
  const [doctorId, setDoctorId, doctorIdRef] = useStateRef(0);
  const [Var_Remainder, setVar_Remainder] = useState(false);

  const [vendorID, setVendorID] = useState("");
  const [vendorTypeID, setvendorTypeID] = useState("");
  var [contract_orSignature, setcontract_orSignature, contract_orSignatureRef] =
    useStateRef(0);
  var [contract_eny_data, setcontract_eny_data, contract_eny_dataRef] =
    useStateRef({});
  const [Var_EmailData, setVar_EmailData, Var_EmailData_Ref] = useStateRef("");
  const [accept_buttonClick, setaccept_buttonClick, accept_buttonClickRef] = useStateRef(false);
  const [, setRegisterationPushNotification, RegisterationPushNotificationRef] = useStateRef(false);

  // initial function call start
  useEffect(() => {
    if ((localStorage.getItem('Doctor_id') != '') && (localStorage.getItem('Doctor_id') != 0)) {
      setDoctorId(localStorage.getItem('Doctor_id'));
      if (doctorIdRef.current != 0) { clearVendorWebToken(); }
    }
    localDataClear();
    vendorprofile();
    setVar_ContractMsg("");
    const canvas = Var_Signature_Ref.current._canvas; // Access canvas safely

    if (canvas) {
      canvas.width = 300;   
      canvas.height = 200; 
      // Default cursor on canvas load
      canvas.style.cursor = 'pointer';

      // Change cursor on mousedown (when the user starts drawing)
      canvas.addEventListener('mousedown', () => {
        canvas.style.cursor = 'pointer'; // Change cursor to crosshair when drawing
      });

      // Change cursor back to default after drawing
      canvas.addEventListener('mouseup', () => {
        canvas.style.cursor = 'pointer'; // Change cursor back to default when done
      });
    }
  }, []);
  // initial function call end

  // navigation method start
  const handleOnClick = (path) => {
    Var_History.push(path)
  };
  // navigation method end

  const iconStyle = {
    fontSize: '26px', // Change the size
    color: '#2F887C', // Change the color
    position: 'absolute',
    top: '68px',
    right: '13px',
    cursor: 'pointer',
  };
  //login validation method start
  const toggleshow = () => {
    setVar_Hidden(!Var_Hidden);
  }

  const getRemainderData = (e) => {
    setVar_Remainder(e);
  }

  const emailValidation = (value) => {
    setVar_Email(value);
    let re = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;

    if (value.match(re)) {
      setVar_InvalidEmail(false);
    } else {
      setVar_InvalidEmail(true);
      setVar_EmailError(false);
    }
    if(value===""){
      setVar_InvalidEmail(false);
    }
  }
  const validation = () => {
    setVar_EmailError(false);
    setVar_PasswordError(false);
    setVar_LoginError("");
    setVar_InvalidEmail(false);
    if (Var_Email === "" &&Var_Password === "") {
      setVar_EmailError(true);
      setVar_PasswordError(true);
      return false;
    }
    else if (Var_Email === "") {
      setVar_EmailError(true);
      return false;
    }
   else if (Var_Password === "") {
      setVar_PasswordError(true);
      return false;
    }
    return true;
  }

  const getPasswordData =(e) =>{
    setVar_Password(e);
    setVar_PasswordError(false);
  }
  const validateEmail = (data) => {
    let re = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    if (data.match(re)) {
      setVar_InvalidEmail(false);
    } else {
      setVar_InvalidEmail(true);
    }
  };
  const localDataClear = () => {
    localStorage.clear("LoginData");
    localStorage.clear("clinic_name");
    localStorage.clear("Clinic_id");
    localStorage.clear("clinic_vendor_type_id");
    localStorage.clear("Doctor_id");
    localStorage.clear("speciality_Id")
  }
  //login validation method end

  function clearVendorWebToken() {
    let requestdata = {
      "vendor_id": doctorIdRef.current
    }
    Axios({
      method: "POST",
      url: "admin/clearVendorWebToken",
      data: requestdata,
    })
      .then((response) => {
        setDoctorId(0);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //login post data start
  const postLogin = async () => {
debugger
    const isVaild = await validation();
    if (isVaild) {
      var logindata = {
        email: Var_Email,
        password: Var_Password,
        module: "doctor",
      };
      setLoading(true);
      Axios({
        method: "POST",
        url: "admin/LoginWeb",
        data: logindata,
      })
        .then((response) => {
          localStorage.setItem("tokenHeader", response.data.token);
          localStorage.setItem("vendorId", response.data.data[0]["vendor_id"]);
          var filtereddata = response.data.data.find(item => item.user_type_id === 1);
          console.log("filtereddata", filtereddata);
          debugger;
          if (response.data.status == 1 && filtereddata.user_type_id == 1) {

            if (response.data.data.length > 0) {

              localStorage.setItem("LoginData", JSON.stringify(response.data.data[0]));
              localStorage.setItem("LoginData", JSON.stringify(filtereddata));
              localStorage.setItem("Doctor_name", filtereddata["user_name"]);
              localStorage.setItem("Doctor_id", filtereddata["vendor_id"]);
              localStorage.setItem("Doctor_vendor_type_id", filtereddata["user_type_id"]);
              localStorage.setItem("Date", (moment(new Date().toLocaleDateString('fr-CA')).format("YYYY-MM-DD")));
              setVar_EmailData(response?.data.data[0].email)
              setLoading(false);
              // if ((filtereddata["contract_status"].toUpperCase() == "P" || filtereddata["contract_status"] == "" || filtereddata["contract_status"] == null) && filtereddata["admin_upload_status"] == "0") {
              //   setVar_AdminContact(!Var_AdminContact);
              // }
              // else if (filtereddata["contract_status"].toUpperCase() == "P" || filtereddata["contract_status"] == "" || filtereddata["contract_status"] == null) {
              //   contractApproved();
              // } else if (filtereddata["contract_status"] == "R") {
              //   notification.error({ message: "Please contact Admin" });
              //   setLoading(false);
              // } else if (filtereddata["contract_status"] == "A") {
                Var_History.push("/dashboard");
                getClinicDoctor();
                localStorage.setItem("isChangeClinic", 0);
              // } else {

              // }
              setLoading(false);
            }
            setLoading(false);
          } else {
            setVar_LoginError("Email Or Password wrong");
            setLoading(false);

          }
        })
        .catch((err) => {
          setLoading(false);
          notification.error({ message: "Invalid username or password" });
        });
    }
  };

  const contractApproved = async () => {
    debugger;
    let requestdata = {
      "vendor_id": localStorage.getItem("Doctor_id")
    }
    setLoading(true);
    Axios({
      method: "POST",
      url: "admin/getVendorContractWeb",
      data: requestdata,
    })
      .then((response) => {
        debugger;
        console.log(response);
        if (response.data.data[0].ErrorCode == 9997) {
          contractResult("Please contact Admin");
        } else {
          setVar_ContractDetails(response.data.data[0]);
          setVar_ContractDetailsID(response.data.data[0].id);
          setVar_EsignatureImage(response.data.data[0].e_signature_file);
          setVar_TermsCondition_File(response.data.data[0].terms_and_condition_file);
          setVar_SignedContract_File(response.data.data[0].signed_contract_file);
          localStorage.setItem("vendor_id", response.data.data[0].id);
          setVendorID(localStorage.getItem("Doctor_id"));
          setvendorTypeID(localStorage.getItem("Doctor_vendor_type_id") == 1 ? 'Doctor' : '');
          vendorprofile();
          contratctVerification();
        }
      })
      .catch((err) => {
        setLoading(false);
      });

  };

  const vendorprofile = () => {
    let vendorprofiledata = {
      "vendor_id": localStorage.getItem("Doctor_id")
    }

    Axios({
      method: "POST",
      url: "admin/getvendorprofile",
      data: vendorprofiledata
    })
      .then((response) => {
        setVar_ProfilePath(response.data.data[0]?.vendor_profile_path);
        setVar_ProfileName(response.data.data[0]?.vendor_name);
      }).catch((error) => {
        console.log(error);
      });
  }
  const signatureSave = (data) => {
    if (Var_Signature_Ref.current.isEmpty()) { }
    else {
      console.log(data, "signature");
      setVar_OpenSignatureImage(false);
      setVar_SignatureImage(data);
      setVar_ErrorMsg("");
    }
  }
  const contratctVerification = async () => {
    setVar_ErrorMsg("");
    setVar_SignatureImage("");
    setVar_ContractVertification(!Var_ContractVertification);
  }
  const contractResult = async () => {
    setVar_ContractResult(!Var_ContractResult);
  }
  const isCloseContractApprovalModel = () => {
    setVar_ContractVertification(!Var_ContractVertification);
  }
  const isCloseSignatureModel = () => {
    setVar_OpenSignatureImage(!Var_OpenSignatureImage);
  }
  const isCloseRejectModel = () => {
    setVar_RejectModel(!Var_RejectModel);
  }
  const isCloseAdminContact = () => {
    setVar_AdminContact(!Var_AdminContact);
  }

  const TermsConditions = async () => {
    debugger;
    if (Var_TermsCondition_File != "") {
      await pdfgenerate()
    }
  }
  const SignedContract = () => {
    if (Var_SignedContract_File != "") {
      window.open(Var_SignedContract_File, "_blank")
    }
  }
  const contractChange = (isapporve) => {
    if (isapporve) {
      setVar_ContractVertification(!Var_ContractVertification);
      setVar_ContractApproval(!Var_ContractApproval);
    }
    else {
      setVar_ContractVertification(!Var_ContractVertification);
    }
  }

  const rejectContract = () => {
    Axios({
      method: "POST",
      url: "admin/rejectVendorContract",
      data: {
        "contract_id": Var_ContractDetailsID
      },
    })
      .then((response) => {
        if (response.data.data[0]["ErrorCode"] == "9999") {
          setVar_RejectModel(!Var_RejectModel);
          setVar_ContractVertification(!Var_ContractVertification);
          setVar_ContractMsg('REJECT');
          setVar_IsShowSuccess(!Var_IsShowSuccess);
        }
      })
  }

  const saveContract = () => {
    setaccept_buttonClick(true);
    debugger;
    if ( Var_SignedContract_File != "" && Var_SignedContract_File != "null" && Var_SignedContract_File != null && Var_SignedContract_File != "undefined") {
      if (Var_SignatureImage != "") {
        setVar_ErrorMsg("");
        var file_url = dataURLtoFile(Var_SignatureImage, "signature.png");
     
        const formData = new FormData();
        formData.append('file', file_url);
        formData.append('module_id', "1");
        formData.append('pathLocation', "SIGNATURE/");
        Axios({
          method: "POST",
          url: "admin/awsS3FileUpload",
          data: formData,
          onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setVar_Progress(percentage);
          },
        })
          .then(async (response) => {
            debugger;
   
            setVar_File(response.data.filepath.privatePathUrl);
            if (Var_File_Ref.current != '') {
              Signature(Var_File_Ref.current)
              await pdfgenerate()
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setVar_ErrorMsg("Please add signature");
      }
    } else {
      setVar_ContractVertification(!Var_ContractVertification);
      setVar_AdminContact(!Var_AdminContact);
    }
  }

  const getClinicDoctor = () => {
    setLoading(true);

    var data = {
        doctorId: localStorage.getItem("Doctor_id")
    };
    axios
        .post("doctor/getDoctorClinics", data)
        .then((response) => {
            localStorage.setItem("Clinic_id", response.data.data[0].clinicId);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        });
}
  const Signature = (data) => {
 
    let requestdata = {
      "contract_id": Var_ContractDetailsID,
      "module_name": "Doctor",
      "signature_image": data,
      "vendor_id":localStorage.getItem("vendorId"),
      "token": localStorage.getItem("tokenHeader")
    };

    Axios({
      method: "POST",
      url: "admin/vendorContractApproval",
      data: requestdata,
    })
      .then((response) => {
        debugger;
        if (response.data.data[0]['ErrorCode']) {
          localStorage.setItem('approvalpopupClose', "accept");
          setVar_ContractVertification(!Var_ContractVertification);

          setVar_ContractMsg('SUCCESS');
          setVar_IsShowSuccess(!Var_IsShowSuccess);
          setRegisterationPushNotification(true);
          setvendor_name(response.data.data[0].vendor_name);
          setTimeout(() => {
            setRegisterationPushNotification(false);
          }, 6000);

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const isCloseConstractSuccessModel = () => {
    setVar_IsShowSuccess(!Var_IsShowSuccess);
    if (Var_ContractMsg_Ref.current == 'SUCCESS') {
      Var_History.push("/dashboard");
    }
  }

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  //Edit Profile Details List end
  async function getEncryptedData(id, vendorTypeID) {
    try {
      const response = await axios.post("admin/decryptedContent_data", {
        id: id,
        vendor_type: vendorTypeID,
      });
      setcontract_eny_data(response?.data?.data.UpdateVendor)
      return response;
    } catch (error) {
      if (!error.response) {
       
      } else {
        // toast(error);
      }
    }
  }
  const Decrypted_Editor_content = (encryptedContent) => {
    const fkey = CryptoJS.enc.Hex.parse(
      "00112233445566778899aabbccddeeff00112233445566778899aabbccddeeff"
    );
    try {
      const fiv = CryptoJS.enc.Hex.parse("00112233445566778899aabbccddeeff");

      const decryptedBytes = CryptoJS.AES.decrypt(encryptedContent, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const decryptedContent = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedContent;
    } catch (error) {
      return error;
    }
  };
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with zero if needed
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

    return `${day}-${month}-${year}`; // Format the date
  };
  const pdfgenerate = async () => {
    try {

      const getEncrypted = await getEncryptedData(vendorID, vendorTypeID);
      const encryptedData = getEncrypted?.data?.data;

      const getEncypted = await getEncryptedData(vendorID, vendorTypeID);


      // Determine decrypted content based on the signature reference
      const decryptedContent =
        contract_orSignatureRef.current === 0
          ? encryptedData.contract_pdf_eData
          : encryptedData.contract_pdf_eData; // Assuming the alternative content is the same as contract_pdf_eData

      const content = htmlToPdfmake(Decrypted_Editor_content(getEncypted.data.data.contract_pdf_eData));
   

      // Define document styles and structure
      const documentDefinition = {
        content: [...content],
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            margin: [40, 10, 40, 10],
          },
          footer: {
            fontSize: 10,
            margin: [40, 0, 40, 0],
          },
          main: {
            margin: [40, 20, 40, 20],
          },
        },
        header: function (currentPage) {
          if (currentPage !== 1) {
            const modified_on = encryptedData?.modified_on
              ? new Date(encryptedData.modified_on)
              : new Date();
            return {
              text: `Published Date: ${formatDate(modified_on)}`,
              alignment: "left",
              style: "header",
              color: "#510F30",
              margin: [30, 40, 0, 0],
            };
          }
          return {
            text: '',
            margin: [30, 10, 0, 0],
          };
        },
        footer: function (currentPage, pageCount) {
          const imageBlob = encryptedData?.profile_imageBlob;
          const signatureImage = encryptedData?.convertblob;
          const vendorName = Var_ProfileName;
          const modified_on = encryptedData?.modified_on
            ? new Date(encryptedData.modified_on)
            : new Date();

          if (  accept_buttonClickRef.current == true) {
        
            return {
              margin: [40, 40, 40, 40],
              columns: [
                {
                  stack: [
                    {
                      text: `Service Provider Signature`,
                      alignment: "left",
                      style: "footer",
                      color: "#510F30",
                    },
                    {
                      table: {
                        body: [
                          [
                            {
                              image: imageBlob,
                              width: 50,
                              height: 50,
                              margin: [0, 10, 10, 0],
                            },
                            {
                              text:  vendorName,
                              alignment: 'left',
                              color: '#510F30',
                        
                              margin: [0, 20, 10, 0],
                            },
                            [
                              {
                                image: Var_SignatureImage,
                                width: 60,
                                height: 60,
                                alignment: 'left',
                          
                              },
                              {
                                text: formatDate(modified_on),
                                alignment: 'left',
                                color: '#510F30',
                                                       }
                            ]
  
                          ]
                        ]
                      },
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function (i, node) {
                          return 4;
                        },
                        paddingRight: function (i, node) {
                          return 4;
                        },
                        paddingTop: function (i, node) {
                          return 4;
                        },
                        paddingBottom: function (i, node) {
                          return 4;
                        },
        
                      }
                    },
                    {
                      text: `Page ${currentPage}/${pageCount}`,
                      alignment: "right",
                      color: "#510F30",
                      style: "footerText",
                    },
                  ],
                },
              ],
            };



          } else {
            return {
              margin: [40, 0, 40, 40],
              columns: [
                {
                  stack: [
                    {
                      text: `Page ${currentPage}/${pageCount}`,
                      alignment: "right",
                      color: "#510F30",
                      margin: [0, 80, 10, 0],
                    },
                  ],
                },
              ],
            };
          }
        },
        styles: {
          footerText: {
            fontSize: 10,
          },
          footer: {
            fontSize: 10,
            bold: true,
          },
        },
        pageMargins: [40, 70, 40, 180],
      };

      // Create and upload PDF
      if (accept_buttonClickRef.current == false) {
        const pdfDoc = pdfMake.createPdf(documentDefinition).open();
        setaccept_buttonClick(false);
      } else {
        const pdfDoc = pdfMake.createPdf(documentDefinition);

        pdfDoc.getBlob(async (blob) => {

          const formData = new FormData();
          formData.append('file', blob ,'document.pdf');
          formData.append('module_id', "1");
          formData.append('pathLocation', "profile/")

          try {
            const uploadResponse = await axios.post('admin/awsS3FileUpload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            console.log('File successfully uploaded', uploadResponse.data);

            axios.post('admin/decryptedContent_data_details', {
              "filepath": uploadResponse.data.filepath.Location,
              "email": [Var_Email,"thanesh@avelator.com"],
              "name": Var_ProfileName,
              "vendorContranct": "vendorContranct"
            });
          } catch (uploadError) {
            console.error('Error uploading file', uploadError);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>

      {/* {loading &&
        <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999' }} />
      } */}

      {/* ================= Login page start ================= */}

      <div className="login_BG">
        <div className="login_input_card">
          <div>
            <p className="welcome_name">Welcome Back</p>
            <p className="doctor_name">Mr. Doctor</p>
            <p className="login_name">Login To See Your Schedules</p>
            <div>
              <div className="email_container">
                <label className="email_input_label">E-Mail ID</label><br />
                <input className="email_input" type="email"
                  placeholder="Enter your email address"
                  name="email" autoComplete="true" value={Var_Email}
                  onChange={(e) => emailValidation(e.target.value)}></input>
                <img className="email_box_icon" alt="EMail_Box" src={EMail_Box} />
                <div className="error">
                  {Var_EmailError && "Email is Required"}
                </div>
                <div className="error">
                  {Var_InvalidEmail && "Invalid Email"}
                </div>
              </div>
              <div className="password_container">
                <label className="password_input_label">Password</label><br />
                <input className="password_input" type={Var_Hidden ? "password" : "text"}
                  placeholder="Enter your password"
                  name="password" autoComplete="true" value={Var_Password}
                  onChange={(e) => getPasswordData(e.target.value)}></input>
                {Var_Hidden ? <VisibilityOff onClick={toggleshow} style={iconStyle} /> : <Visibility onClick={toggleshow} style={iconStyle} />}
                <div className="error">
                  {Var_PasswordError && "Password is Required"}
                </div>
              </div>
              <div className="error">
                {Var_LoginError && "Email Or Password wrong"}
              </div>
              <div className="login_forgot">
                {/* <div className="remainder_button">
                  <input type="radio" className="remainder" id="remainder" value={Var_Remainder} onClick={(e) => getRemainderData(e.target.checked)}></input>
                  <p className="login_remainder_button" for="remainder">Remainder me</p>
                </div> */}
                <p className="login_forget_button" onClick={() => handleOnClick("forgot")}>
                  Forgot Password?
                </p>
              </div>
              <div className="login_button_container">
                <button className="login_button" onClick={() => { postLogin(); }}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================= Login page end ================= */}

      {RegisterationPushNotificationRef.current &&
          <div className="pushNotification_body">
            <div className="notification_img_container">
              <img src={Logo} className='notification_imgs' />
            </div>
            <div className="pushNotification_content">
              <p className="notification_text_head"><b>Registration Notification - Doctor</b></p>
              <div className="notification_text_body">
              Congratulations, {vendor_name}! You're officially registered with TOM.
              </div>
            </div>
          </div>       
}

    </div >
  );
};

export default Login;
