
import useStateRef from "react-usestateref";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { QRCode } from "antd";
import moment from "moment";
import { notification, Spin } from "antd";
import axios from "axios";
import Chip from "@mui/material/Chip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCalendar } from "react-icons/ai";
// Mui meterial component import
import {
  InputLabel,
  FormControlLabel,
  MenuItem,
  Container,
  Card,
  Grid,
  TextField,
  Button,
  DialogContent,
  Dialog,
  Radio,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import LinearProgress from "@mui/material/LinearProgress";

// Internal Components import
import "./AppointmentsMaster.css";
import HeaderMasterComponent from "../Header/HeaderMaster";

import profile from "../../assets/Images/TOMLogo.jpg";
import checkin from "../../assets/Web_Doctor_Icon_new_theme/checkin_inactive.svg";
import checkinok from "../../assets/Web_Doctor_Icon_new_theme/checkinokk.svg";
import Referral from "../../assets/icon/referral-1.svg";
import Report from "../../assets/Web_Doctor_Icon_new_theme/prescription_active.svg";
import Paid from "../../assets/Web_Doctor_Icon_new_theme/pay.svg";
import NotPaid from "../../assets/Web_Doctor_Icon_new_theme/not_pay.svg";
import Reportupload from "../../assets/Web_Doctor_Icon_new_theme/upload1.svg";
import Uploading from "../../assets/Web_Doctor_Icon_new_theme/upload1.svg";
import Checkout from "../../assets/Web_Doctor_Icon_new_theme/check_out_inactive.svg";
import Checkoutok from "../../assets/Web_Doctor_Icon_new_theme/checkoutok.svg";
import Logo from "../../assets/Images/one logo new-01.svg";
import Member from "../../assets/Web_Doctor_Icon_new_theme/Member.svg";
import Fasttrack from "../../assets/Web_Doctor_Icon_new_theme/fast_track.svg";
import Online from "../../assets/Web_Doctor_Icon_new_theme/Online.svg";
import Walkin from "../../assets/Web_Doctor_Icon_new_theme/walkin.svg";
import Prescription from "../../assets/Doctor_web_Icon/check-up.svg";
import closeicon from "../../assets/Web_Doctor_Icon_new_theme/close.svg";
import successModelTickImg from "../../assets/Web_Doctor_Icon_new_theme/Success.svg";
import Reportimage from "../../assets/Doctor_web_Icon/IMG_20200803_125505 (1).png";
import Service from "../../helpers/Service";
import alertimg from "../../assets/Web_Doctor_Icon_new_theme/alert.svg";
import vector from "../../assets/Doctor_web_Icon/Vector.png";
import Plus from "../../assets/Doctor_web_Icon/Path 38629.svg";
import SuccessReportupload from "../../assets/Doctor_web_Icon/uploading-archive.svg";
import SuccessPrescription from "../../assets/Doctor_web_Icon/Success_prescription.svg";
import SuccessReferral from "../../assets/Doctor_web_Icon/referral.svg";
import PrescriptionReferal from "../../assets/Doctor_web_Icon/check-up-walkin.svg";
import ReportWalkin from "../../assets/Doctor_web_Icon/Group 20852-walkin.svg";
import UploadReportReferal from "../../assets/Web_Doctor_Icon_new_theme/walkinupload.svg";
import NotArrived from "../../assets/Web_Doctor_Icon_new_theme/Arrived inactive.svg";
import Arrived from "../../assets/Web_Doctor_Icon_new_theme/Group 20899.svg";
import User from "../../assets/Web_Doctor_Icon_new_theme/Group 23213.svg";
import Viewmore from "../../assets/Web_Doctor_Icon_new_theme/Eye.svg";
// import Modalpage from "../Revenue_settle/Revenue_Models/RevenueModal.jsx";
import Plus_icon from "../../assets/Images/Plus_icon.svg"
// import Modalpage from "../Revenue_Models/RevenueModal.jsx";

// ======================================================== new Icon ======================
import Arrivednewicon from "../../assets/Doctor_Images/Group 17361.svg";
import HealthMatrics from "../../assets/clinicIcon/healthmatrics.svg";
import checkinnewicon from "../../assets/Doctor_Images/Group 17362.svg";
import reportnewicon from "../../assets/Doctor_Images/Group 17363.svg";
import prescriptionnewicon from "../../assets/Doctor_Images/Group 17364.svg";
import uploadnewreport from "../../assets/Doctor_Images/Group 17365.svg";
import checkoutnewicon from "../../assets/Doctor_Images/Group 17366.svg";
import paymentnewicon from "../../assets/Doctor_Images/Group 17367.svg";
import BackIcon from "../../assets/Doctor_Images/Group 31.svg";
import DashboardAddAppointmentImage from "../../assets/Doctor_Images/add_plus.png";
import healthmatricsdull from "../../assets/clinicIcon/healthmatricsdull.svg";
import checkindull from "../../assets/Doctor_Images/checkin.svg";
import checkoutdull from "../../assets/Doctor_Images/checkout.svg";
import paymentdull from "../../assets/Doctor_Images/payment.svg";
import Arriveddull from "../../assets/Doctor_Images/Arraived dull.svg";
import VectorIcon from "../../assets/Doctor_Images/user.png";
import MaleIcon from "../../assets/Doctor_Images/male_user.png";
import FemaleIcon from "../../assets/Doctor_Images/female_user.png";
import AddIcon from "../../assets/Doctor_Images/Plus.png";
import closeIcon from "../../assets/Doctor_Images/closeIcon.png";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AppointmentComponent() {
  let Var_History = useHistory();
  let interval;
  // var [Var_Value, setVar_value] = useState("doctor");
  const [startDate, setStartDate,startDate_Ref] = useStateRef(new Date());
  var [Var_Doctor, setVar_Doctor] = useState("none");
  var [Var_Lab, setVar_Lab] = useState("block");
  var [Var_DoctorColor, setVar_DoctorColor] = useState("var(--app-theme)");
  var [Var_DoctorBackColor, setVar_DoctorBackColor] = useState("#D4C3CC");
  var [Var_LabColor, setLabColor] = useState("#ffffff");
  var [Var_LabBackColor, setLabBackColor] = useState("var(--app-theme)");
  var [Var_Document, setVar_Document] = useState("");
  const [Var_UploadImageCss, SetVar_UploadImageCss] = useState("block");
  const [Var_ShowImageCss, SetVar_ShowImageCss] = useState("none");
  const [Var_GetLabList, setVar_GetLabList] = useState([]);
  const [Var_GetLabTestList, setVar_GetLabTestList] = useState([]);
  const [Var_DoctorSpeciality, setVar_doctor_speciality] = useState([]);
  const [additionalcostvalue, setAdditionalCostValue] = useState([]);
  const [additionalservicevalue, setAdditionalServiceValue] = useState([]);
  const [totaladditionalcost, settotaladditionalcost, totaladditionalcostRef] = useStateRef(0);
  const [doctoradditionalvalue, setDoctorAdditionalValue, doctoradditionalvalueRef] = useStateRef([]);
  const [DoctorAdditionalcost, setDoctorAdditionalcost] = useState([]);
  const [Var_DoctorBySpeciality, setVar_doctor_Byspeciality] = useState([]);
  const [modalT, setModal] = useState(false);
  const [modeldata, setmodeldata] = useState({});
  const [modalType, setModalType] = useState(0);
  const [errors, setErrors] = useState({});
  var [Var_GetLabListSelect, setVar_GetLabListSelect, Var_GetLabListSelect_Ref,] = useStateRef("");
  var [Var_GetLabTestSelect, setVar_GetLabTestSelect, Var_GetLabTestSelect_Ref,] = useStateRef();
  var [Var_DoctorSpecialitySelect, setVar_DoctorSpecialitySelect, Var_DoctorSpecialitySelect_Ref,] = useStateRef("Select Specialty");
  var [Var_DoctorBySpecialitySelect, setVar_DoctorBySpecialitySelect, Var_DoctorBySpecialitySelect_Ref,] = useStateRef("");
  var [Var_AppointmentDetail, setVar_AppointmentDetail] = useState([]);
  var [Var_SuccessText, setVar_SuccessText] = useState([]);
  var [Var_TotalCuont, setVar_TotalCuont] = useState("");
  var [Var_CheckedIn, setVar_CheckedIn] = useState([]);
  var [Var_Date, setVar_Date, Var_DateRef] = useStateRef("");
  var [Var_DateApi, setVar_DateApi, dateRefapi] = useStateRef("");
  const [fullWidth, setFullWidth] = React.useState(true);
  const [Var_WalkInGetSlots, setVar_WalkInGetSlots, Var_WalkInGetSlots_Ref] = useStateRef([]);
  const [Var_WalingNoSlots, setVar_WalingNoSlots, Var_WalingNoSlots_Ref] = useStateRef("0");
  const [showSelect, setShowSelect] = React.useState(false);
  const [showSelectLab, setShowSelectLab] = React.useState(false);
  const [showSelectDoctor, setShowSelectDoctor] = React.useState(false);
  const [bookingBtnState, setBookingBtnState, bookingBtnStateRef] = useStateRef(true);
  const [walBookingBtnState, setWalBookingBtnState, walBookingBtnStateRef] = useStateRef(true);
  const [Var_SuccessModelText, setVar_SuccessModelText] = useState("");
  const [Var_patientid, setVar_patientid, Var_patientid_Ref] = useStateRef();
  const [Var_doctorid, setVar_doctorid, Var_doctorid_Ref] = useStateRef(null);
  const [Var_bookingid, setVar_bookingid, Var_bookingid_Ref] = useStateRef(null);
  const [Var_walkin, setVar_walkin, Var_walkin_Ref] = useStateRef(0);
  const [Var_LabRemark, setVar_LabRemark, Var_LabRemark_Ref] = useStateRef("");
  const [Var_DoctorRemark, setVar_DoctorRemark, Var_DoctorRemark_Ref] = useStateRef("");
  const [Var_FileName, setVar_FileName, Var_FileName_Ref] = useStateRef("");
  const [Var_File, setVar_File, Var_File_Ref] = useStateRef("");
  const [Var_DoctorDetail, setVar_DoctorDetail, Var_DoctorDetail_Ref] = useStateRef([]);
  const [Var_DoctorDetailShow, setVar_DoctorDetailShow, Var_DoctorDetailShow_Ref,] = useStateRef(null);
  const [Var_specialityid, setVar_specialityid, Var_specialityid_Ref] = useStateRef(null);
  const [Var_ProfileName, setVar_ProfileName, Var_ProfileName_Ref] = useStateRef("");
  const [Var_ProfilePhone, setVar_ProfilePhone, Var_ProfilePhone_Ref] = useStateRef("");
  const [Var_SelectTimeSlots, setVar_SelectTimeSlots, Var_SelectTimeSlots_Ref] = useStateRef("");
  const [Var_SlotsLength, setVar_SlotsLength, Var_SlotsLength_Ref] = useStateRef("");
  const [Var_SelectToTimeSlots, setVar_SelectToTimeSlots, Var_SelectToTimeSlots_Ref,] = useStateRef("");
  const [Var_AppointmentId, setVar_AppointmentId, Var_AppointmentId_Ref] = useStateRef("");
  const [Var_PatientIdId, setVar_PatientId, Var_PatientId_Ref] = useStateRef("");
  const [Var_GetServiceList, setVar_GetServiceList, Var_GetServiceList_Ref] = useStateRef([]);
  const [Var_GetServiceListSelect, setVar_GetServiceListSelect, Var_GetServiceListSelect_Ref,] = useStateRef("");
  const [Var_CheckinStatus, setVar_CheckinStatus, Var_CheckinStatus_Ref] = useStateRef("0");
  const [Var_CheckOutStatus, setVar_CheckOutStatus, Var_CheckOutStatus_Ref] = useStateRef("0");
  const [Var_Profiledata, setVar_Profiledata] = useState([]);
  const [Var_ClinicRefferalCode, setVar_ClinicRefferalCode] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Var_remark, setVar_remark, Var_remark_Ref] = useStateRef("Remark");
  const [Var_PayMentMethodName, setVar_PayMentMethodName, Var_PayMentMethodName_Ref,] = useStateRef("");
  const [Var_PayatClinicCost, setVar_PayatClinicCost, Var_PayatClinicCost_Ref] = useStateRef("");
  const [Var_FullAmount, setVar_FullAmount, Var_FullAmount_Ref] = useStateRef(null);
  const [Var_InsuranceCost, SetVar_InsuranceCost, Var_InsuranceCost_Ref] = useStateRef("");
  const [Var_InsuranceCompanyList, setVar_InsuranceCompanyList, Var_InsuranceCompanyList_Ref,] = useStateRef([]);
  const [Var_getInsuranceCompanyName, setVar_getInsuranceCompanyName, Var_getInsuranceCompanyName_Ref,] = useStateRef("");
  const [Var_valueselect, setVar_valueselect, Var_valueselect_Ref] = useStateRef("");
  const [Var_valueshow, setVar_valueshow, Var_valueshow_Ref] = useStateRef([]);
  const [Var_DoctorClinicSelect, setVar_DoctorClinicSelect, Var_DoctorClinicSelect_Ref,] = useStateRef(null);
  const [Var_doctorClinic, setVar_doctorClinic, Var_doctorClinic_Ref] = useStateRef(null);
  const [Var_Height, setVar_Height, Var_Height_Ref] =  useStateRef("");
  const [Var_Weight, setVar_Weight, Var_Weight_Ref] =  useStateRef("");
  const [Var_BloodPressure, setVar_BloodPressure, Var_BloodPressure_Ref] =  useStateRef("");
  const [Var_HeartRate, setVar_HeartRate, Var_HeartRate_Ref] =  useStateRef("");
  const [Var_BodyTemperature, setVar_BodyTemperature, Var_BodyTemperature_Ref] =  useStateRef("");
  const [walheight, setwalheight] = useState("");
  const [walweight, setwalweight] = useState("");
  const [walbloodpressure, setwalbloodpressure] = useState("");
  const [walheartrate, setwalheartrate] = useState("");
  const [walbodytemperature, setwalbodytemperature] = useState("");
  const [Var_HealthMatricsState, setVar_HealthMatricsState, Var_HealthMatricsState_Ref] = useStateRef(true);
  const [Var_HealthMatricsBookingId, setVar_HealthMatricsBookingId, Var_HealthMatricsBookingId_Ref] = useStateRef(false);
  const [Var_HealthMatricsPatientId, setVar_HealthMatricsPatientId, Var_HealthMatricsPatientId_Ref] = useStateRef("");
  const [Var_HealthMatricsButton, setVar_HealthMatricsButton, Var_HealthMatricsButton_Ref] = useStateRef('Add Health Matrics');
  const [isOpenWalkInModelHealthMatrics, setisOpenWalkInModelHealthMatrics] = useState(false);
  const [Loadingprogress, setLoadingprogress] = useState(true);
  const [Var_fileprocess, setVar_fileprocess, Var_fileprocess_Ref] = React.useState(false);
  const [Var_progress, setVar_Progress] = useState(0);
  const [Var_gendervalue, setVar_gendervalue, Var_gendervalue_Ref] = useStateRef("Gender");
  const [Var_gender, setVar_gender, Var_gender_Ref] = useStateRef("");

  const [ShowSelectTestMul, setShowSelectTestMul, ShowSelectTestMul_Ref] = useStateRef(false);
  const initialList = [{ id: 1 }]
  const [list, setList] = useState(initialList);
  const [checkboxvariable, setcheckboxvariable] = useState(false);
  const [isOpenReferralModel, setisOpenReferralModel] = useState(false);
  const [isOpenPayModel, setisOpenPayModel] = useState(false);
  const [isOpenWalkInModel, setisOpenWalkInModel] = useState(false);
  const [isOpenWalkInQrModel, setisOpenWalkInQrModel] = useState(false);
  var [isOpenSuccessModel, setIsOpenSuccessModel] = useState(false);
  var [isOpenQrCodeModel, setisOpenQrCodeModel] = useState(false);
  var [Var_isOpenPaymentTypeModal, SetVar_isOpenPaymentTypeModal] = useState(false);
  const [Var_isOpenPaymentInsuranceModal, SetVar_isOpenPaymentInsuranceModal] = useState(false);
  const [Var_isOpenPaymentCombaineModal, SetVar_isOpenPaymentCombaineModal] = useState(false);
  var [QRCodeData, setQRCodeData] = useState("");

  const [personName, setPersonName] = React.useState(["Select Test"]);
  const [Var_viewdata, SetVar_viewdata, Var_viewdata_Ref] = useStateRef([]);
  const [Var_SpecialityDoctorList, setVar_SpecialityDoctorList, Var_SpecialityDoctorList_Ref] = useStateRef([]);
  const [Var_GetSpecilaitySelect, setVar_GetSpecilaitySelect, Var_GetSpecilaitySelect_Ref] = useStateRef("");
  const [isinsurance, setisinsurance, Isinsurance_Ref] = useStateRef("");
  const [isDisable, setisisDisable] = useState(false);
  // field required state 
  const [labselect, setlabselect] = useState("");
  const [labTest, setlabTest] = useState("");
  const [labrmark, setlabrmark] = useState("");
  const [labrmarkclassName, setlabrmarkclassName] = useState("files_head");
  const [docselect, setdocselect] = useState("");
  const [docTest, setdocTest] = useState("");
  const [docrmark, setdocrmark] = useState("");
  const [docrmarkclassName, setdocrmarkclassName] = useState("files_head");
  const fileInputRef = useRef(null);
  const fileInputDoctorRef = useRef(null);
  const [walname, setwalname] = useState("");
  const [walgender, setwalgender] = useState("");
  const [walmobile, setwalmobile] = useState("");
  const [walspecial, setwalspecial] = useState("");
  const [walservice, setwalservice] = useState("");

  // ===================================
  const [walage, setwalage] = useState("");
  const [var_current_date, setvar_current_date] = useState(new Date());
  const [Var_IsShowSuccess, setVar_IsShowSuccess] = React.useState(false);
  const [walsymptoms, setwalsymptoms] = useState("");
  const [Var_ProfileAge, setVar_ProfileAge, Var_ProfileAge_Ref] =useStateRef("");
  const [WalkInAppointmentModel, setWalkInAppointmentModel] = useState(false);
  const [Var_GetSymptoms, setVar_GetSymptoms, Var_GetSymptoms_Ref] = useStateRef("");
  const [Var_symptomsValue, setVar_symptomsValue, Var_symptomsValue_Ref] = useStateRef([]);
  const [Var_preexistingdisease, serVar_preexistingdisease, Var_preexistingdisease_Ref] = useStateRef("");
  const [Var_preexistingdisease_Value, setVar_preexistingdisease_Value, Var_preexistingdisease_Value_Ref] = useStateRef([]);

  const steps = [
    { prescriptDate: "Arrived", icon: Arrivednewicon },
    { prescriptDate: "Health-matrics", icon: HealthMatrics },
    { prescriptDate: "Check-in", icon: checkinnewicon },
    { prescriptDate: "Report", icon: reportnewicon },
    { prescriptDate: "Prescription", icon: prescriptionnewicon },
    { prescriptDate: "Upload Report", icon: uploadnewreport },
    { prescriptDate: "Check-out", icon: checkoutnewicon },
    { prescriptDate: "Payment", icon: paymentnewicon },
  ];
  const totalSteps = 8;


  const handleChange = (event) => {
    // let index = personName.indexOf("Select Test");
    // var check = personName;
    // if (index > -1) {
    //     setPersonName(personName.splice(index, 1));
    // }
    //setPersonName(check)
    let _value = []
    const {
      target: { value },
    } = event;

    if (typeof value === 'string') {
      _value = value.split(',');
    }
    else {
      _value = value;
    }
    if (_value.indexOf("Select Test") != -1) {
      value.splice(_value.indexOf("Select Test"), 1);
    }


    setPersonName(_value)
    var arrayList = [];
    event.target.value.forEach((element) => {
      //let selectedData = Var_GetLabTestList.filter((data) => data.testName ==element);
      Var_GetLabTestList.forEach((data) => {
        if (data.testName == element) {
          arrayList.push(data.testId);
        }
      });
    });
    console.log("arrayddddddd==>", arrayList);
    setVar_valueselect(arrayList);
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: undefined }));
  };

  const validateForm = () => {
    debugger
    const newErrors = {};
    if ((Var_PayMentMethodName_Ref.current == "payAtClinic" || Var_PayMentMethodName_Ref.current === '') && Var_PayatClinicCost_Ref.current?.toString()?.trim().length === 0) {
      newErrors.Var_PayatClinicCost = '*Please enter a valid amount';
    }
    if ((Var_PayMentMethodName_Ref.current == "payAtInsurance" || Var_PayMentMethodName_Ref.current === '') && Var_InsuranceCost_Ref.current?.toString()?.trim().length === 0) {
      newErrors.Var_InsuranceCost = '*Please enter a valid amount';
    }
    if ((Var_PayMentMethodName_Ref.current == "payAtInsurance" || Var_PayMentMethodName_Ref.current === '') && Var_getInsuranceCompanyName_Ref.current.id === 0) {
      newErrors.Var_getInsuranceCompanyName = '*Please select Insurance company';
    }
    if (Var_PayMentMethodName_Ref.current === '' && Var_FullAmount_Ref.current?.toString()?.trim().length === 0) {
      newErrors.Var_FullAmount = '*Please enter a valid amount';
    }
    if (checkboxvariable && doctoradditionalvalue.length === 0) {
      newErrors.doctoradditionalvalue = '*Please add one additional service';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // initial function call start
  useEffect(() => {
    setisisDisable(false);
    getspeciality();
    // getClinicDoctor();
    // setVar_DoctorDetail(Service.doctorDetail)
    var contentTypeDataGet = localStorage.getItem('contentTypeData')
    if ((contentTypeDataGet == "Booking") || (contentTypeDataGet == "RescheduledSec")) {
      setVar_Date(localStorage.getItem('formatDateToDayMonthYear'));
      setVar_DateApi(localStorage.getItem('formatDateToISO'));
      localStorage.removeItem('contentTypeData');
    } else {
      setVar_Date(
        moment(new Date().toLocaleDateString("fr-CA")).format("DD-MMM-YY")
      );
      setVar_DateApi(new Date().toLocaleDateString("fr-CA"));
    }
    getLabList();
    getMasDoctorSpeciality();
    getAllAppointment();
    getDoctorBySpeciality();
    getTestsByLab();
    // getClinicWiseDoctor();
    // getservice();
    getDoctorProfile();
    setShowSelectTestMul(false);
    const totalCost = doctoradditionalvalue.reduce((total, item) => total + parseFloat(item.cost), 0);
    settotaladditionalcost(totalCost);
    return () => {
      clearInterval(interval); // Cleanup the interval on component unmount
    };
  }, [doctoradditionalvalue]);
  // initial function call End
// Custom input component with an icon
const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
    style={{
      display: "flex",
      alignItems: "center",
      border: "1px solid #2f887c ",
      borderRadius: "5px",
      padding: "6px 12px",
      cursor: "pointer",
      gap: "20px",
      background: "#fff",
      boxShadow: "0 4px 6px rgba(99, 99, 99, 0.2)",
      marginRight :"4rem"
    }}
      onClick={onClick}
      ref={ref}
    >
      <span>{value || "Select Date"}</span> {/* Display the selected date or placeholder */}
      <AiOutlineCalendar size={20} color="#555" /> {/* Calendar icon */}
    </div>
  ));

  const getAppointmentDate = (date) =>{
    setStartDate(date);
    getSlotsService();
  }

  // navigattion to back start
  const navigateTo = (path) => {
    Var_History.push("/dashboard" + path);
  };
  // navigattion to back end
  const isOpenPaymentTypeModal = (data) => {
    debugger
    setVar_PayMentMethodName(data);
    setVar_PayatClinicCost("");
    SetVar_InsuranceCost("");
    setVar_FullAmount("");
    setisOpenPayModel(!isOpenPayModel);
    if (Var_PayMentMethodName_Ref.current == "payAtClinic") {
      setcheckboxvariable(false)
      setDoctorAdditionalValue([]);
      var cost = Var_AppointmentDetail.filter(
        (x) => x.booking_id == Var_AppointmentId_Ref.current
      );
      setVar_PayatClinicCost(cost[0].cost);
      setVar_valueshow(cost[0].appointment_type_id);
      SetVar_isOpenPaymentTypeModal(!Var_isOpenPaymentTypeModal);
    } else if (Var_PayMentMethodName_Ref.current == "payAtInsurance") {
      setcheckboxvariable(false)
      setDoctorAdditionalValue([]);
      var cost = Var_AppointmentDetail.filter(
        (x) => x.booking_id == Var_AppointmentId_Ref.current
      );
      SetVar_InsuranceCost(cost[0].cost);
      setVar_valueshow(cost[0].appointment_type_id);
      setVar_getInsuranceCompanyName({
        id: 0,
        company_name: "Select Insurance Company",
        insurance_days: "",
        insurance_percentage: "",
        created_date: "",
        created_by: "",
      });
      SetVar_isOpenPaymentInsuranceModal(!Var_isOpenPaymentInsuranceModal);
      getInsuranceCompanytList();
    } else {
      setcheckboxvariable(false)
      setDoctorAdditionalValue([]);
      var cost = Var_AppointmentDetail.filter(
        (x) => x.booking_id == Var_AppointmentId_Ref.current
      );
      setVar_FullAmount(cost[0].cost);
      setVar_valueshow(cost[0].appointment_type_id);
      setVar_getInsuranceCompanyName({
        id: 0,
        company_name: "Select Insurance Company",
        insurance_days: "",
        insurance_percentage: "",
        created_date: "",
        created_by: "",
      });
      SetVar_isOpenPaymentCombaineModal(!Var_isOpenPaymentCombaineModal);
      getInsuranceCompanytList();
    }
  };
  // ===================== payment maodal close
  const isClosePatAtClinicModal = () => {
    SetVar_isOpenPaymentTypeModal(false);
    setVar_PayatClinicCost("");
    SetVar_InsuranceCost("");
    setVar_FullAmount("");
  };
  const isClosePatInsuranceModal = () => {
    SetVar_isOpenPaymentInsuranceModal(false);
    setVar_PayatClinicCost("");
    SetVar_InsuranceCost("");
    setVar_FullAmount("");
  };
  const isClosePatAtClinicinsuranceModal = () => {
    SetVar_isOpenPaymentCombaineModal(false);
    setVar_PayatClinicCost("");
    SetVar_InsuranceCost("");
    setVar_FullAmount("");
  };

  // =============== Get Next Date start ==========================================
  const nextdate = () => {
    let date = new Date(Var_DateRef.current);
    date.setDate(date.getDate() + 1);
    let options = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    };

    let formattedDate = date.toLocaleDateString("en-US", options);
    var monthsegment = moment(formattedDate).format("DD-MMM-YY");
    setVar_Date(monthsegment);
    setVar_DateApi(date.toLocaleDateString("fr-CA"));
    getAllAppointment();
    getSlotsService();
  };
  // =============== Get Next Date End ==========================================

  // =============== Get Pervious Date start ==========================================
  const perviousdate = () => {
    debugger
    let date = new Date(Var_DateRef.current);
    date.setDate(date.getDate() - 1);
    let options = {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    };

    let formattedDate = date.toLocaleDateString("en-US", options);
    var monthsegment = moment(formattedDate).format("DD-MMM-YY");
    setVar_Date(monthsegment);
    setVar_DateApi(date.toLocaleDateString("fr-CA"));
    getAllAppointment();
    getSlotsService();
  };
  // =============== Get Pervious Date End ==========================================

  // ======= API=======

  // =============== Get All Appointment Start ==========================================
  const getAllAppointment = () => {
    var date = dateRefapi.current;

    setLoading(true);
    var data = {
      // clinic_id: Var_doctorClinic_Ref.current,
      clinic_id: localStorage.getItem("Clinic_id"),
      doctor_id: localStorage.getItem("Doctor_id"),
      patient_type: "",
      period: "day",
      search_date: date.toString(), //"2023-08-14",
      search_date_to: date.toString(), //"2023-08-14",
    };
    axios
      .post("doctor/getAppointmentList", data)
      .then((response) => {
        console.log("Appoinment", response.data.data[0].result);

        // setVar_TotalCuont(response.data.data[0].result.length);
        var TotalAppointmentCount = response.data.data[0].result.filter(x => x.appointment_type_id != 2);
        setVar_TotalCuont(TotalAppointmentCount.length);
        setVar_AppointmentDetail(response.data.data[0].result);
        var checkInStatus = response.data.data[0].result.filter(
          (x) => x.check_in_status == 1
        );
        var checkOutStatus = response.data.data[0].result.filter(
          (x) => x.check_out_status == 1
        );
        setVar_CheckinStatus(checkInStatus.length);
        console.log("checkin", checkInStatus.length);
        setVar_CheckOutStatus(checkOutStatus.length);
        console.log("checkout", checkOutStatus.length);
        setLoading(false);
        // changeCheckinStatus(response.data.data[0].result)

        clearInterval(interval);
        interval = setInterval(() => {
          getAllAppointment();
        }, 120000); // 1000ms interval
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // const changeCheckinStatus = (data) =>{
  //     let is_refresh=false;
  //    data?.map((obj)=>{
  //        if(obj.appointment_type_id==2)
  //        {
  //             if (moment(obj.appointment_date).isSame(moment(), 'day'))
  //             {
  //                 let checkin_time_diff = moment().diff(moment(obj.appointment_date + " " + obj.appointment_time), 'milliseconds')/ 1000;
  //                 let checkout_time_diff = moment().diff(moment(obj.appointment_date + " " + obj.appointment_to_time), 'milliseconds')/ 1000;

  //                  if (obj.check_in_status==0 && checkin_time_diff>=0)
  //                  {
  //                     is_refresh=true
  //                    const currentDate = new Date();
  //                    const currentHour = String(currentDate.getHours()).padStart(2, '0');
  //                     const currentMinute = String(currentDate.getMinutes()).padStart(2, '0');
  //                     const currentSecond = String(currentDate.getSeconds()).padStart(2, '0');
  //                     const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
  //                     var data = {
  //                         book_id: obj.booking_id,
  //                         is_walkin: 0, // Need to change Dynamic binding
  //                         checked_in: formattedTime,
  //                         arrived_status: 1,
  //                         available_status: "arrived"
  //                     }
  //                     axios.put("clinic/updateCheckedIn", data).then((response) => {
  //                         var data = {
  //                             book_id: obj.booking_id,
  //                             is_walkin: 0, // Need to change Dynamic binding
  //                             checked_in: formattedTime,
  //                             arrived_status: 1,
  //                             available_status: "checkin"
  //                         }
  //                         axios.put("clinic/updateCheckedIn", data).then((response) => {

  //                         })
  //                         .catch((error) => {
  //                             setLoading(false);
  //                             console.log(error);
  //                         });
  //                     })
  //                     .catch((error) => {
  //                         setLoading(false);
  //                         console.log(error);
  //                     });

  //                  }
  //                  else if (obj.check_in_status==1 && obj.check_out_status==0 && checkout_time_diff>=0){
  //                     is_refresh=true
  //                     const currentDate = new Date();
  //                     const currentHour = String(currentDate.getHours()).padStart(2, '0');
  //                     const currentMinute = String(currentDate.getMinutes()).padStart(2, '0');
  //                     const currentSecond = String(currentDate.getSeconds()).padStart(2, '0');
  //                     const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
  //                     var data = {
  //                         book_id: obj.booking_id,
  //                         is_walkin: 0, // Need to change Dynamic binding
  //                         checked_in: formattedTime
  //                     }
  //                     axios
  //                         .put("clinic/updateCheckedOut", data)
  //                         .then((response) => {
  //                         })
  //                         .catch((error) => {
  //                             setLoading(false);
  //                             console.log(error);
  //                         });
  //                  }
  //             }

  //        }
  //    })

  //    if(is_refresh)
  //    {
  //     getAllAppointment()
  //    }
  // }
  // =============== Get All Appointment End ==========================================

  // =============== Get Lab List  ==========================================

  const openModel = (data, type) => {
    setModalType(type);
    setModal(true);

    setmodeldata(data);
  };

  const getLabList = () => {
    setLoading(true);
    axios
      .get("doctor/getlabList")
      .then((response) => {
        console.log("labList", response.data);
        setVar_GetLabList(response.data.data);
        setVar_GetLabListSelect(response.data.data[0].id);
        setVar_GetLabListSelect({
          labId: 0,
          Lab: "Select Lab",
          profilepath: "",
        });
        setLoading(false);
        console.log(Var_GetLabListSelect_Ref.current);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // =============== Get Test By Lab List  ==========================================

  const packagemeal = (field, value) => {
    if (field === 'service') {
      setAdditionalServiceValue(value);
    } else if (field === 'cost') {
      setAdditionalCostValue(value);
    }
  };

  const submitText = () => {
    if (additionalservicevalue?.trim() !== '' && additionalcostvalue?.trim() !== '') {
      const obj = {
        service: additionalservicevalue,
        cost: additionalcostvalue,
      };
      setDoctorAdditionalValue((prevValue) => [...prevValue, obj]);
      // setDoctorAdditionalcost((prevValue) => [...prevValue, obj]);
      setAdditionalServiceValue('');
      setAdditionalCostValue('');
      SetVar_InsuranceCost("");
      setVar_PayatClinicCost("");
      const totalCost = doctoradditionalvalue.reduce((total, item) => total + parseFloat(item.cost), 0);
      settotaladditionalcost(totalCost);
      clearError('doctoradditionalvalue')
      console.log('Data added:', doctoradditionalvalue);
    } else {
      // Handle the case where either service or cost input is empty
      console.log('Service or cost is empty');
    }
  };

  const deletetag = (index) => {
    const updatedArray = [...doctoradditionalvalue.slice(0, index), ...doctoradditionalvalue.slice(index + 1)];

    setDoctorAdditionalValue(updatedArray);
  };

  const getTestsByLab = () => {
    setLoading(true);
    var data = {
      labId: Var_GetLabListSelect_Ref.current.labId,
    };
    axios
      .post("doctor/getlabTestList", data)
      .then((response) => {
        console.log("labTestList", response.data.data);
        setVar_GetLabTestList(response.data.data);
        setPersonName(["Select Test"]);
        // setVar_getlabListSelect(response.data.data[0].id)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // =============== Get Doctor Speciality ==========================================

  const getMasDoctorSpeciality = () => {
    setLoading(true);
    axios
      .get("doctor/get_mas_doctor_speciality_by_doctor")
      .then((response) => {
        console.log("Doctor speciality", response.data.data);
        setVar_doctor_speciality(response.data.data);
        setVar_DoctorSpecialitySelect({
          id: 0,
          speciality: "Select Specialty",
          speciality_filename: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // =============== Get Doctor By Speciality ==========================================

  const getDoctorBySpeciality = () => {
    setLoading(true);
    var data = {
      specialityId: Var_DoctorSpecialitySelect_Ref.current.id, // Need to change Dynamic binding
    };
    axios
      .post("doctor/getdoctorspecialitywise", data)
      .then((response) => {
        console.log("Doctor By speciality", response.data.data);
        setVar_doctor_Byspeciality(response.data.data);
        setVar_DoctorBySpecialitySelect({
          doctorId: 0,
          doctorName: "Select Doctor",
          profileImage: "",
          location: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  //get service list API start
  const getservice = (spldid) => {
    setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("Doctor_id"),
      speciality_id: spldid,
    };
    axios
      .post("doctor/getDoctorServiceBySpecilaity", data)
      .then((response) => {
        {
          console.log("service", response.data.data);
          setVar_GetServiceList(response.data.data);
          setVar_GetServiceListSelect({
            cost: "",
            description: "",
            doctor_id: "",
            file_name: "",
            file_path: "",
            id: 0,
            is_active: "",
            service: "Select Service",
            slot_duration: "",
            spec_id: "",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  //get service list API end
  const getspeciality = () => {
    setLoading(true);
    var data = {
      doctor_id: localStorage.getItem("Doctor_id")
    };
    axios

      ({
        method: "POST",
        url: "doctor/getSpecialitybyDoctor",
        data: data,
      })

      .then((response) => {
        setVar_SpecialityDoctorList(response.data.data);
        setVar_GetSpecilaitySelect({
          id: "",
          speciality: "Select Speciality"
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  // =============== Get All SlotsService Start ==========================================
  const getSlotsService = () => {
    if (Var_GetServiceListSelect_Ref.current.id != "" || Var_GetServiceListSelect_Ref.current.id != 0) {
      setwalservice(false);
    }
    setLoading(true);
    var date = dateRefapi.current;
    var data = {
      search_date: startDate_Ref.current.toLocaleDateString("fr-CA").toString(),
      clinic_id: localStorage.getItem("Clinic_id"),
      doctor_id: localStorage.getItem("Doctor_id"),
      service_id: Var_GetServiceListSelect_Ref.current.id,
      type: "",
      nxtdate: "true",
    };
    axios
      .post("doctor/getslotsbyservice", data)
      .then((response) => {
        var check = new Date().toLocaleTimeString("en-US", {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
      });
        {
          console.log("Sloats", response.data.data[0].date_slots);
          if(Var_DateRef.current === moment(new Date()).format("DD-MMM-YY")){
            var filteredData = response.data.data[0].date_slots.filter(
              (x) => x.appointtype_id == "1"  && check < x.from_time
            );
            setVar_WalkInGetSlots(filteredData);
          }else{
          var filteredData = response.data.data[0].date_slots.filter(
            (x) => x.appointtype_id == "1"
          );
          setVar_WalkInGetSlots(filteredData);
        }
          // setVar_WalkInGetSlots(response.data.data[0].date_slots);
          setVar_SlotsLength(response.data.data[0].date_slots.length);
          setVar_SlotsLength(response.data.data[0].date_slots.length);
          setLoading(false);
          if (response.data.data[0].date_slots.length == 0) {
            setVar_WalingNoSlots("1");
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  // =============== Get All Appointment End ==========================================

  // walk in booking API start

  // validation for not booked before enter all inputs start
  // const validation = () => {
  //   if (Var_SelectTimeSlots_Ref.current == "") {
  //     return false;
  //   } else if (Var_ProfileName_Ref.current == "") {
  //     return false;
  //   } else if (Var_ProfilePhone_Ref.current == "") {
  //     return false;
  //   } else if (
  //     Var_gender_Ref.current == "" &&
  //     Var_gender_Ref.current == "Gender"
  //   ) {
  //     return false;
  //   } else if (Var_GetServiceListSelect_Ref.current.id == "") {
  //     return false;
  //   } else if (Var_SlotsLength_Ref.current == "") {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  const validation = () => {
    debugger
    setwalname("")
    setwalmobile("")
    setwalage("");
    setwalgender("")
    setwalservice("");
    setwalspecial("")
    setwalsymptoms("");
    if (Var_SelectTimeSlots_Ref.current == '' 
      && Var_ProfileName_Ref.current == '' &&
       Var_ProfilePhone_Ref.current == '' && 
       Var_ProfileAge_Ref.current == "" &&
       Var_symptomsValue_Ref.current.length == 0 &&
       (Var_gender_Ref.current == '' || Var_gender_Ref.current == 'Gender') 
       && (Var_GetServiceListSelect_Ref.current.id == 0 || Var_GetServiceListSelect_Ref.current.id == "")
        && Var_SlotsLength_Ref.current == '') {
      setwalname(true)
      setwalmobile(true)
      setwalgender(true)
      setwalservice(true);
      setwalspecial(true)
      setwalage(true);
      setwalsymptoms(true);
      return false;
    }
    if (Var_ProfileName_Ref.current == '') {
      setwalname(true);
      return false;
    }
    if (Var_ProfileAge_Ref.current == "") {
      setwalage(true);
      return false;
    }
    if (Var_gender_Ref.current == '' || Var_gender_Ref.current == 'Gender') {
      setwalgender(true);
      return false;
    }
    if (Var_ProfilePhone_Ref.current == '') {
      setwalmobile(true);
      return false;
    }
    if (Var_symptomsValue_Ref.current.length == 0) {
      setwalsymptoms(true);
      return false;
    }
    if (Var_GetSpecilaitySelect_Ref.current.id == '' || Var_GetSpecilaitySelect_Ref.current.id == 0) {
      setwalspecial(true)
    }
    if (Var_GetServiceListSelect_Ref.current.id == '' || Var_GetServiceListSelect_Ref.current.id == 0) {
      setwalservice(true);
      return false;
    }
    if (Var_SlotsLength_Ref.current == '') {
      return false;
    }
    if (Var_SelectTimeSlots_Ref.current == '') {
      return false;
    }
    if (walspecial || walservice || walmobile || walgender || walname) {
      return false;
    } else {
      return true;
    }

  }
  //validation for not booked before enter all inputs end

  const clickhandler = ({ target }) => {
    debugger
    setcheckboxvariable(target.checked);
    setAdditionalServiceValue('');
    setAdditionalCostValue('');
    settotaladditionalcost(0)
    setDoctorAdditionalValue([])
    clearError('doctoradditionalvalue')
    if (Var_PayMentMethodName_Ref.current === "payAtClinic") {
      SetVar_InsuranceCost('')
      setVar_FullAmount('')
    }
    else if (Var_PayMentMethodName_Ref.current == "payAtInsurance") {
      setVar_FullAmount('')
      setVar_PayatClinicCost('');
    }
    else if (Var_PayMentMethodName_Ref.current == "") {
      SetVar_InsuranceCost('');
      setVar_PayatClinicCost('');
    }
  }


  const bookedSuccessfully = () => {
    let Var_Validate = validation();
    if (Var_Validate) {
      setWalBookingBtnState(false)
      var date = dateRefapi.current;
      setLoading(true);
      var data = {
        mobile_no: Var_ProfilePhone_Ref.current,
        name: Var_ProfileName_Ref.current,
        age: Var_ProfileAge_Ref.current,
        gender: Var_gendervalue_Ref.current,
        book_date: startDate_Ref.current.toLocaleDateString("fr-CA").toString(),
        from_time: Var_SelectTimeSlots_Ref.current,
        to_time: Var_SelectToTimeSlots_Ref.current,
        service_type_id: Var_GetServiceListSelect_Ref.current.id,
        book_amount: Var_GetServiceListSelect.cost,
        total_slots: Var_SlotsLength_Ref.current,
        payment_status: 0,
        clinic_id: localStorage.getItem("Clinic_id"),
        doctor_id: localStorage.getItem("Doctor_id"),
        created_by: localStorage.getItem("Doctor_id"),
        arrived_status:
          moment(startDate).format("DD-MMM-YY") == moment(new Date()).format("DD-MMM-YY")
            ? "1"
            : "0",
        arrived_order_list:
          moment(startDate).format("DD-MMM-YY") == moment(new Date()).format("DD-MMM-YY")
            ? new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds()
            : "NULL",
        speciality_id: localStorage.getItem("speciality_Id"),
        symptoms: JSON.stringify(Var_symptomsValue_Ref.current),
      };
      axios
        .post("clinic/insert_patient_walkin", data)
        .then((response) => {
          {
            if (response.data.status == 1) {
              setVar_SuccessModelText("Congratulations! Appointment Added Successfully.");
              setVar_IsShowSuccess(true);
              setTimeout(() => {
                setVar_IsShowSuccess(false);
              }, 1500);
              setWalkInAppointmentModel(!WalkInAppointmentModel);
              getAllAppointment();
              setWalBookingBtnState(true)
              setLoading(false);
              setVar_ProfileName("");
              setVar_gender("Gender");
              setVar_ProfileAge("");
              setVar_ProfilePhone("");
              setVar_GetSymptoms("");
              setVar_GetServiceList([])
              setVar_SelectTimeSlots("");
              setVar_SlotsLength("");
              setVar_GetServiceListSelect({
                cost: "",
                description: "",
                doctor_id: "",
                file_name: "",
                file_path: "",
                id: 0,
                is_active: "",
                service: "Select Service",
                slot_duration: "",
                spec_id: "",
              });
              setVar_GetSpecilaitySelect({
                id: "",
                speciality: "Select Speciality"
              });
            }
            getAllAppointment();
          }
        })
        .catch((error) => {
          setWalBookingBtnState(true)
          setLoading(false);
          console.log(error);
        });
    }
  };
  // walk in booking API end
  // =============== Check In Status Start ==========================================

  const check = (bookid, walkin, name, patient_id, doctor_id, clinic_id, date) => {
    debugger
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const currentHour = String(currentDate.getHours()).padStart(2, "0");
    const currentMinute = String(currentDate.getMinutes()).padStart(2, "0");
    const currentSecond = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
    if (moment(formattedDate).format("DD-MMM-YY") == Var_DateRef.current) {
      setLoading(true);
      var data = {
        book_id: bookid,
        is_walkin: walkin, // Need to change Dynamic binding
        checked_in: formattedTime,
        arrived_status: 1,
        available_status: "arrived",
      };
      axios
        .put("clinic/updateCheckedIn", data)
        .then((response) => {
          console.log("Checkin data", response.data);
          setVar_CheckedIn(response.data.msg);
          if (response.data.status == 1) {
            setVar_SuccessModelText(
              "Member " + name + " arrived successfully!"
            );
            setVar_IsShowSuccess(true);
            setTimeout(() => {
              setVar_IsShowSuccess(false);
            }, 1500)
            setLoading(false);
            // ========================================================Queue===============
            // let requestdata = {
            //   booking_id: bookid,
            //   clinic_id: clinic_id,
            //   patient_id: patient_id,
            //   doctor_id: doctor_id,
            //   date: date,
            //   type: "Arrived"
            // };
            // axios
            //   .post("clinic/getQueueList", requestdata)
            //   .then((resp) => {
            //     console.log(resp);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
          } else if (response.data.status == 0) {
            notification.error({ message: response.data.msg });
            setLoading(false);
          }
          getAllAppointment();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      notification.error({ message: "Today is not your appointment" });
    }
  };

  const postCheckedIn = (bookid, walkin, name, patient_id, doctor_id, clinic_id, date) => {
    // if () {

    // } else {
    //     notification.error({ message: "Check in not allowed" })
    // }
    // if (Var_CheckinStatus_Ref.current == Var_CheckOutStatus_Ref.current) {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const currentHour = String(currentDate.getHours()).padStart(2, "0");
    const currentMinute = String(currentDate.getMinutes()).padStart(2, "0");
    const currentSecond = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
    if (moment(formattedDate).format("DD-MMM-YY") >= Var_DateRef.current) {
      setLoading(true);
      var data = {
        book_id: bookid,
        is_walkin: walkin, // Need to change Dynamic binding
        checked_in: formattedTime,
        arrived_status: 1,
        available_status: "checkin",
      };
      axios
        .put("clinic/updateCheckedIn", data)
        .then((response) => {
          console.log("Checkin data", response.data);
          setVar_CheckedIn(response.data.msg);
          if (response.data.status == 1) {
            // ==========================Queue API=====================
            // let requestdata = {
            //   booking_id: bookid,
            //   clinic_id: clinic_id,
            //   patient_id: patient_id,
            //   doctor_id: doctor_id,
            //   date: date,
            //   type: "CheckIn"
            // };
            // axios
            //   .post("clinic/getQueueList", requestdata)
            //   .then((resp) => {
            //     console.log(resp);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
            setVar_SuccessModelText(
              "Member " + name + " checked-in successfully!"
            );
            setVar_IsShowSuccess(true);
            setTimeout(() => {
              setVar_IsShowSuccess(false);
            }, 1500);
            setLoading(false);
          } else if (response.data.status == 0) {
            notification.error({ message: response.data.msg });
            setLoading(false);
          }
          getAllAppointment();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      notification.error({ message: "Today is not your appointment" });
    }
    // } else {
    //     notification.error({ message: "Please check out patient already checked in" });

    // }
  };

  // =============== Check In Status End ==========================================

  // =============== Check Out Status Start ==========================================
  // const postCheckedOut = (bookid, walkin, name) => {
  //     if (Var_CheckinStatus_Ref.current > Var_CheckOutStatus_Ref.current) {
  //         const currentDate = new Date();

  //         const year = currentDate.getFullYear();
  //         const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  //         const day = String(currentDate.getDate()).padStart(2, '0');
  //         const currentHour = String(currentDate.getHours()).padStart(2, '0');
  //         const currentMinute = String(currentDate.getMinutes()).padStart(2, '0');
  //         const currentSecond = String(currentDate.getSeconds()).padStart(2, '0');
  //         const formattedDate = `${year}-${month}-${day}`;
  //         const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
  //
  //         if (moment(formattedDate).format("DD-MMM-YY") >= Var_DateRef.current) {
  //
  //             setLoading(true);
  //             var data = {
  //                 book_id: bookid,
  //                 is_walkin: walkin, // Need to change Dynamic binding
  //                 checked_in: formattedTime
  //             }
  //             axios
  //                 .put("clinic/updateCheckedOut", data)
  //                 .then((response) => {
  //                     console.log("Checkout data", response.data)
  //                     if (response.data.status == 1) {

  //                         setVar_SuccessModelText("Member " + name + " checked out successfully!")
  //                         setIsOpenSuccessModel(!isOpenSuccessModel)
  //                         setLoading(false);
  //                     } else if (response.data.status == 0) {
  //                         notification.error({ message: response.data.msg });
  //                         setLoading(false);

  //                     }
  //                     getAllAppointment()
  //                     setLoading(false);
  //                 })
  //                 .catch((error) => {
  //                     setLoading(false);
  //                     console.log(error);
  //                 });
  //         }
  //     } else {
  //         notification.error({ message: "Please check in first" });
  //     }

  // }
  const postCheckedOut = (bookid, walkin, name) => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const currentHour = String(currentDate.getHours()).padStart(2, "0");
    const currentMinute = String(currentDate.getMinutes()).padStart(2, "0");
    const currentSecond = String(currentDate.getSeconds()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
    if (moment(formattedDate).format("DD-MMM-YY") >= Var_DateRef.current) {
      setLoading(true);
      var data = {
        book_id: bookid,
        is_walkin: walkin, // Need to change Dynamic binding
        checked_in: formattedTime,
      };
      axios
        .put("clinic/updateCheckedOut", data)
        .then((response) => {
          console.log("Checkout data", response.data);
          if (response.data.status == 1) {
            setVar_SuccessModelText(
              "Member " + name + " checked-out successfully!"
            );
            setVar_IsShowSuccess(true);
            setTimeout(() => {
              setVar_IsShowSuccess(false);
            }, 1500);
            setLoading(false);
          }
          getAllAppointment();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  // =============== Check Out Status End ==========================================

  // =============== PayMent Status start ==========================================
  const PayMethod = (bookid, patientid, is_insurance) => {
    debugger
    setisinsurance(is_insurance);
    // console.log(Isinsurance_Ref.current,"bala");
    setErrors({})
    setisOpenPayModel(!isOpenPayModel);
    setVar_AppointmentId(bookid);
    setVar_PatientId(patientid);
    var data = Var_AppointmentDetail.filter(
      (x) => x.booking_id == bookid
    );
    SetVar_viewdata(data)
    setAdditionalServiceValue('');
    setAdditionalCostValue('');
  };

  const paymentInsert = (bookid, patientid, totalfee) => {

    try {
      // debugger
      // const isFormValid = validateForm();

      // if (isFormValid) {
        setBookingBtnState(false)
        // setLoading(true);
        var data = {
          doctorId: localStorage.getItem("Doctor_id"),
          patientId: patientid,
          paymentInHand: Var_PayatClinicCost_Ref.current,
          // paymentInHand: Var_PayatClinicCost_Ref.current,
          insuranceAmount: "",
          // paymentType: Var_PayMentMethodName_Ref.current == "payAtClinic" ? 2 : 0
          //   || Var_PayMentMethodName_Ref.current == "payAtInsurance" ? 4 : 0
          //     || Var_PayMentMethodName_Ref.current == "" ? 3 : 0,
          // created_by: localStorage.getItem("Clinic_id"),
          paymentType: 2,
        created_by: localStorage.getItem("Doctor_id"),
          appointmentId:bookid,
          // insuranceId: Var_getInsuranceCompanyName_Ref.current.id == null ? "" : Var_getInsuranceCompanyName_Ref.current.id,
          insuranceId: "",
          totalAmount: "",
          totaladitionalamount: "",
          additionalservicelist: "",
        };
        axios
          .post("patient/clinicPaymentInsert", data)
          .then((response) => {
            if (response.data.status == 1) {
              setisOpenPayModel(false);
              SetVar_isOpenPaymentTypeModal(false);
              SetVar_isOpenPaymentInsuranceModal(false);
              SetVar_isOpenPaymentCombaineModal(false);
              setVar_SuccessModelText("Payment updated  successfully!");
              setVar_IsShowSuccess(true);
              setTimeout(() => {
                setVar_IsShowSuccess(false);
              }, 1500);
              setBookingBtnState(true);
              getAllAppointment();
              setLoading(false);
              setVar_PayatClinicCost("");
            }
          })
          .catch((error) => {
            setBookingBtnState(true)
            setLoading(false);
            console.log(error);
          });
    } catch (error) {
      setBookingBtnState(true)
      console.error(error)
    }
  };

  const noClose = () => {
    setisOpenPayModel(!isOpenPayModel);
  };
  // =============== PayMent Status End ==========================================

  // File Upload Referral Lab start
  const uploadDocumentLab = async (e) => {
    debugger
    setisisDisable(true);
    // setLoading(true);
    setVar_fileprocess(true);
    setVar_Progress(0);
    setVar_FileName(e.target.files[0].name);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('module_id', "1");
    formData.append('pathLocation', "REFERRAL/");

    axios({
      method: "POST",
      url: "admin/awsS3FileUpload",
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setVar_Progress(percentage);
      },
    })
      .then((response) => {
        console.log("File", response.data);
        setVar_File(response.data.filepath.Location);
        setisisDisable(false);
        SetVar_ShowImageCss("block");
        SetVar_UploadImageCss("none");
        setLoading(false);
        // setVar_fileprocess(false)
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      })
      .catch((error) => {
        setLoading(false);
        setVar_fileprocess(false);
        setisisDisable(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      });
  };

  // File Upload Referral Lab End

  // File Upload Referral Doctor Start

  const uploadDocumentDoctor = async (e) => {
    // setLoading(true);
    setisisDisable(true);
    setVar_fileprocess(true);
    setVar_Progress(0);
    setVar_FileName(e.target.files[0].name);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('module_id', "1");
    formData.append('pathLocation', "REFERRAL/");
    axios({
      method: "POST",
      url: "admin/awsS3FileUpload",
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setVar_Progress(percentage);
      },
    })
      // axios.post("admin/awsS3FileUpload", formData)
      .then((response) => {
        setVar_File(response.data.filepath.Location);
        setisisDisable(false);
        SetVar_ShowImageCss("block");
        SetVar_UploadImageCss("none");
        setLoading(false);
        if (fileInputDoctorRef.current) {
          fileInputDoctorRef.current.value = null;
        }
        // setVar_fileprocess(false)
      })
      .catch((error) => {
        if (fileInputDoctorRef.current) {
          fileInputDoctorRef.current.value = null;
        }
        setLoading(false);
        setVar_fileprocess(false);
        setisisDisable(false);
      });
  };
  // File Upload Referral Doctor End

  const dataclear = () => {
    setVar_GetLabListSelect({
      labId: 0,
      Lab: "Select Lab",
      profilepath: "",
    });
    setVar_DoctorBySpecialitySelect({
      "doctorId": 0,
      "doctorName": "Select Doctor",
      "profileImage": "",
      "location": ""

    })
    setVar_doctor_Byspeciality([]);
    setPersonName(["Select Test"]);
    setVar_GetLabTestList([]);
    setVar_FileName("");
    setVar_File("");
    setVar_remark("");
    setVar_LabRemark("");
    fileUploadCalcel();
    setShowSelectTestMul(false);
  };

  const dataclear1 = () => {
    setVar_DoctorSpecialitySelect({
      id: 0,
      speciality: "Select Specialty",
      speciality_filename: "",
    });
    setVar_DoctorBySpecialitySelect({
      doctorId: 0,
      doctorName: "Select Doctor",
      profileImage: "",
      location: "",
    });
    setVar_FileName("");
    setVar_File("");
    setVar_DoctorRemark("");
    fileUploadCalcel();
  };

  // =============== Referral St  atus start ==========================================
  const onReferral = (type) => {
    setisisDisable(true);
    if (type == "labreferral") {
      if (Var_GetLabListSelect_Ref.current.labId != "") {
        setlabselect(false);
      } else {
        setlabselect(true);
      }
      if (Var_valueselect_Ref.current != "") {
        setlabTest(false);
      } else {
        setlabTest(true);
      }
      if (Var_LabRemark_Ref.current != "") {
        setlabrmark(false);
        setlabrmarkclassName("files_head");
      } else {
        setlabrmark(true);
        setlabrmarkclassName("");
      }
      setLoading(true);
      var data = {
        patient_id: Var_patientid_Ref.current,
        lab_id: Var_GetLabListSelect_Ref.current.labId,
        test_id: Var_valueselect_Ref.current,
        remarks: Var_LabRemark_Ref.current,
        file_name: Var_FileName_Ref.current,
        file_path: Var_File_Ref.current.split('?')[0],
        created_by: Var_doctorid_Ref.current,
        doctor_id: Var_doctorid_Ref.current,
        booking_id: Var_bookingid_Ref.current,
        is_walkin: Var_walkin_Ref.current,
      };
      if (
        Var_GetLabListSelect_Ref.current.labId != "" &&
        Var_valueselect_Ref.current != "" &&
        Var_LabRemark_Ref.current != ""
      ) {
        axios
          .post("doctor/addLabReferral", data)
          .then((response) => {
            console.log("addLabReferral", response.data);
            console.log("addLabReferral", response.data.msg);
            setisisDisable(false);
            if (response.data.status == 1) {
              setVar_SuccessModelText("Lab referred successfully!");
              setisOpenReferralModel(!isOpenReferralModel);
              setIsOpenSuccessModel(!isOpenSuccessModel);
              setLoading(false);
              dataclear();
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            setisisDisable(false);
          });
      } else {
        // notification.error({ message: "Please fill all the data" });
        setLoading(false);
        setisisDisable(false);
      }
    } else if (type == "doctorreferral") {
      if (Var_DoctorSpecialitySelect_Ref.current.id != "") {
        setdocselect(false);
      } else {
        setdocselect(true);
      }
      if (Var_DoctorBySpecialitySelect_Ref.current.doctorId != "") {
        setdocTest(false);
      } else {
        setdocTest(true);
      }
      if (Var_DoctorRemark_Ref.current != "") {
        setdocrmark(false);
        setdocrmarkclassName("files_head");
      } else {
        setdocrmark(true);
        setdocrmarkclassName("");
      }
      setLoading(true);
      var data = {
        patient_id: Var_patientid_Ref.current,
        speciality_id: Var_DoctorSpecialitySelect_Ref.current.id,
        ref_doctor_id: Var_DoctorBySpecialitySelect_Ref.current.doctorId,
        remarks: Var_DoctorRemark_Ref.current,
        created_by: Var_doctorid_Ref.current,
        doctor_id: Var_doctorid_Ref.current,
        file_name: Var_FileName_Ref.current,
        file_path: Var_File_Ref.current.split('?')[0],
        booking_id: Var_bookingid_Ref.current,
        is_walkin: Var_walkin_Ref.current,
        from_speciality_id: Var_specialityid_Ref.current,
      };
      if (
        Var_DoctorSpecialitySelect_Ref.current.id != "" &&
        Var_DoctorBySpecialitySelect_Ref.current.doctorId != "" &&
        Var_DoctorRemark_Ref.current != ""
      ) {
        axios
          .post("doctor/addDoctorReferral", data)
          .then((response) => {
            setisisDisable(false);
            console.log("doctorreferral", response.data);
            console.log("doctorreferral", response.data.msg);
            if (response.data.status == 1) {
              setisOpenReferralModel(!isOpenReferralModel);
              setVar_SuccessModelText("Doctor referred successfully!");
              setIsOpenSuccessModel(!isOpenSuccessModel);
              setLoading(false);
              dataclear1();
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            setisisDisable(false);
          });
      } else {
        // notification.error({ message: "Please fill all the data" });
        setLoading(false);
        console.log("Please fill all the data");
        setisisDisable(false);
      }
    }
  };
  // =============== PayMent Status End ==========================================

  // model controls start

  const okClose = () => {
    setIsOpenSuccessModel(!isOpenSuccessModel);
    getAllAppointment();
  };

  const isCloseModel = (modelname) => {
    if (modelname == "ReferralModel") {
      setShowSelectTestMul(false);
      dataclear();
      dataclear1();
      setVar_FileName("");
      setVar_File("");
      setisOpenReferralModel(false);
    } else if (modelname == "PayModel") {
      setisOpenPayModel(false);
    } else if (modelname == "WalkInModel") {
      setisOpenWalkInModel(false);
      setVar_ProfilePhone("");
      setVar_ProfileName("");
      setVar_WalingNoSlots("0");
      setVar_WalkInGetSlots([]);
      setVar_GetServiceListSelect({
        cost: "",
        description: "",
        doctor_id: "",
        file_name: "",
        file_path: "",
        id: 0,
        is_active: "",
        service: "Select Service",
        slot_duration: "",
        spec_id: "",
      });
      setVar_GetServiceList([]);
      setVar_GetSpecilaitySelect({
        id: "",
        speciality: "Select Speciality"
      });
      setStartDate(new Date());
      setVar_symptomsValue([]);
    } else if (modelname == "WalkInModelHealthMatrics") {
      setVar_Height("");
      setVar_Weight("");
      setVar_BloodPressure("");
      setVar_HeartRate("");
      setVar_BodyTemperature("");
      setVar_HealthMatricsBookingId("");
      setVar_HealthMatricsPatientId("");
      setVar_preexistingdisease_Value([]);
    } else if (modelname == "WalkInQrModel") {
      setisOpenWalkInQrModel(false);
      setVar_SuccessModelText("Walk-in booking added successfully!");
      setIsOpenSuccessModel(!isOpenSuccessModel);
    } else if (modelname == "QrCodeModel") {
      setisOpenQrCodeModel(false);
    }
  };

  const onReferrals = (patientid, doctorid, bookingid, walkin, doctorSpecIdByAppmnt) => {
    setVar_patientid(patientid);
    setVar_doctorid(doctorid);
    setVar_bookingid(bookingid);
    setVar_walkin(walkin);
    setVar_specialityid(doctorSpecIdByAppmnt);
    setVar_FileName("");
    setVar_File("");
    setisOpenReferralModel(!isOpenReferralModel);
    setPersonName(["Select Test"]);
    setlabselect("");
    setlabTest("");
    setlabrmark("");
    setdocselect("");
    setdocTest("");
    setdocrmark("");
  };

  const walkingBookingReferral = () => {
    setVar_WalingNoSlots("0");
    setVar_WalkInGetSlots([]);
    setVar_GetServiceListSelect({
      cost: "",
      description: "",
      doctor_id: "",
      file_name: "",
      file_path: "",
      id: 0,
      is_active: "",
      service: "Select Service",
      slot_duration: "",
      spec_id: "",
    });

    setVar_GetSpecilaitySelect({
      id: "",
      speciality: "Select Speciality"
    });
    setwalname("");
    setwalgender("");
    setwalage("");
    setwalmobile("");
    setwalsymptoms("");
    setwalspecial("");
    setwalservice("");
    setVar_ProfileName("");
    setVar_gender("Gender");
    setVar_ProfileAge("");
    setVar_ProfilePhone("");
    setVar_GetSymptoms("");
    setVar_GetServiceList([])
    setVar_SelectTimeSlots("");
    setVar_SlotsLength("");
    setWalkInAppointmentModel(!WalkInAppointmentModel);
    setStartDate(new Date());
    setVar_symptomsValue([]);
  };
  // model controls end
  const isAppointmentCloseModel = () => {
    setWalkInAppointmentModel(false);
  };
  // Referral Model functions start

  const isHealthMatricsCloseModel = () => {
    setisOpenWalkInModelHealthMatrics(false);
  };


   // health matrics model controls start
   const walkingBookingHealthMatrics = (patientid,bookid,height,weight,blood_pressure,heart_rate,body_temperature,health_matrics_status,pre_exist_disease) => {
    debugger
    if(health_matrics_status == 1){
      setVar_Height(height);
      setVar_Weight(weight);
      setVar_BloodPressure(blood_pressure);
      setVar_HeartRate(heart_rate);
      setVar_BodyTemperature(body_temperature);
      setVar_HealthMatricsBookingId(bookid);
      setVar_HealthMatricsPatientId(patientid);
      setVar_HealthMatricsButton('Update Health Matrics');
      setVar_preexistingdisease_Value(JSON.parse(pre_exist_disease));
    }else{
      setwalheight("");
      setwalweight("");
      setwalbloodpressure("");
      setwalheartrate("");
      setwalbodytemperature("");
      setVar_Height("");
      setVar_Weight("");
      setVar_BloodPressure("");
      setVar_HeartRate("");
      setVar_BodyTemperature("");
      setVar_HealthMatricsBookingId(bookid);
      setVar_HealthMatricsPatientId(patientid);
      setVar_HealthMatricsButton('Add Health Matrics');
      setVar_preexistingdisease_Value(JSON.parse(pre_exist_disease));
    }
    setisOpenWalkInModelHealthMatrics(!isOpenWalkInModelHealthMatrics);
  };
  // health matrics model controls end

  const referralTab = (value) => {
    setShowSelectTestMul(false);
    if ("doctor" == value) {
      // setVar_value('doctor')
      setVar_Doctor("block");
      setVar_Lab("none");
      SetVar_ShowImageCss("none");
      SetVar_UploadImageCss("block");
      setVar_DoctorColor("#ffffff");
      setVar_DoctorBackColor("var(--app-theme)");
      setLabColor("var(--app-theme)");
      setLabBackColor("#D4C3CC");
      setVar_SuccessText("Doctor referred successfully!");
      fileUploadCalcel();
      setVar_FileName("");
      setVar_File("");
      dataclear();
      setdocselect(false);
      setdocTest(false);
      setdocrmark(false)
    } else if (value == "lab") {
      // setVar_value('lab')
      setVar_Doctor("none");
      setVar_Lab("block");
      SetVar_ShowImageCss("block");
      SetVar_UploadImageCss("none");
      setVar_DoctorColor("var(--app-theme)");
      setVar_DoctorBackColor("#D4C3CC");
      setLabColor("#ffffff");
      setLabBackColor("var(--app-theme)");
      setVar_SuccessText("Lab referred successfully!");
      fileUploadCalcel();
      dataclear1();
      setVar_FileName("");
      setVar_File("");
      setlabselect(false);
      setlabTest(false);
      setlabrmark(false);
    }
  };
  // Referral Model functions End

  // Time conversion start

  const convertTo12HrFormat = (time24) => {
    var timeArr = time24.split(":");
    var hours = parseInt(timeArr[0]);
    var minutes = parseInt(timeArr[1]);
    var suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    var time12 =
      hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + suffix;

    return time12;
  };
  const timeFormat = (time) => {
    let datetime = new Date("1970-01-01T" + time + "Z");
    return datetime.toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
  };

  // Time conversion End

  const Demo = () => {
    setisOpenPayModel(!isOpenPayModel);
  };

  // File Upload start

  // File Upload End

  const getClinicWiseDoctor = () => {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("Clinic_id"),
    };
    axios
      .post("clinic/getClinicWiseDoctor", data)
      .then((response) => {
        var filteredData = response.data.data.filter(
          (x) =>
            x.specialityid == localStorage.getItem("speciality_Id") &&
            x.doctor_id == localStorage.getItem("Doctor_id")
        );
        setVar_DoctorDetailShow(
          filteredData[0].doctorName + " " + filteredData[0].specialityname
        );
        // setVar_specialityid(filteredData[0].specialityid);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // select slots from time function start
  const selectSlots = (value) => {
    Var_WalkInGetSlots_Ref.current.forEach((element) => {
      element.is_clicked = 0;
    });
    setVar_SelectTimeSlots(value.from_time);
    value.is_clicked = 1;
    setVar_SelectToTimeSlots(value.to_time);
  };
  // select slots from time function end

  // select slots from time function end
  const fileUploadCalcel = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setVar_fileprocess(false);
    SetVar_ShowImageCss("none");
    SetVar_UploadImageCss("block");
    setVar_FileName("");
    setVar_File("");
  };
  // select slots from time function end

  const test = () => { };
  // ====================

  // ============ Page Navigate data pass start
  const navigateToReportsPage = (data) => {
    Service.check = "checking navigate data";

    navigateTo(
      "/appointments/report/" +
      data.patient_id +
      "/" +
      data.booking_id +
      "/" +
      data.appointment_date
    );
  };

  const navigateToPrescriptionPage = (data) => {
    Service.check = "checking navigate data";

    navigateTo(
      "/appointments/prescription/" +
      data.patient_id +
      "/" +
      data.booking_id +
      "/" +
      data.appointment_date +
      "/" +
      "1" +
      "/" +
      "1"
    );
  };

  const navigateToUploadReportPage = (data) => {
    Service.check = "checking navigate data";

    navigateTo(
      "/appointments/uploadreport/" +
      data.patient_id +
      "/" +
      data.booking_id +
      "/" +
      data.appointment_date +
      "/" +
      "1"
    );
  };

  // ============ Page Navigate data pass End

  const getDoctorProfile = () => {
    setLoading(true);
    var data = {
      clinic_id: localStorage.getItem("Clinic_id"),
    };
    axios
      .post("clinic/getClinicProfile", data)
      .then((response) => {
        setVar_Profiledata(response.data.data[0]);
        setVar_ClinicRefferalCode(response.data.data[0].clinic_refferal_code);
        console.log("appointment", response.data.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const handleLabClick = (item) => {
    // Define what should happen when a MenuItem is clicked
    setVar_GetLabListSelect(item);
    getTestsByLab();
    setShowSelectLab(false);
    setlabselect(false);
  };
  const handleDoctorClick = (item) => {
    // Define what should happen when a MenuItem is clicked
    setVar_DoctorBySpecialitySelect(item)
    setShowSelectDoctor(false);
    setdocTest(false);
  };

  const testing = () => {
    setisOpenWalkInQrModel(true);
  };

  const openQRCodePopup = (data) => {
    if (data != "" && data != null) {
      setQRCodeData(data);
      setisOpenQrCodeModel(true);
    }
  };

  const walkinMobileNumbetValidation = (event) => {
    debugger
    const inputText = event.target.value;
    const numericOnly = inputText.replace(/\D/g, "");
    if (/^\d{0,10}$/.test(event.target.value)) {
      setVar_ProfilePhone(numericOnly);
    }
    if (numericOnly.toString().length > 0) {
      setwalmobile("");
    } else {
      setwalmobile(true);
    }
  };

  const getGender = (e) => {
    setVar_gendervalue(e.target.value);
    setwalgender("");
    if (e.target.value == "M") {
      setVar_gender("Male");
    } else {
      setVar_gender("Female");
    }
  };

  const getInsuranceCompanytList = () => {
    setLoading(true);
    var reqData = {
      doctorId: localStorage.getItem("Clinic_id"),
    };
    axios
      .post("patient/insuranceCompanyList", reqData)
      .then((response) => {
        setVar_InsuranceCompanyList(response.data.data);
        setVar_getInsuranceCompanyName({
          id: 0,
          company_name: "Select Insurance Company",
          insurance_days: "",
          insurance_percentage: "",
          created_date: "",
          created_by: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  // get clinic wise doctor api call start
  // const getClinicDoctor = () => {
  //   setLoading(true);

  //   var data = {
  //     doctorId: localStorage.getItem("Doctor_id"),
  //   };
  //   axios
  //     .post("doctor/getDoctorClinics", data)
  //     .then((response) => {
  //       localStorage.setItem("Clinic_id", response.data.data[0].clinicId);
  //       // setVar_getClinicWiseDoctor(response.data.data);
  //       setVar_doctorClinic(response.data.data[0].clinicId);
  //       localStorage.setItem("Clinic_id", response.data.data[0].clinicId);

  //       console.log(
  //         "response.data.data[0].clinicId",
  //         response.data.data[0].clinicId
  //       );
  //       console.log("setVar_DoctorClinicSelect", response.data.data);
  //       setVar_DoctorClinicSelect(response.data.data);
  //       // localStorage.setItem("speciality_Id", response.data.data[0].specialityid);
  //       // clinicTabPermissionById()
  //       // checkThreeStepVerification(response.data.data[0].doctor_id)
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.log(error);
  //     });
  // };
  const doctorClinic = (e) => {
    // alert(e.target.value);
    setVar_doctorClinic(e.target.value);
    localStorage.setItem("Clinic_id", e.target.value);
    getAllAppointment();
  };
  const getSymptoms = () =>{
    let symptoms = [];
    symptoms = [...Var_symptomsValue_Ref.current];
    symptoms.push(Var_GetSymptoms_Ref.current)
    setVar_symptomsValue(symptoms);
    setVar_GetSymptoms("");
}
const removeSymptoms = (index) =>{
let symptoms = [];
symptoms = [...Var_symptomsValue_Ref.current];
symptoms.splice(index,1);
setVar_symptomsValue(symptoms);
}
const getpreExistingdis = () =>{
  if(Var_preexistingdisease_Ref.current !==""){
    let diseces = [];
    diseces = [...(Var_preexistingdisease_Value_Ref.current || [])];
    diseces.push(Var_preexistingdisease_Ref.current)
    setVar_preexistingdisease_Value(diseces);
    serVar_preexistingdisease("");
  }
}
const removepreExistingdis = (index) =>{
let diseces = [];
diseces = [...Var_preexistingdisease_Value_Ref.current];
diseces.splice(index,1);
setVar_preexistingdisease_Value(diseces);
}
  // validation for health matrics start
  const validationHealthMatrics = () => {
    setwalheight("");
    setwalweight("");
    setwalbloodpressure("");
    setwalheartrate("");
    setwalbodytemperature("");
    if (Var_Height_Ref.current == "" && Var_Weight_Ref.current == "" && Var_BloodPressure_Ref.current == "" && 
      Var_HeartRate_Ref.current == "" && Var_BodyTemperature_Ref.current == "") {
      setwalheight(true);
      setwalweight(true);
      setwalbloodpressure(true);
      setwalheartrate(true);
      setwalbodytemperature(true);
      return false;
    }
    if (Var_Height_Ref.current == "") {
      setwalheight(true);
      return false;
    }
    if (Var_Weight_Ref.current == "") {
      setwalweight(true);
      return false;
    }
    if (Var_BloodPressure_Ref.current == "") {
      setwalbloodpressure(true);
      return false;
    }
    if (Var_HeartRate_Ref.current == "") {
      setwalheartrate(true);
      return false;
    }
    if (Var_BodyTemperature_Ref.current == "") {
      setwalbodytemperature(true);
      return false;
    }
    if (walheight || walweight || walbloodpressure || walheartrate || walbodytemperature) {
      return false;
    } else {
      return true;
    }
  };
  //validation for health matrics end

  // walk in booking health matrics API start
  const healthMatricsBooked = () => {
    let Var_Validate_HealthMatrics = validationHealthMatrics();
    if (Var_Validate_HealthMatrics) {
      setVar_HealthMatricsState(false);
      setLoading(false);

      var healthMatricsData = {
        patientid:Var_HealthMatricsPatientId_Ref.current,
        booking_id: Var_HealthMatricsBookingId_Ref.current,
        height: Var_Height_Ref.current,
        weight: Var_Weight_Ref.current,
        blood_pressure: Var_BloodPressure_Ref.current,
        heartrate: Var_HeartRate_Ref.current,
        body_temperature: Var_BodyTemperature_Ref.current,
        pre_exist_disease: JSON.stringify(Var_preexistingdisease_Value_Ref.current),
      };
      axios
        .post("clinic/insert_patient_walkin_health_matrics", healthMatricsData)
        .then((response) => {
            if (response.data.status == 1) {
              if(Var_HealthMatricsButton_Ref.current == 'Update Health Matrics'){
                setVar_SuccessModelText("Health Matrics Updated Successfully.");
              }else{
                setVar_SuccessModelText("Health Matrics Added Successfully.");
              }
              setVar_IsShowSuccess(true);
              setTimeout(() => {
                setVar_IsShowSuccess(false);
              }, 1500);
              setisOpenWalkInModelHealthMatrics(!isOpenWalkInModelHealthMatrics);
              setVar_HealthMatricsState(true);
              setLoading(false);
              setVar_Height("");
              setVar_Weight("");
              setVar_BloodPressure("");
              setVar_HeartRate("");
              setVar_BodyTemperature("");
              setVar_HealthMatricsBookingId("");
              setVar_HealthMatricsPatientId("");
            }
            getAllAppointment();
        })
        .catch((error) => {
          setVar_HealthMatricsState(true);
          setLoading(false);
          console.log(error);
        });
    }
  };
  // walk in booking health matrics API end



  return (
    <div>
      {/* {loading &&
                <div className="loader_body">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                        
                    </Box>
                </div>
            } */}

      {loading && (
        <LinearProgress
          color="secondary"
          className="progressBar"
          sx={{ zIndex: "9999" }}
        />
      )}

      <div>
        <HeaderMasterComponent />
      </div>

      <div>
        {/* =================== Header Starts ============================== */}
        <div className="Navication-header appointment_page_image_icon_details">
          <div className="d-flex">
            <img src={BackIcon} onClick={() => navigateTo("")} />
            <label onClick={() => navigateTo("")}>Appointments</label> 
          </div>
          {moment(var_current_date).format("DD-MMM-YY") <= Var_DateRef.current &&
          <div className="dashboard_content_button_container appointment_page_header">
            <button
              className="dashboard_content_button"
              onClick={() => {
                walkingBookingReferral();
                debugger
              }}
            >
              <img
                className="Dashboard_Add_Appointment_Image appointment_page_add_icon"
                src={DashboardAddAppointmentImage}
                alt="Dashboard_Add_Appointment_Image"
              />
              <span className="dashboard_content_button_text">
                Add Appointment
              </span>
            </button>
          </div>}
        </div>
        {/* =================== Header End ============================== */}

        {/* =================== Content Starts ============================== */}
        <div className="workinghours_content">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="appointment_details_list">
              <Grid container className="appointment_2list">
                <Grid item xs={6} sm={6} md={6} lg={6} align="center">
                  <p className="today_count">
                    Total Appointments : {Var_TotalCuont}
                  </p>
                </Grid>
                <Grid item xs={6} align="center">
                  <p className="today_count">
                    {" "}
                    <span> Checked</span>-In / Started Session :{" "}
                    {Var_CheckinStatus_Ref.current}
                  </p>
                </Grid>
              </Grid>
            </div>

            <div className="page_head_date_container">
              <div className="page_head_date">
                <Button
                  className="appointment_date_left_button"
                  onClick={() => {
                    perviousdate();
                  }}
                >
                  <i
                    className="fa fa-chevron-left date_left_button_icon"
                    aria-hidden="true"
                  />
                </Button>
                <Button className="date_button">{Var_DateRef.current}</Button>
                <Button
                  className="appt_date_right_button"
                  onClick={() => {
                    nextdate();
                  }}
                >
                  <i
                    className="fa fa-chevron-right date_right_button_icon"
                    aria-hidden="true"
                  />
                </Button>
              </div>
            </div>
          </div>
          {Var_AppointmentDetail.length > 0 && (
            <div style={{ padding: "30px 81px 30px 81px" }}>
              <div className="particular_card_appointment_details">
                {Var_AppointmentDetail.map((value) => (
                  <div className="header_profile_details">
                    <div>
                      <div className="profile_details_prescription_new">
                        <div className="profile_detail_container">
                          <img
                            style={{ border: "1px solid var(--app-theme)" }}
                            src={
                              value.appointment_type_id == 0 &&
                                value.gender == "male"
                                ? MaleIcon
                                : value.appointment_type_id == 0 &&
                                  value.gender == "Male"
                                  ? MaleIcon
                                  : value.appointment_type_id == 0 &&
                                    value.gender == "female"
                                    ? FemaleIcon
                                    : value.appointment_type_id == 0 &&
                                      value.gender == "Female"
                                      ? FemaleIcon
                                      : value.file_name == "" &&
                                        value.gender == "male"
                                        ? MaleIcon
                                        : value.file_name == "" &&
                                          value.gender == "Male"
                                          ? MaleIcon
                                          : value.file_name == "" &&
                                            value.gender == "female"
                                            ? FemaleIcon
                                            : value.file_name == "" &&
                                              value.gender == "Female"
                                              ? FemaleIcon
                                              : value.file_name == " " &&
                                                value.gender == "male"
                                                ? MaleIcon
                                                : value.file_name == " " &&
                                                  value.gender == "Male"
                                                  ? MaleIcon
                                                  : value.file_name == " " &&
                                                    value.gender == "female"
                                                    ? FemaleIcon
                                                    : value.file_name == " " &&
                                                      value.gender == "Female"
                                                      ? FemaleIcon
                                                      : value.file_name == null &&
                                                        value.gender == "male"
                                                        ? MaleIcon
                                                        : value.file_name == null &&
                                                          value.gender == "Male"
                                                          ? MaleIcon
                                                          : value.file_name == null &&
                                                            value.gender == "female"
                                                            ? FemaleIcon
                                                            : value.file_name == null &&
                                                              value.gender == "Female"
                                                              ? FemaleIcon
                                                              : value.file_name == "undefined" &&
                                                                value.gender == "male"
                                                                ? MaleIcon
                                                                : value.file_name == "undefined" &&
                                                                  value.gender == "Male"
                                                                  ? MaleIcon
                                                                  : value.file_name == "undefined" &&
                                                                    value.gender == "female"
                                                                    ? FemaleIcon
                                                                    : value.file_name == "undefined" &&
                                                                      value.gender == "Female"
                                                                      ? FemaleIcon
                                                                      : value.file_name
                            }
                            alt="profile"
                            className="profile_Details1"
                          />
                          <div>
                            <div className="detailsprofile">
                              <p className="name_details_new">Name</p>
                              <p className="profile_name">{value.name}</p>
                            </div>
                            <div className="detailsprofile">
                              <p className="name_details_new">Patient ID </p>
                              <p className="profile_name">{value.patient_id}</p>
                            </div>
                          </div>
                          <div>
                            <div className="detailsprofile">
                              <p className="name_details_new">Gender</p>
                              <p className="profile_name">{value.gender}</p>
                            </div>
                            <div className="detailsprofile">
                              <p className="name_details_new">Phone No</p>
                              <p className="profile_name">{value.mobile_no}</p>
                            </div>
                          </div>
                          <div>
                            <div className="detailsprofile">
                              <p className="name_details_new">Age</p>
                              <p className="profile_name">{value.age}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="stepper-horizontal appoint_horizontal_stepper_details">
                        {steps.map((data, index) => (
                          <React.Fragment key={index}>
                            <div
                              className="stepper-step"
                              onClick={() => {
                                if (data.prescriptDate === "Report") {
                                  navigateToReportsPage(value);
                                } else if (
                                  data.prescriptDate === "Prescription"
                                ) {
                                  navigateToPrescriptionPage(value);
                                } else if (
                                  data.prescriptDate === "Upload Report"
                                ) {
                                  navigateToUploadReportPage(value);
                                } else if (data.prescriptDate === "Arrived") {
                                  debugger;
                                  {
                                    value.arrived_status != 1 &&
                                      check(
                                        value.booking_id,
                                        value.appointment_type_id == 0 ? 1 : 0,
                                        value.name
                                      );
                                  }
                                } else if (data.prescriptDate === "Health-matrics") {
                                  debugger;
                                  {value.check_in_status == 0 && value.arrived_status == 1 &&
                                    walkingBookingHealthMatrics(value.patient_id,value.booking_id,value.height,value.weight,value.blood_pressure,
                                      value.heart_rate,value.body_temperature,value.health_matrics_status,value.pre_exits_disease);
                                  }
                                } else if (data.prescriptDate === "Check-in") {
                                  debugger;
                                  {value.check_in_status == 1 ? notification.success({
                                    message: "Patient Already checked in",
                                  }) : value.arrived_status == 1 && value.health_matrics_status == 1 
                                    ? postCheckedIn(
                                       value.booking_id,
                                       value.appointment_type_id == 0 ? 1 : 0,
                                       value.name
                                     ) : notification.error({
                                       message: "Please fill the health matrics",
                                     });
                                 }
                                } else if (data.prescriptDate === "Check-out") {
                                  debugger;
                                  {
                                    value.check_in_status == 1 && value.arrived_status == 1 && value.health_matrics_status == 1
                                      ? postCheckedOut(
                                        value.booking_id,
                                        value.appointment_type_id != 0
                                          ? 0
                                          : 1,
                                        value.name
                                      )
                                      : notification.error({
                                        message: "Please checkin first",
                                      });
                                  }
                                } else if (data.prescriptDate === "Payment") {
                                  debugger;
                                  {
                                    value.arrived_status == 1 &&
                                      value.check_in_status === 1 &&
                                      value.check_out_status === 1 &&
                                      value.payment_status == 0
                                      ? paymentInsert(
                                        value?.booking_id,
                                        value?.patient_id,
                                        value.totalFee
                                      )
                                      : test();
                                  }
                                }
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <div className="stepper-circle">
                                  <img
                                    className="report_doctor_profile"
                                    src={
                                      data.prescriptDate === "Arrived" &&
                                        value.arrived_status === "0" ?
                                        Arriveddull :
                                        data.prescriptDate === "Health-matrics" &&
                                        value.health_matrics_status === 0
                                        ? healthmatricsdull :
                                        data.prescriptDate === "Check-in" &&
                                          value.check_in_status === 0
                                          ? checkindull
                                          : data.prescriptDate === "Check-out" &&
                                            value.check_out_status === 0
                                            ? checkoutdull
                                            : data.prescriptDate === "Payment" &&
                                              value.payment_status === 0
                                              ? paymentdull
                                              : data.icon
                                    }
                                    alt={`${data.prescriptDate} icon`}
                                  />
                                </div>
                                {index < totalSteps - 1 && (
                                  <div className="stepper-line_horizontal appointment_stepper_details"></div>
                                )}
                              </div>
                              <div className="stepper-card_comment">
                                <p className="report_time time_appointments_details">
                                  {data.prescriptDate}
                                </p>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* =================== Content End ============================== */}

      {/* =============================== Add Appointment New Model Start ========================= */}
      <Dialog
        fullWidth={fullWidth}
        alignment="center"
        size="md"
        open={WalkInAppointmentModel}
        onClose={setWalkInAppointmentModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "850px",
              borderRadius: "8px !important",
              padding: "10px 0px 30px",
            },
          },
        }}
      >
        <div className="dashboard_dialog_header">
          {/* <div className="dialog_title"> WALK-IN BOOKING</div> */}
          <div className="flex_grow"></div>
          {/* <div className="walk_appointment_booking" style={{marginBottom:"5px"}}>
                                    <p className="walk_model_header_text">
                                        Select Appointment Date<span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div> */}
                                <DatePicker
                                selected={startDate}
                                onChange={(date) =>getAppointmentDate(date)} // Set the selected date
                                customInput={<CustomInput />} // Use custom input with the icon
                                minDate={new Date()} // Prevent dates before today
                                />
          <div onClick={() => isAppointmentCloseModel()}>
            <img style={{ cursor: "pointer" }} src={closeicon} />
          </div>
        </div>
        <DialogContent>
          <Container>
            <div className="row">
              <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="walk_appointment_booking">
                  <p className="walk_model_header_text">
                    Patient Name
                    <span className="walk_model_header_text_valid"> *</span>
                  </p>
                </div>
                <div className="enter_input_name">
                  <input
                    className="holder_input_name"
                    value={Var_ProfileName}
                    type="text"
                    placeholder="Enter full name"
                    onChange={(event) => setVar_ProfileName(event.target.value)}
                  />
                </div>
                {Var_ProfileName_Ref.current !== "" ? (
                  <></>
                ) : (
                  <>
                    {walname == true && (
                      <div item xs={12} className="field_required pb-1 pt-0">
                        Field Required
                      </div>
                    )}
                  </>
                )}
                <div className="walk_appointment_booking">
                  <p className="walk_model_header_text">
                    Age<span className="walk_model_header_text_valid"> *</span>
                  </p>
                </div>
                <div className="enter_input_name">
                  <input
                    className="holder_input_name"
                    value={Var_ProfileAge}
                    type="number"
                    placeholder="Enter age"
                    maxLength="3"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (/^\d{0,2}$/.test(value)) { // Accept only up to 2 digits
                        setVar_ProfileAge(value);
                      }
                    }}
                  />
                </div>
                {Var_ProfileAge_Ref.current !== "" ? (
                  <></>
                ) : (
                  <>
                    {walage == true && (
                      <div className="field_required">Field Required</div>
                    )}
                  </>
                )}
              </div>
              <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_right_part">
                <div className="walk_appointment_booking">
                  <p className="walk_model_header_text">
                    Gender
                    <span className="walk_model_header_text_valid"> *</span>
                  </p>
                </div>
                <div className="enter_input_name">
                  <FormControl
                    sx={{ m: "8px 0px", minWidth: 353 }}
                    size="small"
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={Var_gender}
                      renderValue={(p) => p}
                      onChange={(e) => getGender(e)}
                    >
                      <MenuItem value={"M"}>Male</MenuItem>
                      <MenuItem value={"F"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {walgender == true && (
                  <div className="field_required">Field Required</div>
                )}
                <div className="walk_appointment_booking">
                  <p className="walk_model_header_text">
                    Phone Number
                    <span className="walk_model_header_text_valid"> *</span>
                  </p>
                </div>
                <div className="enter_input_name">
                  <input
                    className="holder_input_name"
                    value={Var_ProfilePhone}
                    type="number"
                    placeholder="Enter number"
                    maxLength="10"
                    onChange={(e) =>
                      walkinMobileNumbetValidation(e)
                    }
                  />
                </div>
                {Var_ProfilePhone_Ref.current !== "" ? (
                  <></>
                ) : (
                  <>
                    {walmobile == true && (
                      <div className="field_required">Field Required</div>
                    )}
                  </>
                )}
              </div>
              <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_service_part">
                <div className="walk_appointment_booking">
                  <p className="walk_model_header_text">
                    Symptoms
                    <span className="walk_model_header_text_valid"> *</span>
                  </p>
                </div>
                <div className="symptom_parent">
                  <input
                    className="symptom_input"
                    value={Var_GetSymptoms}
                    type="text"
                    placeholder="Enter symptoms"
                    onChange={(event) => setVar_GetSymptoms(event.target.value)}
                  />
                    <img  className="symptom_add_icon" onClick={()=>getSymptoms()} src={AddIcon}></img>
                </div>
                {Var_symptomsValue_Ref.current?.map((item,i) =>(
                                <span className="symptoms_chip_main">{item}{" "}<img onClick={()=>removeSymptoms(i)}  alt="Remove" className="symptoms_remove_icon" src={closeIcon}></img></span>
                                ))}
                                {Var_symptomsValue_Ref.current.length !== 0 ? (
                  <></>
                ) : (
                  <>
                    {walsymptoms == true && (
                      <div item xs={12} className="field_required pb-1 pt-0">
                        Field Required
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_service_part">
                <div className="walk_appointment_booking">
                  <p className="walk_model_header_text">
                    Select Specialty
                    <span className="walk_model_header_text_valid"> *</span>
                  </p>
                </div>

              <div className="dropdown_control">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      sx={{
                        padding: "8.5px 14px",
                        height: "40px",
                        minHeight: 15,
                        minWidth: "-webkit-fill-available",
                      }}
                      value={Var_GetSpecilaitySelect}
                      onChange={(e) => {
                        setVar_GetSpecilaitySelect(e.target.value);
                        getservice(Var_GetSpecilaitySelect_Ref.current.id);
                        setVar_SelectTimeSlots("");
                        setVar_WalingNoSlots("0");
                        setVar_WalkInGetSlots([]);
                        setwalspecial(false);
                      }}
                      renderValue={(Var_GetSpecilaitySelect) => {
                        return Var_GetSpecilaitySelect.speciality;
                      }}
                    >
                      <MenuItem
                        key={0}
                        value={{
                          id: "",
                          speciality: "Select Speciality"
                        }}
                      ></MenuItem>

                      {Var_SpecialityDoctorList_Ref.current.map((item) => (
                        <MenuItem key={item.speciality} value={item}>
                          <Container>
                            <Grid container>
                              <Grid
                                item
                                xs={9}
                                className="ref_lab"
                                align="start"
                              >
                                <p className="dropdowntext">{item.speciality}</p>
                              </Grid>
                            </Grid>
                          </Container>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                {walspecial == true &&
                  <Grid item xs={12} className="field_required py-1">
                    Field Required
                  </Grid>
                }
              </div>
              </div>
              <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 walk_model_service_part">
                <div className="walk_appointment_booking">
                  <p className="walk_model_header_text">
                    Select Service
                    <span className="walk_model_header_text_valid"> *</span>
                  </p>
                </div>

                <div className="dropdown_control">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        sx={{
                          padding: "8.5px 14px",
                          height: "40px",
                          minHeight: 15,
                          minWidth: "-webkit-fill-available",
                        }}
                        value={Var_GetServiceListSelect}
                        onChange={(e) => {
                          setVar_GetServiceListSelect(e.target.value);
                          getSlotsService();
                          setVar_SelectTimeSlots("");
                        }}
                        renderValue={(Var_GetServiceListSelect) => {
                          return Var_GetServiceListSelect.service;
                        }}
                      >
                        <MenuItem
                          key={0}
                          value={{
                            cost: "",
                            description: "",
                            doctor_id: "",
                            file_name: "",
                            file_path: "",
                            id: 0,
                            is_active: "",
                            service: "Select Service",
                            slot_duration: "",
                            spec_id: "",
                          }}
                        ></MenuItem>

                        {Var_GetServiceList.map((item) => (
                          <MenuItem key={item.service} value={item}>
                            {item.is_active == 1 && (
                              <Container>
                                <Card className="dropdown_card">
                                  <Grid container>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={1} align="start">
                                      <img
                                        src={
                                          item.file_path == " "
                                            ? VectorIcon
                                            : item.file_path ==
                                              "[object Object]"
                                              ? VectorIcon
                                              : item.file_path == ""
                                                ? VectorIcon
                                                : item.file_path
                                        }
                                        alt="a"
                                        className="dropdownimg"
                                      />
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid
                                      item
                                      xs={9}
                                      className="ref_lab"
                                      align="start"
                                    >
                                      <p className="dropdowntext">
                                        {item.service}
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Container>
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  {walservice == true && (
                    <Grid item xs={12} className="field_required">
                      Field Required
                    </Grid>
                  )}
                </div>
                {Var_WalingNoSlots_Ref.current == "1" &&
                  Var_WalkInGetSlots.length == 0 && (
                    <Grid container className="modal_slots_time">
                      <Grid item>
                        <p className="dashboard_warning_helper_txt">
                          Slots Not Available
                        </p>
                      </Grid>
                    </Grid>
                  )}
                {Var_WalkInGetSlots.length != 0 && (
                  <>
                    <Grid container className="modal_slots_time">
                      <Grid item>
                        <p className="dashboard_helper_txt">Slots</p>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{ justifyContent: "space-evenly" }}
                      >
                        <Grid container className="dashboard_total_slots_top">
                          {Var_WalkInGetSlots?.map((value) => (
                            <Grid item xs={2}>
                              {(value.date >=
                                moment(new Date()).format("YYYY-MM-DD") ==
                                true ||
                                value.from_time >=
                                new Date().getHours() +
                                ":" +
                                new Date().getMinutes() +
                                ":" +
                                new Date().getSeconds()) && (
                                  <>
                                    <div className="dashboard_time_sloat">
                                      <p
                                        className={`dashboard_time_show ${value.is_booked != 0
                                          ? "dashboard_time_disabled"
                                          : ""
                                          }`}
                                        onClick={() => {
                                          if (value.is_booked == 0) {
                                            selectSlots(value);
                                          }
                                        }}
                                        disabled={
                                          value.is_booked == 1 ? true : false
                                        }
                                        style={{
                                          backgroundColor:
                                            value.is_booked == 0
                                              ? value.is_clicked == 1
                                                ? "var(--app-theme)"
                                                : "#2A7A6F40"
                                              : "#FBEAE5",
                                          color:
                                            value.is_booked == 0
                                              ? value.is_clicked == 1
                                                ? "white"
                                                : "var(--app-theme) "
                                              : "#E12F2F",
                                          border:
                                            value.is_booked == 0
                                              ? "1px solid var(--app-theme) "
                                              : "1px solid #E12F2F",
                                        }}
                                      >
                                        {timeFormat(value.from_time)}
                                      </p>
                                    </div>
                                  </>
                                )}
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <div>
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="dashboard_select_finder">
                        <div className="dashboard_select_icon">
                          <div className="dashboard_check_box_booked" />
                          <p className="booked">Booked</p>
                        </div>
                        <div className="dashboard_select_icon">
                          <div className="dashboard_check_box_available" />
                          <p className="available">Available</p>
                        </div>
                        <div className="dashboard_select_icon">
                          <div className="dashboard_check_box_selected" />
                          <p className="selected">Selected</p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="walk_appointment_booking_button">
              <button
                className="walk_appointment_cancel_button"
                onClick={() => isCloseModel("WalkInModel")}
              >
                Clear
              </button>
              <button
                className="walk_appointment_book_button"
                onClick={
                  walBookingBtnStateRef.current
                    ? () => bookedSuccessfully()
                    : undefined
                }
              >
                Add Appointment
              </button>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
      {/* =============================== Add Appointment Model End ========================= */}


      {/* ======================== Referral Dialog Start======================================= */}
      <Dialog
        fullWidth={fullWidth}
        alignment="center"
        size="md"
        open={isOpenReferralModel}
        onClose={setisOpenReferralModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "450px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <div className="dialog_header">
          <div className="dialog_title">REFERRAL</div>
          <div className="flex_grow"></div>
          <div onClick={() => isCloseModel("ReferralModel")}>
            <img src={closeicon} />
          </div>
        </div>
        <DialogContent>
          <Grid container className="referral_row">
            <Grid
              item
              xs={6}
              className="referral_doctor_column border_curvesize"
              align="center"
              style={{ color: Var_LabColor, backgroundColor: Var_LabBackColor }}
              value="lab"
              onClick={() => {
                referralTab("lab");
              }}
            >
              <p className="referral_doctor">Lab</p>
            </Grid>
            <Grid
              item
              xs={6}
              className="referral_lab_column border_curvesizes"
              align="center"
              style={{
                color: Var_DoctorColor,
                backgroundColor: Var_DoctorBackColor,
              }}
              value="doctor"
              onClick={() => {
                referralTab("doctor");
              }}
            >
              <p className="referral_lab">Doctor</p>
            </Grid>
          </Grid>
          <div className="content_doctor_visible" style={{ display: Var_Lab }}>
            {/* {ShowSelectTestMul_Ref.current == false && */}
            <div className="dropdown_control">
              <Grid container spacing={2} className="1">
                <Grid item xs={12} className="2">
                  {/* <InputLabel id="demo-select-small-label">Select Lab</InputLabel> */}
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    sx={{
                      padding: "8.5px 14px",
                      height: "40px",
                      minHeight: 15,
                      minWidth: "-webkit-fill-available",
                    }}
                    value={Var_GetLabListSelect}
                    onChange={(e) => {
                      setVar_GetLabListSelect(e.target.value);
                      getTestsByLab();
                    }}
                    renderValue={(Var_GetLabListSelect) => {
                      return Var_GetLabListSelect.Lab;
                    }}
                    placeholder="Select Lab22"
                    // open={showSelect}
                    // onOpen={() => {
                    //   setShowSelect(true);
                    //   setShowSelectTestMul(true);
                    // }}
                    //onClose={() => setShowSelect(false)}
                    open={showSelectLab}
                    onOpen={() => { setShowSelectLab(true) }}
                    onClose={() => setShowSelectLab(false)}
                  >

                    <MenuItem
                      key={0}
                      value={{
                        labId: 0,
                        Lab: "Select Lab",
                        profilepath: "",

                      }}
                    ></MenuItem>
                    <div className="dropdown-labmenu">
                      {Var_GetLabList.map((item) => (
                        <MenuItem key={item.Lab} value={item} onClick={() => handleLabClick(item)}>
                          <Container>
                            <Card className="dropdown_card">
                              <Grid container>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={1} align="start">
                                  <img
                                    src={item.profilepath}
                                    className="dropdownimg"
                                  />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid
                                  item
                                  xs={9}
                                  className="ref_lab"
                                  align="start"
                                >
                                  <p className="dropdowntext">{item.Lab}</p>
                                </Grid>
                              </Grid>
                            </Card>
                          </Container>
                        </MenuItem>
                      ))}
                    </div>
                    {/* <div className="multiselect_lab_test">
                    <button
                      className="alert_no_btn "
                      onClick={() => {
                        setShowSelect(false);
                        setPersonName([]);
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="alert_yes_btn "
                      onClick={() => {
                        setShowSelect(false);
                      }}
                    >
                      OK
                    </button>
                  </div> */}

                  </Select>
                </Grid>
                {labselect == true &&
                  <Grid item xs={12} className="field_required">
                    Field Required
                  </Grid>
                }
              </Grid>
            </div>
            {/* // } */}

            <div className="dropdown_control">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl className="select_lab_width">
                    {/* <InputLabel
                      id="demo-multiple-checkbox-label"
                      style={{ marginTop: "-6px", color: "black" }}
                    >
                      Select Test
                    </InputLabel> */}
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      sx={{
                        padding: "8.5px 14px",
                        height: "40px",
                        minHeight: 15,
                        width: "100%",
                        minWidth: "-webkit-fill-available",
                      }}
                      className="select_lab_width"
                      multiple
                      value={personName}
                      open={showSelect}
                      onOpen={() => { setShowSelect(true) }}
                      onClose={() => setShowSelect(false)}
                      onChange={handleChange}
                      input={<OutlinedInput label="" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key={0} value={"Select Test"}></MenuItem>
                      {Var_GetLabTestList.map((name) => (
                        <MenuItem Key={name.testId} value={name.testName}>
                          <Checkbox
                            checked={personName.indexOf(name.testName) > -1}
                          />
                          <ListItemText primary={name.testName} />
                        </MenuItem>
                      ))}
                      <div className="multiselect_lab_test">
                        <button className="alert_no_btn " onClick={() => {
                          setShowSelect(false);
                          setPersonName([]);
                        }}>Close</button>
                        <button className="alert_yes_btn " onClick={() => {
                          setShowSelect(false);
                          setlabTest(false);
                        }}>OK</button>
                      </div>
                    </Select>

                    {/* <Select
                                         labelId="demo-multiple-checkbox-label"
                                         id="demo-multiple-checkbox"
                                        
                                   
                                        sx={{ padding: '8.5px 14px', height: '40px', minHeight: 15, minWidth: '-webkit-fill-available' }}
                                        value={Var_GetLabTestSelect}
                                        onChange={(e) => { setVar_GetLabTestSelect(e.target.value) }}
                                        renderValue={(Var_GetLabTestSelect) => { return Var_GetLabTestSelect.testName }}
                                    >
                                        <MenuItem key={0} value={{
                                            "testId": 0,
                                            "testName": "Select Test",
                                            "profilepath": ""
                                        }}>
                                        </MenuItem>
                                        {Var_GetLabTestList.map((item) => (
                                            <MenuItem
                                                key={item.testName} value={item}
                                            >
                                                <Container>
                                                    <Card className='dropdown_card'>
                                                        <Grid container >
                                                            <Grid item xs={1}></Grid >
                                                            <Grid item xs={9} align="start">
                                                                <p className="dropdowntext">{item.testName}</p>
                                                            </Grid >
                                                            <Grid item xs={1} className='ref_lab' align="start">
                                                                <Checkbox checked={item.id} />
                                                            </Grid >
                                                            <Grid item xs={1}></Grid >
                                                        </Grid >

                                                    </Card>
                                                </Container>
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                  </FormControl>
                </Grid>
                {labTest == true &&
                  <Grid item xs={12} className="field_required">
                    Field Required
                  </Grid>
                }
              </Grid>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} className="select_referral">
                {/* <FormControl fullWidth> */}
                <div className="TextArea">
                  <TextField
                    value={Var_LabRemark}
                    placeholder="Remarks"
                    multiline
                    rows={3}
                    maxRows={2}
                    className="fieldtext"
                    sx={{
                      height: "40px",
                      minHeight: 15,
                      minWidth: "-webkit-fill-available",
                    }}
                    onChange={(e) => { setVar_LabRemark(e.target.value); setlabrmark(false); }}
                  />
                </div>
                {/* </FormControl> */}
              </Grid>

            </Grid>
            <div className="files_heads">
              {labrmark == true &&
                <Grid item xs={12} className="field_required">
                  Field Required
                </Grid>
              }
            </div>
            <div className={labrmarkclassName}>
              <Grid container spacing={2} className="files">
                <Grid item xs={12} align="center">
                  <div className="files_head">
                    <p>If required, attach Report.</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="file_upload_content">
              <Grid container>
                <Grid item xs={12} className="ref_lab" align="center">
                  <div style={{ display: Var_UploadImageCss }}>
                    <div className="image-container">
                      <img
                        src={Uploading}
                        alt="Upload Image"
                        id="preview-image"
                      />
                      <input
                        accept="image/*"
                        type="file"
                        id="file-input"
                        className="upload-input"
                        ref={fileInputRef}
                        onChange={(e) => {
                          uploadDocumentLab(e);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: Var_ShowImageCss }}>
                    <div className="segment_center">
                      <Grid container className="report_image">
                        <Grid item align="center">
                          <img src={Var_File} alt="" width="100%" />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="segment_center">
                      <div className="button_container">
                        <Grid container className="cancel_button">
                          <Grid item align="center">
                            <div className="report_cancel_button">
                              <div
                                className="button_text"
                                onClick={() => fileUploadCalcel()}
                              >
                                <p style={{ marginTop: "4px" }}>{Var_FileName} <span style={{ margin: "1px 5px" }}>X</span></p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    {/* <div className="image-container">
                                            <img src={Var_File_Ref} alt="Upload Image" id="preview-image" />

                                        </div> */}
                  </div>
                  {Var_fileprocess && (
                    <div>
                      {/* <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999999' }} /> */}
                      <div
                        className="upload-container"
                        style={{ paddingTop: "15px" }}
                      >
                        {/* Your other UI elements */}
                        <LinearProgress
                          variant="determinate"
                          value={Var_progress}
                          color="secondary"
                          className="progressBar"
                          sx={{
                            zIndex: "9999999",
                            width: "100%",
                            margin: "0px 3px",
                          }}
                        />
                        {/* Display progress percentage */}
                        <div className="progress-text">{`${Var_progress}%`}</div>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className="segment_center">
              <button
                className="botton_Cart_Create"
                disabled={isDisable}
                onClick={() => onReferral("labreferral")}
              >
                Refer
              </button>
            </div>
          </div>
          <div className="content_lab_visible" style={{ display: Var_Doctor }}>
            <div className="dropdown_control">
              <div className="dropdown_control_doctor">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <InputLabel id="demo-select-small-label">Select Specialty</InputLabel> */}
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      sx={{
                        padding: "8.5px 14px",
                        height: "40px",
                        minHeight: 15,
                        minWidth: "-webkit-fill-available",
                      }}
                      value={Var_DoctorSpecialitySelect}
                      onChange={(e) => {
                        setVar_DoctorSpecialitySelect(e.target.value);
                        getDoctorBySpeciality();
                        setdocselect(false);
                      }}
                      renderValue={(Var_DoctorSpecialitySelect) => {
                        return Var_DoctorSpecialitySelect.speciality;
                      }}
                    >
                      <MenuItem
                        clas
                        key={0}
                        value={{
                          id: 0,
                          speciality: "Select Specialty",
                          speciality_filename: "",
                        }}
                      ></MenuItem>

                      {Var_DoctorSpeciality.map((item) => (
                        <MenuItem key={item.speciality} value={item}>
                          <Container>
                            <Card className="dropdown_card">
                              <Grid container>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={1} align="start">
                                  <img
                                    src={item.speciality_filename}
                                    className="dropdownimg"
                                  />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid
                                  item
                                  xs={9}
                                  className="ref_lab"
                                  align="start"
                                >
                                  <p className="dropdowntext">
                                    {item.speciality}
                                  </p>
                                </Grid>
                              </Grid>
                            </Card>
                          </Container>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {docselect == true &&
                    <Grid item xs={12} className="field_required">
                      Field Required
                    </Grid>
                  }
                </Grid>
              </div>
            </div>
            <div className="dropdown_control">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* <InputLabel id="demo-select-small-label">Select Doctor</InputLabel> */}
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    sx={{
                      padding: "8.5px 14px",
                      height: "40px",
                      minHeight: 15,
                      minWidth: "-webkit-fill-available",
                    }}
                    value={Var_DoctorBySpecialitySelect}
                    onChange={(e) =>
                      setVar_DoctorBySpecialitySelect(e.target.value)
                    }
                    renderValue={(Var_DoctorBySpecialitySelect) => {
                      return Var_DoctorBySpecialitySelect.doctorName;
                    }}
                    open={showSelectDoctor}
                    onOpen={() => { setShowSelectDoctor(true) }}
                    onClose={() => setShowSelectDoctor(false)}
                  >
                    <MenuItem
                      key={0}
                      value={{
                        doctorId: 0,
                        doctorName: "Select Doctor",
                        profileImage: "",
                        location: "",
                      }}
                    ></MenuItem>
                    <div className="dropdown-doctormenu">
                      {Var_DoctorBySpeciality.map((item) => (
                        <MenuItem key={item.doctorName} value={item} onClick={() => handleDoctorClick(item)}>
                          <Container>
                            {localStorage.getItem("Doctor_id") !=
                              item.doctorId && (
                                <Card className="dropdown_card">
                                  <Grid container>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={1} align="start">
                                      <img
                                        src={item.profileImage}
                                        className="dropdownimg"
                                      />
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid
                                      item
                                      xs={9}
                                      className="ref_lab"
                                      align="start"
                                    >
                                      <p className="dropdowntext">
                                        {item.doctorName}
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Card>
                              )}
                          </Container>
                        </MenuItem>
                      ))}
                    </div>
                  </Select>
                </Grid>
                {docTest == true &&
                  <Grid item xs={12} className="field_required">
                    Field Required
                  </Grid>
                }
              </Grid>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} className="select_referral">
                <FormControl fullWidth>
                  <div className="TextArea">
                    <TextField
                      placeholder="Remarks"
                      value={Var_DoctorRemark}
                      multiline
                      rows={3}
                      maxRows={2}
                      className="fieldtext"
                      sx={{
                        height: "40px",
                        minHeight: 15,
                        minWidth: "-webkit-fill-available",
                      }}
                      onChange={(e) => { setVar_DoctorRemark(e.target.value); setdocrmark(false) }}
                    />
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <div className="files_heads">
              {docrmark == true &&
                <Grid item xs={12} className="field_required">
                  Field Required
                </Grid>
              }
            </div>
            <div className={docrmarkclassName}>
              <Grid container spacing={2} className="files">
                <Grid item xs={12} align="center">
                  <div className="files_head">
                    <p>If required, attach Report.</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="file_upload_content">
              <Grid container>
                <Grid item xs={12} className="ref_lab" align="center">
                  <div style={{ display: Var_UploadImageCss }}>
                    <div className="image-container">
                      <img
                        src={Uploading}
                        alt="Upload Image"
                        id="preview-image"
                      />
                      <input
                        accept="image/*"
                        type="file"
                        id="file-input"
                        className="upload-input"
                        ref={fileInputDoctorRef}
                        onChange={(e) => {
                          uploadDocumentDoctor(e);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: Var_ShowImageCss }}>
                    <div className="segment_center">
                      <Grid container className="report_image">
                        <Grid item align="center">
                          <img src={Var_File} alt="" width="100%" />
                        </Grid>
                      </Grid>
                    </div>
                    <div className="segment_center">
                      <div className="button_container">
                        <Grid container className="cancel_button">
                          <Grid item align="center">
                            <div className="report_cancel_button">
                              <div
                                className="button_text"
                                onClick={() => fileUploadCalcel()}
                              >
                                <p style={{ marginTop: "4px" }}>{Var_FileName} <span style={{ margin: "1px 5px" }}>X</span></p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    {/* <div className="image-container">
                                            <img src={Var_Document} alt="Upload Image" id="preview-image" />

                                        </div> */}
                  </div>
                  {Var_fileprocess && (
                    <div>
                      {/* <LinearProgress color="secondary" className="progressBar" sx={{ zIndex: '9999999' }} /> */}
                      <div
                        className="upload-container"
                        style={{ paddingTop: "15px" }}
                      >
                        {/* Your other UI elements */}
                        <LinearProgress
                          variant="determinate"
                          value={Var_progress}
                          color="secondary"
                          className="progressBar"
                          sx={{
                            zIndex: "9999999",
                            width: "100%",
                            margin: "0px 3px",
                          }}
                        />
                        {/* Display progress percentage */}
                        <div className="progress-text">{`${Var_progress}%`}</div>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className="segment_center">
              <button
                className="botton_Cart_Create"
                disabled={isDisable}
                onClick={() => onReferral("doctorreferral")}
              >
                Refer
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ======================== Referral Dialog End ======================================= */}

      {/* ======================== PAY Dialog Start ======================================= */}
      <Dialog
        fullWidth={fullWidth}
        open={isOpenPayModel}
        onClose={setisOpenPayModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "430px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <div className="appoinment_payment_method">
          <div className="appoinment_payment_title">PAYMENT METHOD</div>
          <div className="flex_grow"></div>
          <div onClick={() => setisOpenPayModel()}>
            <img src={closeicon} />
          </div>
        </div>
        <div className={Isinsurance_Ref.current === 0 ? 'payment_method_model_rm_insu' : 'payment_method_model'}>
          <DialogContent>
            {/* <div className="success_model_body">
                        <div className="icon">
                            <img src={alertimg} />
                        </div>
                        <div className="title">ARE YOU SURE?</div>
                        <div className="content">You want to change the payment status?</div>
                        <div className="alert_btn_center">
                            <button className="alert_no_btn " onClick={() => noClose()}>No</button>
                            <button className="alert_yes_btn " onClick={() => paymentStatus()}>Yes</button>
                        </div>
                    </div> */}

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
            >
              <div className="appoinment_pay_status ">
                <Grid container className={Isinsurance_Ref.current === 0 ? "rm-border-bottom" : ""}>
                  <Grid item xs={11.5}>
                    <label className="pay_label">
                      Pay at Clinic{" "}
                      <span style={{ fontSize: "12px" }}>(No Insurance)</span>
                    </label>
                  </Grid>
                  <Grid item xs={0.5}>
                    <div className="deal_option_group">
                      <FormControlLabel
                        value="payatclinic"
                        control={
                          <Radio
                            onClick={() => {
                              isOpenPaymentTypeModal("payAtClinic");
                            }}
                          />
                        }
                      />
                      <div className="flex_grow"></div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {
                Isinsurance_Ref.current !== 0 &&
                <div className="appoinment_pay_status">
                  <Grid container>
                    <Grid item xs={11.5}>
                      <label className="pay_label">
                        Insurance Booking{" "}
                        <span style={{ fontSize: "12px" }}> (Full Coverage)</span>
                      </label>
                    </Grid>
                    <Grid item xs={0.5}>
                      <div className="deal_option_group">
                        <FormControlLabel
                          control={
                            <Radio

                              onClick={() => {
                                isOpenPaymentTypeModal("payAtInsurance");
                              }}
                            />
                          }
                        />
                        <div className="flex_grow"></div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              }

              {
                Isinsurance_Ref.current !== 0 &&
                <div className="appoinment_pay_status">
                  <Grid container>
                    <Grid item xs={11.5}>
                      <label className="pay_label">
                        Pay at Clinic & Insurance Booking{" "}
                        <span style={{ fontSize: "12px" }}>
                          (Partial Coverage)
                        </span>
                      </label>
                    </Grid>
                    <Grid item xs={0.5}>
                      <div className="deal_option_group">
                        <FormControlLabel
                          value="payatclinicandinsurancebooking"
                          control={
                            <Radio
                              onClick={() => {
                                isOpenPaymentTypeModal("");
                              }}
                            />
                          }
                        />
                        <div className="flex_grow"></div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              }



            </RadioGroup>
          </DialogContent>
        </div>
      </Dialog>
      {/* ======================== PAY Dialog End ======================================= */}
      {/* ======================== PAY Dialog sbumit  start ======================================= */}

      <Dialog
        fullWidth={fullWidth}
        open={Var_isOpenPaymentTypeModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <div className="appoinment_payment_method">
          <div className="appoinment_payment_title">PAYMENT METHOD</div>
          <div className="flex_grow"></div>
          <div onClick={() => isClosePatAtClinicModal()}>
            <img src={closeicon} />
          </div>
        </div>
        <DialogContent>
          <div className="apponment_payment_cost">
            {Var_valueshow_Ref.current != 0 && Var_viewdata.map((data) => (
              <h6 className="payment_type_title">
                Pay at Clinic{" "}
                <span style={{ fontSize: "12px" }}>(No Insurance) </span>
                <span className="currency_value">(KWD)</span>
                <img
                  src={Viewmore}
                  className="ViewMore"
                  onClick={() => {
                    openModel(data, 7);
                  }}
                />
              </h6>
            ))}

            {/* {Var_AppointmentDetail.map((value) => ( */}
            <TextField
              sx={{ Width: "300px" }}
              inputProps={{ readOnly: Var_valueshow_Ref.current != 0 ? true : false }}
              id="standard-basic"
              label={Var_valueshow_Ref.current != 0 ? "" : "Enter amount"}
              variant="standard"
              value={Var_PayatClinicCost_Ref.current}
              onChange={(e) => {
                setVar_PayatClinicCost(e.target.value.replace(/[^\d.]/g, ''))
                clearError('Var_PayatClinicCost')
              }}

            />
            {Var_PayatClinicCost_Ref.current.length == 0 && errors.Var_PayatClinicCost && <div className="error-message">{errors.Var_PayatClinicCost}</div>}

            {Var_valueshow_Ref.current != 0 &&
              <div>
                <div className="additional_payment_source">
                  <div>
                    <h6 className="payment_type_title_checkbox">
                      Additional Service
                    </h6>
                  </div>

                  <div>
                    <Checkbox
                      style={{ fontSize: "29px" }} onChange={(event) => clickhandler(event)}>
                    </Checkbox>
                  </div>
                </div>

                {checkboxvariable &&
                  <div>
                    <div>
                      <Grid container>

                        <Grid item xs={8}>
                          <div className="payment_type_title_text">
                            Additional Service Description
                          </div>
                        </Grid>

                        <Grid item xs={4}>
                          <div className="payment_type_title_text">
                            Cost
                          </div>
                        </Grid>

                      </Grid>

                      <Grid container>
                        <Grid item xs={8} style={{ paddingRight: '24px' }}>
                          <TextField
                            id="standard-basic"
                            label="Enter Service"
                            variant="standard"
                            value={additionalservicevalue}
                            onChange={(e) => {
                              packagemeal('service', e.target.value)
                            }}
                          />

                        </Grid>
                        <Grid item xs={3} style={{ paddingRight: '3px' }}>
                          <TextField
                            id="standard-basic"
                            label="Enter cost"
                            variant="standard"
                            value={additionalcostvalue}
                            onChange={(e) => {
                              packagemeal('cost', e.target.value.replace(/[^\d.]/g, ''))
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} style={{ marginTop: "17px" }}>
                          <img
                            src={Plus}
                            className="add_additional_service"
                            onClick={() => { submitText() }} />
                        </Grid>
                      </Grid>
                      {errors.doctoradditionalvalue && <div className="error-message">{errors.doctoradditionalvalue}</div>}
                    </div>
                    <Grid item xs={10} md={10} style={{ textAlign: 'left', paddingRight: '12px' }}>
                      <div className="add-browse-input">
                        {
                          doctoradditionalvalueRef.current.map((item, index) => (
                            <div className="add-input-browser-li" style={{ margin: '5px 5px 5px 5px' }}>
                              <div>
                                {`${item.service}`}{"   "}{`${item.cost}`} <span className="_f10">KWD</span>
                                <img
                                  src={closeicon}
                                  style={{ height: "12px", width: "23px" }}
                                  onClick={() => deletetag(index)}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </Grid>
                    <div>
                      <div className="payment_type_title_text">
                        Total Additional Service Cost
                      </div>
                      <TextField
                        style={{ Width: "100px" }}
                        id="standard-basic"
                        variant="standard"
                        value={totaladditionalcostRef.current}
                      // onChange={(e) => setVar_PayatClinicCost(e.target.value)}
                      />
                    </div>
                  </div>
                }
              </div>
            }





            <div className="payment_submit_appoinment">
              <button
                className="appoinment_pay_submit"
                onClick={bookingBtnStateRef.current ? () => paymentInsert() : undefined}
              >
                Submit
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        open={Var_isOpenPaymentInsuranceModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <div className="appoinment_payment_method">
          <div className="appoinment_payment_title">PAYMENT METHOD</div>
          <div className="flex_grow"></div>
          <div onClick={() => isClosePatInsuranceModal()}>
            <img src={closeicon} />
          </div>
        </div>
        <div className="payment_method_model">
          <DialogContent>
            <div className="paid_through_insurance">
              {Var_valueshow_Ref.current != 0 && Var_viewdata.map((data) => (
                <h6 className="payment_type_title_select">
                  Paid through Insurance{" "}
                  <span style={{ fontSize: "12px" }}> (Full Coverage)</span>{" "}
                  <span className="currency_value"> (KWD)</span>
                  <img
                    src={Viewmore}
                    className="ViewMore"
                    onClick={() => {
                      openModel(data, 7);
                    }}
                  />
                </h6>
              ))}
              <TextField
                sx={{ Width: "300px" }}
                id="standard-basic"
                label="Enter amount"
                variant="standard"
                inputProps={{ readOnly: Var_valueshow_Ref.current != 0 ? true : false }}
                value={Var_InsuranceCost_Ref.current}
                onChange={(e) => {
                  SetVar_InsuranceCost(e.target.value.replace(/[^\d.]/g, ''))
                  clearError('Var_InsuranceCost')
                }}
              />
              {Var_InsuranceCost_Ref.current.length == 0 && errors.Var_InsuranceCost && <div className="error-message">{errors.Var_InsuranceCost}</div>}
            </div>

            {Var_valueshow_Ref.current != 0 &&
              <div style={{ padding: "10px 20px" }}>
                <div className="additional_payment_source">
                  <div>
                    <h6 className="payment_type_title_checkbox">
                      Additional Service
                    </h6>
                  </div>

                  <div>
                    <Checkbox
                      style={{ fontSize: "29px" }} onChange={(event) => clickhandler(event)}>
                    </Checkbox>
                  </div>
                </div>

                {checkboxvariable &&
                  <div>
                    <div>
                      <Grid container>
                        <Grid item xs={8}>
                          <div className="payment_type_title_text">
                            Additional Service Description
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="payment_type_title_text">
                            Cost
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={8} style={{ paddingRight: '24px' }}>
                          <TextField
                            id="standard-basic"
                            label="Enter Service"
                            variant="standard"
                            value={additionalservicevalue}
                            onChange={(e) => {
                              packagemeal('service', e.target.value)
                            }}
                          />

                        </Grid>
                        <Grid item xs={3} style={{ paddingRight: '3px' }}>
                          <TextField
                            id="standard-basic"
                            label="Enter cost"
                            variant="standard"
                            value={additionalcostvalue}
                            onChange={(e) => {
                              packagemeal('cost', e.target.value.replace(/[^\d.]/g, ''))
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} style={{ marginTop: "17px" }}>
                          <img
                            src={Plus}
                            className="add_additional_service"
                            onClick={() => { submitText() }} />
                        </Grid>
                      </Grid>
                      {errors.doctoradditionalvalue && <div className="error-message">{errors.doctoradditionalvalue}</div>}
                    </div>
                    <Grid item xs={10} md={10} style={{ textAlign: 'left', paddingRight: '12px' }}>
                      <div className="add-browse-input">
                        {
                          doctoradditionalvalueRef.current.map((item, index) => (
                            <div className="add-input-browser-li" style={{ margin: '5px 5px 5px 5px' }}>
                              <div>
                                {`${item.service}`}{"   "}{`${item.cost}`} <span className="_f10">KWD</span>
                                <img
                                  src={closeicon}
                                  style={{ height: "12px", width: "23px" }}
                                  onClick={() => deletetag(index)}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </Grid>
                    <div>
                      <div className="payment_type_title_text">
                        Total Additional Service Cost
                      </div>
                      <TextField
                        style={{ Width: "100px" }}
                        id="standard-basic"
                        variant="standard"
                        value={totaladditionalcostRef.current}
                      />
                    </div>
                  </div>
                }

              </div>
            }

            <div className="paid_through_insurance">
              <h6 className="payment_type_title">Insurance company </h6>
              <FormControl
                variant="standard"
                sx={{ minWidth: "-webkit-fill-available" }}
              >
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  sx={{
                    height: "40px",
                    minHeight: 15,
                    minWidth: "-webkit-fill-available",
                  }}
                  value={Var_getInsuranceCompanyName}
                  onChange={(e) => {
                    debugger
                    setVar_getInsuranceCompanyName(e.target.value);
                    clearError('Var_getInsuranceCompanyName')
                  }}
                  renderValue={(Var_getInsuranceCompanyName) => {
                    return Var_getInsuranceCompanyName.company_name;
                  }}
                // label="select company"
                >
                  <MenuItem
                    key={0}
                    value={{
                      id: 0,
                      company_name: "Select Insurance Company",
                      insurance_days: "",
                      insurance_percentage: "",
                      created_date: "",
                      created_by: "",
                    }}
                  ></MenuItem>
                  {Var_InsuranceCompanyList_Ref.current.map((item) => (
                    <MenuItem key={item.company_name} value={item}>
                      <Container>
                        <Grid container>
                          <Grid item className="ref_lab" align="start">
                            <p className="dropdowntext">{item.company_name}</p>
                          </Grid>
                        </Grid>
                      </Container>
                    </MenuItem>
                  ))}
                </Select>
                {errors.Var_getInsuranceCompanyName && <div className="error-message">{errors.Var_getInsuranceCompanyName}</div>}
              </FormControl>
            </div>

            <div className="payment_submit_appoinment">
              <button
                className="appoinment_pay_submit"
                onClick={bookingBtnStateRef.current ? () => paymentInsert() : undefined}
              >
                Submit
              </button>
            </div>
          </DialogContent>
        </div>
      </Dialog>

      <Dialog
        className="paymentMOdelLen"
        fullWidth={fullWidth}
        open={Var_isOpenPaymentCombaineModal}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <div className="appoinment_payment_method">
          <div className="appoinment_payment_title">
            PAYMENT METHOD{" "}
            <span style={{ fontSize: "12px" }}>(PARTIAL COVERAGE)</span>
          </div>
          <div className="flex_grow"></div>
          <div
            onClick={() => {
              isClosePatAtClinicinsuranceModal();
            }}
          >
            <img src={closeicon} />
          </div>
        </div>
        <div className="payment_method_model">
          <DialogContent>
            <div className="paid_through_insurance">
              {Var_valueshow_Ref.current != 0 && Var_viewdata.map((data) => (
                <h6 className="payment_type_title">
                  Booked Amount <span className="currency_value">(KWD)</span>
                  <img
                    src={Viewmore}
                    className="ViewMore"
                    onClick={() => {
                      openModel(data, 7);
                    }}
                  />
                </h6>
              ))}
              <TextField
                id="standard-basic"
                label="Enter amount"
                inputProps={{ readOnly: Var_valueshow_Ref.current != 0 ? true : false }}
                variant="standard"
                value={Var_FullAmount_Ref.current}
                onChange={(e) => {
                  setVar_FullAmount(e.target.value.replace(/[^\d.]/g, ''));
                  clearError('Var_FullAmount')
                }}
              />
              {errors.Var_FullAmount && <div className="error-message">{errors.Var_FullAmount}</div>}
            </div>

            {Var_valueshow_Ref.current != 0 &&
              <div style={{ padding: "10px 20px" }}>

                <div className="additional_payment_source">
                  <div>
                    <h6 className="payment_type_title_checkbox">
                      Additional Service
                    </h6>
                  </div>

                  <div>
                    <Checkbox
                      style={{ fontSize: "29px" }} onChange={(event) => clickhandler(event)}>
                    </Checkbox>
                  </div>
                </div>

                {checkboxvariable &&
                  <div>

                    <div>

                      <Grid container>

                        <Grid item xs={8}>
                          <div className="payment_type_title_text">
                            Additional Service Description
                          </div>
                        </Grid>

                        <Grid item xs={4}>
                          <div className="payment_type_title_text">
                            Cost
                          </div>
                        </Grid>

                      </Grid>

                      <Grid container>
                        <Grid item xs={8} style={{ paddingRight: '24px' }}>
                          <TextField
                            id="standard-basic"
                            label="Enter Service"
                            variant="standard"
                            value={additionalservicevalue}
                            onChange={(e) => {
                              packagemeal('service', e.target.value)
                            }}
                          />

                        </Grid>
                        <Grid item xs={3} style={{ paddingRight: '3px' }}>
                          <TextField
                            id="standard-basic"
                            label="Enter cost"
                            variant="standard"
                            value={additionalcostvalue}
                            onChange={(e) => {
                              packagemeal('cost', e.target.value.replace(/[^\d.]/g, ''))
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} style={{ marginTop: "17px" }}>
                          <img
                            src={Plus}
                            className="add_additional_service"
                            onClick={() => { submitText() }} />
                        </Grid>
                      </Grid>

                      {errors.doctoradditionalvalue && <div className="error-message">{errors.doctoradditionalvalue}</div>}
                    </div>

                    <Grid item xs={10} md={10} style={{ textAlign: 'left', paddingRight: '12px' }}>
                      <div className="add-browse-input">
                        {
                          doctoradditionalvalueRef.current.map((item, index) => (
                            <div className="add-input-browser-li" style={{ margin: '5px 5px 5px 5px' }}>
                              <div>
                                {`${item.service}`}{"   "}{`${item.cost}`} <span className="_f10">KWD</span>
                                <img
                                  src={closeicon}
                                  style={{ height: "12px", width: "23px" }}
                                  onClick={() => deletetag(index)}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </Grid>

                    <div>
                      <div className="payment_type_title_text">
                        Total Additional Service Cost
                      </div>
                      <TextField
                        style={{ Width: "100px" }}
                        id="standard-basic"
                        variant="standard"
                        value={totaladditionalcostRef.current}
                      />
                    </div>
                  </div>
                }

              </div>
            }

            <div className="paid_through_insurance">
              <h6 className="payment_type_title_select">Insurance company </h6>
              <FormControl
                variant="standard"
                sx={{ minWidth: "-webkit-fill-available" }}
              >
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  sx={{
                    height: "40px",
                    minHeight: 15,
                    minWidth: "-webkit-fill-available",
                  }}
                  value={Var_getInsuranceCompanyName}
                  onChange={(e) => {
                    setVar_getInsuranceCompanyName(e.target.value);
                    clearError('Var_getInsuranceCompanyName')
                  }}
                  renderValue={(Var_getInsuranceCompanyName) => {
                    return Var_getInsuranceCompanyName.company_name;
                  }}
                  placeholder="Select Lab22"
                >
                  <MenuItem
                    key={0}
                    value={{
                      id: 0,
                      company_name: "Select Insurance Company",
                      insurance_days: "",
                      insurance_percentage: "",
                      created_date: "",
                      created_by: "",
                    }}
                  ></MenuItem>
                  {Var_InsuranceCompanyList_Ref.current.map((item) => (
                    <MenuItem key={item.company_name} value={item}>
                      <Container>
                        <Grid container>
                          <Grid item className="ref_lab" align="start">
                            <p className="dropdowntext">{item.company_name}</p>
                          </Grid>
                        </Grid>
                      </Container>
                    </MenuItem>
                  ))}
                </Select>
                {errors.Var_getInsuranceCompanyName && <div className="error-message">{errors.Var_getInsuranceCompanyName}</div>}
              </FormControl>
            </div>
            <div className="paid_through_insurance">
              <h6 className="payment_type_title">
                Full Amount <span className="currency_value">(KWD)</span>
              </h6>
              <TextField
                id="standard-basic"
                label="Enter amount"
                variant="standard"
                inputProps={{ readOnly: true }}
                value={(parseFloat(Var_FullAmount_Ref.current) + parseFloat(totaladditionalcostRef.current)).toString()}
              />
            </div>
            <div className="paid_through_insurance">
              <h6 className="payment_type_title_select">
                Insurance Coverage Amount{" "}
                <span className="currency_value"> (KWD)</span>
              </h6>
              <TextField
                id="standard-basic"
                label="Enter amount"
                variant="standard"
                value={Var_InsuranceCost_Ref.current}
                onChange={(e) => {
                  const insuranceCost = e.target.value.replace(/[^\d.]/g, '');
                  const fullAmount = parseFloat(Var_FullAmount_Ref.current) || 0;
                  const totalAdditionalCost = parseFloat(totaladditionalcostRef.current) || 0;
                  const payAtClinicCost = fullAmount + totalAdditionalCost - parseFloat(insuranceCost || 0);

                  if (payAtClinicCost <= 0 || parseFloat(insuranceCost) <= 0) {
                    SetVar_InsuranceCost('');
                    setVar_PayatClinicCost('');
                  } else {
                    SetVar_InsuranceCost(insuranceCost);
                    setVar_PayatClinicCost(parseFloat(payAtClinicCost).toFixed(3));
                  }
                  clearError('Var_InsuranceCost');
                }}
              />
              {Var_InsuranceCost_Ref.current.length == 0 && errors.Var_InsuranceCost && <div className="error-message">{errors.Var_InsuranceCost}</div>}
            </div>
            <div className="paid_through_insurance">
              <h6 className="payment_type_title_select">
                Balance to be Paid by Patient{" "}
                <span className="currency_value"> (KWD)</span>
              </h6>
              <TextField
                id="standard-basic"
                label="Enter amount"
                variant="standard"
                value={Var_PayatClinicCost_Ref.current}
                onChange={(e) => {
                  const payAtClinicCost = e.target.value.replace(/[^\d.]/g, '');
                  const fullAmount = parseFloat(Var_FullAmount_Ref.current) || 0;
                  const totalAdditionalCost = parseFloat(totaladditionalcostRef.current) || 0;
                  const insuranceCost = fullAmount + totalAdditionalCost - parseFloat(payAtClinicCost || 0);

                  if (insuranceCost <= 0 || parseFloat(payAtClinicCost) <= 0) {
                    SetVar_InsuranceCost('');
                    setVar_PayatClinicCost('');
                  } else {
                    setVar_PayatClinicCost(payAtClinicCost);
                    SetVar_InsuranceCost(insuranceCost);
                  }
                  clearError('Var_PayatClinicCost');
                }}
              />
              {Var_PayatClinicCost_Ref.current.length == 0 && errors.Var_PayatClinicCost && <div className="error-message">{errors.Var_PayatClinicCost}</div>}
            </div>
            <div className="payment_submit_appoinment">
              <button
                className="appoinment_pay_submit"
                onClick={bookingBtnStateRef.current ? () => paymentInsert() : undefined}
              >
                Submit
              </button>
            </div>
          </DialogContent>
        </div>
      </Dialog>

      {/* ======================== PAY Dialog submit end ======================================= */}
      {/* =============================== Walking Book Dialog Start ========================= */}
      <Dialog
        fullWidth={fullWidth}
        alignment="center"
        size="md"
        open={isOpenWalkInModel}
        onClose={setisOpenWalkInModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "450px",
              borderRadius: "15px",
            },
          },
        }}
      // style={{ margin: "", borderRadius: "20px", minWidth: "450px" }}
      >
        <div className="dialog_header">
          <div className="dialog_title"> WALK-IN BOOKING</div>
          <div className="flex_grow"></div>
          <div onClick={() => isCloseModel("WalkInModel")}>
            <img src={closeicon} />
          </div>
        </div>
        <DialogContent>
          <Container>
            <Grid container className="approval_modal_title_walk_booking">
              <Grid item>Name</Grid>
            </Grid>
            <Grid container className="enter_input_name">
              <Grid item xs={12}>
                <input
                  className="holder_input_name"
                  value={Var_ProfileName}
                  type="text"
                  placeholder="Enter Name"
                  onChange={(event) => setVar_ProfileName(event.target.value)}
                />
              </Grid>
              {Var_ProfileName_Ref.current !== '' ? <></> :
                <>
                  {walname == true &&
                    <Grid item xs={12} className="field_required pb-1 pt-0">
                      Field Required
                    </Grid>
                  }
                </>
              }

            </Grid>
            <Grid container className="approval_modal_title_walk_booking">
              <Grid item>Gender</Grid>
            </Grid>
            <Grid container className="enter_input_name">
              <Grid item xs={12}>
                <FormControl sx={{ m: "8px 0px", minWidth: 353 }} size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Var_gender}
                    renderValue={(p) => p}
                    onChange={(e) => getGender(e)}
                  >
                    <MenuItem value={"M"}>Male</MenuItem>
                    <MenuItem value={"F"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {walgender == true &&
                <Grid item xs={12} className="field_required pb-1 pt-0">
                  Field Required
                </Grid>
              }
            </Grid>
            <Grid container className="approval_modal_title_walk_booking">
              <Grid item>Mobile Number</Grid>
            </Grid>
            <Grid container className="enter_input_name">
              <Grid item xs={12}>
                <input
                  className="holder_input_name"
                  value={Var_ProfilePhone}
                  type="text"
                  placeholder="Enter Mobile Number"
                  onChange={(e) => walkinMobileNumbetValidation(e)}
                  maxLength={8}
                />
              </Grid>
              {walmobile == true &&
                <Grid item xs={12} className="field_required pb-1 pt-0">
                  Field Required
                </Grid>
              }
            </Grid>
            <div className="dropdown_control">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    sx={{
                      padding: "8.5px 14px",
                      height: "40px",
                      minHeight: 15,
                      minWidth: "-webkit-fill-available",
                    }}
                    value={Var_GetSpecilaitySelect}
                    onChange={(e) => {
                      setVar_GetSpecilaitySelect(e.target.value);
                      getservice(Var_GetSpecilaitySelect_Ref.current.id);
                      setVar_SelectTimeSlots("");
                      setVar_WalingNoSlots("0");
                      setVar_WalkInGetSlots([]);
                      setwalspecial(false);
                    }}
                    renderValue={(Var_GetSpecilaitySelect) => {
                      return Var_GetSpecilaitySelect.speciality;
                    }}
                  >
                    <MenuItem
                      key={0}
                      value={{
                        id: "",
                        speciality: "Select Speciality"
                      }}
                    ></MenuItem>

                    {Var_SpecialityDoctorList_Ref.current.map((item) => (
                      <MenuItem key={item.speciality} value={item}>
                        <Container>
                          <Grid container>
                            <Grid
                              item
                              xs={9}
                              className="ref_lab"
                              align="start"
                            >
                              <p className="dropdowntext">{item.speciality}</p>
                            </Grid>
                          </Grid>
                        </Container>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              {walspecial == true &&
                <Grid item xs={12} className="field_required py-1">
                  Field Required
                </Grid>
              }
            </div>

            <div className="dropdown_control">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* <InputLabel id="demo-select-small-label">Select Lab</InputLabel> */}
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    sx={{
                      padding: "8.5px 14px",
                      height: "40px",
                      minHeight: 15,
                      minWidth: "-webkit-fill-available",
                    }}
                    value={Var_GetServiceListSelect}
                    onChange={(e) => {
                      setVar_GetServiceListSelect(e.target.value);
                      getSlotsService();
                      setVar_SelectTimeSlots("");
                    }}
                    renderValue={(Var_GetServiceListSelect) => {
                      return Var_GetServiceListSelect.service;
                    }}
                  >
                    <MenuItem
                      key={0}
                      value={{
                        cost: "",
                        description: "",
                        doctor_id: "",
                        file_name: "",
                        file_path: "",
                        id: 0,
                        is_active: "",
                        service: "Select Service",
                        slot_duration: "",
                        spec_id: "",
                      }}
                    ></MenuItem>

                    {Var_GetServiceList_Ref.current.map((item) => (
                      <MenuItem key={item.service} value={item}>
                        {/* {item.spec_id == localStorage.getItem("speciality_Id") && */}
                        {item.is_active == 1 && (
                          <Container>
                            <Card className="dropdown_card">
                              <Grid container>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={1} align="start">
                                  <img
                                    src={
                                      item.file_path == " "
                                        ? vector
                                        : item.file_path == "[object Object]"
                                          ? vector
                                          : item.file_path == ""
                                            ? vector
                                            : item.file_path
                                    }
                                    alt="a"
                                    className="dropdownimg"
                                  />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid
                                  item
                                  xs={9}
                                  className="ref_lab"
                                  align="start"
                                >
                                  <p className="dropdowntext">{item.service}</p>
                                </Grid>
                              </Grid>
                            </Card>
                          </Container>
                        )}
                        {/* } */}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {walservice == true &&
                  <Grid item xs={12} className="field_required py-1">
                    Field Required
                  </Grid>
                }
              </Grid>
            </div>

            {Var_WalingNoSlots_Ref.current == "1" &&
              Var_WalkInGetSlots.length == 0 && (
                <Grid container className="modal_slots_time">
                  <Grid item>
                    <p className="warning_helper_txt">Slots Not Available</p>
                  </Grid>
                </Grid>
              )}

            {Var_WalkInGetSlots.length != 0 && (
              <>
                <Grid container className="modal_slots_time">
                  <Grid item>
                    <p>Slots</p>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} style={{ justifyContent: "space-evenly" }}>
                    <Grid container className="walkin_total_slots_top">
                      {Var_WalkInGetSlots?.map((value) => (
                        <Grid item xs={3}>
                          {((value.date >= moment(new Date()).format("YYYY-MM-DD") == true) || (value.from_time >= new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds())) &&
                            <>
                              {/* {(value.date.slice(-2) >= new Date().getDate() || ((Number(value.from_time.slice(0, 2)) + 11) % 12) + 1 > new Date().getHours()) && ( */}
                              <div className="time_sloat">
                                <p
                                  className="time_show"
                                  onClick={() => {
                                    if (value.is_booked == 0) {
                                      selectSlots(value);
                                    }
                                  }}
                                  disabled={value.is_booked == 1 ? true : false}
                                  style={{
                                    backgroundColor:
                                      value.is_booked == 0
                                        ? value.is_clicked == 1
                                          ? "var(--app-theme)"
                                          : "#510F3040"
                                        : "#FBEAE5",
                                    color:
                                      value.is_booked == 0
                                        ? value.is_clicked == 1
                                          ? "white"
                                          : "var(--app-theme) "
                                        : "#E12F2F",
                                    border:
                                      value.is_booked == 0
                                        ? "1px solid var(--app-theme) "
                                        : " 1px solid #E12F2F",
                                  }}
                                >
                                  {timeFormat(value.from_time)}
                                </p>
                              </div>
                              {/* )} */}
                            </>
                          }
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <div>
              <Grid container>
                <Grid item xs={12}>
                  <div className="select_finder">
                    <div className="check_box_booked" />
                    <p className="booked">Booked</p>

                    <div className="check_box_available" />
                    <p className="available">Available</p>

                    <div className="check_box_selected" />
                    <p className="selected">Selected</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="segment_center">
              <button
                className="btnWithNOSHade botton_Cart_Create"
                onClick={walBookingBtnStateRef.current ? () => bookedSuccessfully() : undefined}
              >
                Book
              </button>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
      {/* =============================== Walking Book Dialog End ========================= */}

{/* =============================== Add Appointment Model Start ========================= */}
<Dialog
                fullWidth={fullWidth}
                alignment="center"
                size="md"
                open={isOpenWalkInModelHealthMatrics}
                onClose={setisOpenWalkInModelHealthMatrics}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "850px",
                            borderRadius: "8px !important",
                            padding: "10px 0px 30px",
                        },
                    },
                }}
            >
                <div className="dashboard_dialog_header">
                    {/* <div className="dialog_title"> WALK-IN BOOKING</div> */}
                    <div className="flex_grow"></div>
                    <div onClick={() => isHealthMatricsCloseModel()}>
                        <img style={{ cursor: "pointer" }} src={closeicon} />
                    </div>
                </div>
          <DialogContent>
              <Container>
                <div className="row">
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_left_part">
                            <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Height (cm)
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_Height}
                                        type="number"
                                        placeholder="Enter height"
                                        onChange={(event) => setVar_Height(event.target.value)}
                                        // Decimal Pattern /^\d{1,3}(\.\d{1,2})?$/
                                        // Float Pattern /^\d{0,2}(\.\d{1,2})?$/
                                    />
                                </div>
                                {Var_Height_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walheight == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                                Field Required
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_left_part">
                            <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Weight (kg)
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_Weight}
                                        type="number"
                                        placeholder="Enter weight"
                                        onChange={(event) => setVar_Weight(event.target.value)}
                                    />
                                </div>
                                {Var_Weight_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walweight == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                                Field Required
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_left_part">
                            <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Blood Pressure (mmHg)
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_BloodPressure}
                                        type="text"
                                        placeholder="Enter blood pressure"
                                        onChange={(event) => setVar_BloodPressure(event.target.value)}
                                    />
                                </div>
                                {Var_BloodPressure_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walbloodpressure == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                                Field Required
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_left_part">
                            <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Heart Rate (bpm)
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_HeartRate}
                                        type="number"
                                        placeholder="Enter heart rate"
                                        onChange={(event) => setVar_HeartRate(event.target.value)}
                                    />
                                </div>
                                {Var_HeartRate_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walheartrate == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                                Field Required
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_left_part">
                                <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">
                                        Body Temperature (°C or °F)
                                        <span className="walk_model_header_text_valid"> *</span>
                                    </p>
                                </div>
                                <div className="enter_input_name">
                                    <input
                                        className="holder_input_name"
                                        value={Var_BodyTemperature}
                                        type="number"
                                        placeholder="Enter body temperature"
                                        onChange={(event) => setVar_BodyTemperature(event.target.value)}
                                    />
                                </div>
                                {Var_BodyTemperature_Ref.current !== "" ? (
                                    <></>
                                ) : (
                                    <>
                                        {walbodytemperature == true && (
                                            <div item xs={12} className="field_required pb-1 pt-0">
                                                Field Required
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="col-6 col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 walk_model_left_part">
                            <div className="walk_appointment_booking">
                                    <p className="walk_model_header_text">Pre-existing disease</p>
                                </div>
                                <div className="symptom_parent">
                                    <input
                                        className="symptom_input"
                                        value={Var_preexistingdisease_Ref.current}
                                        type="text"
                                        placeholder="Enter disease"
                                        onChange={(event) => serVar_preexistingdisease(event.target.value)}
                                    />
                                    <img  className="symptom_add_icon" onClick={()=>getpreExistingdis()} src={AddIcon}></img>
                                </div>
                                {Var_preexistingdisease_Value_Ref.current?.map((item,i) =>(
                                <span className="symptoms_chip_main">{item} <img onClick={()=>removepreExistingdis(i)}  className="symptoms_remove_icon" src={closeIcon}></img></span>
                                ))}
                              </div>
                 </div>
                        <div className="walk_appointment_booking_button">
                            <button className="walk_appointment_cancel_button" onClick={() => isCloseModel("WalkInModelHealthMatrics")}>
                                Clear
                            </button>
                            <button className="walk_appointment_book_button"
                                onClick={Var_HealthMatricsState_Ref.current ? () => healthMatricsBooked() : undefined}>
                                {Var_HealthMatricsButton_Ref.current}
                            </button>
                        </div>
              </Container>
          </DialogContent>
     </Dialog>
  {/* =============================== Add Appointment Model End ========================= */}

      {/* =================== Qr code generate Dialog Start =============== */}
      {/* <Dialog
        alignment="center"
        size="sm"
        width={"300px"}
        height={"500px"}
        open={isOpenWalkInQrModel}
        onClose={setisOpenWalkInQrModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "350px",
              borderRadius: "20px",
            },
          },
        }}
      // style={{ margin: "2rem auto", borderRadius: "30px", minWidth: "350px" }}
      >
        <div className="dialog_header">
          <div className="dialog_title">REGISTRATION</div>
          <div className="flex_grow"></div>
          <div onClick={() => isCloseModel("WalkInQrModel")}>
            <img src={closeicon} />
          </div>
        </div>
        <DialogContent>
          <Grid container className="qr_code_generator">
            <Grid item xs={12} align="center">
              <div className="App">
                <QRCode
                  className="qr_code"
                  value={Var_ClinicRefferalCode}
                  size={250}
                  icon={Logo}
                  iconsize={30}
                  color="#82AE3F"
                  bordered={false}
                // status='loading'
                />
              </div>
            </Grid>
          </Grid>
          <div className="total_qr_details">
            <Grid container>
              <Grid item xs={12} align="center">
                Clinic referral code :{" "}
                <span className="qr_referral_code">
                  {" "}
                  REF COD {Var_ClinicRefferalCode}{" "}
                </span>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} align="center">
                Scan the above QR code to Get TOM App
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog> */}
      {/* =================== Qr code generate Dialog End =============== */}

      {/* ====================== Dialog checked In and checked Out Success Model Start=============================== */}
      {/* <Dialog
        fullWidth={fullWidth}
        open={isOpenSuccessModel}
        onClose={setIsOpenSuccessModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <DialogContent>
          <div className="success_model_body">
            <div className="icon">
              <img src={successModelTickImg} />
            </div>
            <div className="title">success!</div>
            <div className="content">{Var_SuccessModelText}</div>
            <div className="segment_center">
              <button className="botton_Cart_Create" onClick={() => okClose()}>
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog> */}

      {/* ================= Dialog checked In and checked Out Success Model End ================= */}
      {/* ================= Dialog Queue Status QR Model Start ================= */}
      <Dialog
        fullWidth={fullWidth}
        alignment="center"
        size="md"
        open={isOpenQrCodeModel}
        onClose={setisOpenQrCodeModel}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "450px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <div className="dialog_header">
          <div className="dialog_title">QUEUE CODE</div>
          <div className="flex_grow"></div>
          <div onClick={() => isCloseModel("QrCodeModel")}>
            <img src={closeicon} />
          </div>
        </div>
        <DialogContent>
          <div>
            <div style={{ textAlign: "center" }}>
              <img src={QRCodeData} className="qr_code_img" />
              <div style={{ marginTop: "10px" }}>
                Scan the above QR code to Get Queue status
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* ================= Dialog Queue Status QR Model End ================= */}
      {Var_IsShowSuccess && (
        <div className="popup_main">
          <div className="success_Popup_body">
            <div className="success_model_content">
              <p className="success_content_text_head">
                <b>{Var_SuccessModelText}</b>
              </p>
            </div>
            <div className="progress_prt">
              <div className="progress_bar"></div>
            </div>
          </div>
        </div>
      )}
      {/* 
      <Modalpage
        open={modalT}
        setModal={() => setModal(false)}
        data={modeldata}
        type={modalType}
      /> */}
    </div>
  );
}
export default AppointmentComponent;
